import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSignature } from "features/user/userAPI";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { srAnnounce } from "features/global/functions";

function Completion({ completeProgress }) {
	const navigate = useNavigate();
	const [signature, setSignature] = useState();
	const { i18n } = useTranslation();

	useEffect(() => {
		const downloadSignature = async () => {
			const signature = await getSignature();
			setSignature(signature);
		};

		downloadSignature();
	}, [i18n.language]);

	const handleDownload = async () => {
		const url = window.URL.createObjectURL(new Blob([signature]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "signature.pdf");
		document.body.appendChild(link);
		link.click();
	};

	/*const handleOpen = async () => {
		const blob = new Blob([signature], { type: "application/pdf" });
		const url = URL.createObjectURL(blob);
		const newWindow = window.open(url, "_blank");
		newWindow.addEventListener("load", function () {
			URL.revokeObjectURL(url);
		});
	};*/

	// Functions to execute when page navigate occurs and page has rendered
	useLayoutEffect(() => {
		// Announce naviation to screen reader
		srAnnounce(
			i18n.t("consent-signed") +
				". " +
				i18n.t("congratulations") +
				i18n.t("congratulations-message")
		);
	}, [navigate, i18n]);

	return (
		<>
			<div className="page-header mb-3 mt-2 badge">
				<h1>{i18n.t("consent-signed")}</h1>
			</div>
			<h4 aria-level={2} className="fw-normal">
				{i18n.t("congratulations")}
			</h4>
			<div>{i18n.t("congratulations-message")}</div>
			<div className="mb-2">
				<Button
					variant="pantone-390c"
					onClick={handleDownload}
					className="text-start mt-3 me-0 me-sm-3"
					aria-label={i18n.t("download-signed-form-description")}
				>
					<FontAwesomeIcon icon={faDownload} />{" "}
					{i18n.t("download-signed-pdf")}
				</Button>
				{/* <Button variant="success" onClick={handleOpen} className="me-2 mt-3">
					{i18n.t("view-pdf")}
				</Button> */}
				<Button
					variant="primary"
					onClick={completeProgress}
					className="mt-3"
				>
					{i18n.t("view-profile")}
				</Button>
			</div>
			<img
				src="/images/congratulations.svg"
				className="mx-auto d-block img-fluid mb-4"
				alt=""
				style={{ maxHeight: "52vh" }}
			/>
		</>
	);
}

export default Completion;
