import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { AdminLevelOptions } from 'features/user/userOptions';

function AdminAccessLevel({ adminAccessLevelSelectedString, id="adminAccessLevel", name="adminAccessLevel", label, placeholder, onChange, onBlur, isInvalid, disabled=false, errors, formik }) {
    const { i18n } = useTranslation();
    const adminAccessLevels = AdminLevelOptions.map(({ optionKey, translationKey }) => ({
        value: optionKey,
        label: i18n.t(translationKey),
    }));

    var adminAccessLevelSelected = null;
    if (adminAccessLevelSelectedString !== undefined && adminAccessLevelSelectedString !== "") {
        for (const adminAccessLevel of adminAccessLevels) {
            if (adminAccessLevelSelectedString == adminAccessLevel.value) {
                adminAccessLevelSelected = adminAccessLevel;
            }
        }
    }

	return (
		<>
            <label htmlFor={name} id={`${id}Label`} className="form-label">
                {label}
            </label>
			<Select
                className="react-select-enabled"
                value={adminAccessLevelSelected}
                id={id}
                name={name}
				placeholder={placeholder}
                options={adminAccessLevels}
                onChange={onChange}
                onBlur={onBlur}
                isInvalid={isInvalid}
                disabled={disabled}
                errors={errors}
                isDisabled={disabled}
                required
                aria-labelledby={`${id}-invalid-feedback ${id}Label`}
            />
            {formik.touched.adminAccessLevel && formik.errors.adminAccessLevel && (
                <div id={`${id}-invalid-feedback`} type="invalid" className="invalid-feedback-select">{formik.errors.adminAccessLevel}</div>
            )}
		</>
	);
}

export default AdminAccessLevel;