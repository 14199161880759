import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useCheckboxTree = (sportOrganizations, setFilters, initialChecked = [], initialExpanded, formik) => {
	const { i18n } = useTranslation();
	const [checked, setChecked] = useState(initialChecked);
	const [expanded, setExpanded] = useState(initialExpanded);
	const [filterText, setFilterText] = useState("");

	const sortNodes = (nodes) => {
		return nodes.sort((a, b) => a.label.localeCompare(b.label));
	};

	const filterTree = (node) => {
		if (!filterText) return true;
		if (node.label.toLowerCase().includes(filterText.toLowerCase())) {
			return true;
		}
		if (node.children) {
			node.children = sortNodes(node.children);
			return node.children.some((child) => filterTree(child));
		}
		return false;
	};

	const getTreeNodes = (orgs, parentId = 0, isRoot = false) => {
		let nodes = orgs
			.filter((org) => org.parent_id === parentId)
			.map((org) => {
				const childNodes = getTreeNodes(orgs, org.id);
				return {
					value: org.id.toString(),
					label: org.name,
					...(childNodes.length > 0 && { children: childNodes }),
				};
			})
			.filter(filterTree);
		nodes = sortNodes(nodes);
		if (isRoot) {
			nodes = [
				{
					value: "0",
					label: i18n.t("all-organizations"),
					children: nodes,
				},
			];
		}
		return nodes;
	};

	const handleTreeChange = (checked) => {
		setChecked(checked);
		if (formik) {
			formik.setFieldValue("adminAccessSportOrganizations", checked);
		}
		if (setFilters) {
			setFilters((prev) => ({
				...prev,
				sport_organization_id: checked.map((id) => parseInt(id)),
			}));
		}
	};

	const treeNodes = getTreeNodes(sportOrganizations, undefined, true);

	return { checked, setChecked, expanded, setExpanded, treeNodes, handleTreeChange, filterText, setFilterText };
};
