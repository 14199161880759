import { Form, InputGroup } from "react-bootstrap";

function UserDisabled({ label, fieldLabel, onChange, onBlur, checked, disabled=false, formik }) {
    return (
		<>
            <label id={"userDisabledLabel"} className="">
                {label}
            </label>
			<InputGroup key="inline-radio" className="mt-2 d-flex d-block bg-white border rounded mt-1 p-2">
                <Form.Check
                    inline
                    id="userDisabledRadio"
                    name="userDisabledRadio"
                    type="switch"
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked}
                    disabled={disabled}
                    label={fieldLabel}
                    aria-labelledby={"userDisabled-invalid-feedback userDisabledLabel"}
                />
            </InputGroup>
            {formik.touched.disabledRadio && formik.errors.disabledRadio && (
                <div id={"userDisabled-invalid-feedback"} type="invalid" className="invalid-feedback-select">{formik.errors.disabledRadio}</div>
            )}
		</>
	);
}

export default UserDisabled;