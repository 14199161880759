import { createSlice } from "@reduxjs/toolkit";
import i18n from "i18next";

const changeI18nLanguage = (language) => {
  if (i18n && i18n.isInitialized) {
    i18n.changeLanguage(language);
  }
};

const loadLanguageFromLocalStorage = () => {
  const serializedState = localStorage.getItem("language");
  if (serializedState === null) {
    return null;
  }
  const language = JSON.parse(serializedState);
  changeI18nLanguage(language);
  return language;
};

const initialLanguageState = {
  language: loadLanguageFromLocalStorage(),
};

const languageSlice = createSlice({
  name: "language",
  initialState: initialLanguageState,
  reducers: {
    setLanguage: (state, action) => {
      const language = action.payload;
      localStorage.setItem("language", JSON.stringify(language));
      changeI18nLanguage(language);
      state.language = language;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export const selectLanguage = (state) => state.language.language;

export default languageSlice.reducer;
