import { useRef } from "react";
import { InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker, { registerLocale } from "react-datepicker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import MaskedTextInput from "react-text-mask";
import i18n from "app/i18nConfig";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr-CA";
import { srAnnounce } from "features/global/functions";

// Register locales
registerLocale("en", en);
registerLocale("fr", fr);

function ParticipantDateOfBirth({
	id = "participantDateOfBirth",
	name = "participantDateOfBirth",
	placeholder,
	label,
	ariaLabel,
	selected,
	onChange,
	onBlur,
	isInvalid,
	locale = "en",
	disabled = false,
	formik,
}) {
	const participantDateOfBirthRef = useRef(null); // Ref to the input field
	let participantDateOfBirthKeyDown = false;

	// date select via input text field
	const handleChange = (date) => {
		// if keydown tracked, announce to screenreader
		if (participantDateOfBirthKeyDown) {
			// Select date value within input once typed in (to announce to screen reader)
			if (participantDateOfBirthRef.current) {
				// Remove aria-live and role attribs for month span element in react-datepicker popup (prevent month readout)
				const spanElement = document.querySelector(
					".react-datepicker__tab-loop > .react-datepicker-popper > div > div > span"
				);
				if (spanElement) {
					spanElement.setAttribute("aria-live", "off");
				}
				const spanElement2 = document.querySelector(
					".react-datepicker__aria-live"
				);
				if (spanElement2) {
					spanElement2.setAttribute("aria-live", "off");
				}
				setTimeout(() => {
					// select input value of datepicker
					srAnnounce(
						i18n.t("selected") +
							": " +
							moment(date).format("MMMM Do, YYYY"),
						true
					);
					if (spanElement) {
						spanElement.setAttribute("aria-live", "polite");
					}
					if (spanElement2) {
						spanElement2.setAttribute("aria-live", "polite");
					}
				}, 400);

				// revalidate the field (if previously validated)
				if (formik.touched[name] && formik.errors[name]) {
					formik.setFieldTouched(name, true, true);
					formik.setFieldValue(name, date, true);
				}
			}
		}
		onChange(date);
	};

	// date select via datepicker popper
	const handleSelect = (date) => {
		// date selcted via popper
		if (participantDateOfBirthRef.current) {
			participantDateOfBirthRef.current.input.inputElement.blur();
		}
		srAnnounce(
			i18n.t("selected") + ": " + moment(date).format("MMMM Do, YYYY")
		);
	};

	const handleCalendarOpen = (date) => {
		const spanElement = document.querySelector(
			"div.react-datepicker-popper > div > div > div.react-datepicker__month-container > div.react-datepicker__month"
		);
		if (spanElement) {
			spanElement.removeAttribute("aria-label");
			spanElement.removeAttribute("role");
		}
	};

	const handleKeyDown = (e) => {
		participantDateOfBirthKeyDown = true;
		const isDigit = /^\d$/.test(e.key);
		const isHyphen = e.key === "-";
		const isNavigationKey = [
			"ArrowLeft",
			"ArrowRight",
			"Backspace",
			"Delete",
			"Tab",
		].includes(e.key);
		const isCtrl = -e.ctrlKey;
		const isCtrlA = e.ctrlKey && (e.key === "a" || e.key === "A");
		const isCtrlX = e.ctrlKey && (e.key === "x" || e.key === "X");
		const isCtrlV = e.ctrlKey && (e.key === "v" || e.key === "V");
		const isEnterKey = e.key === "Enter" || e.key === "Return";

		if (
			!isDigit &&
			!isHyphen &&
			!isNavigationKey &&
			!isCtrl &&
			!isCtrlA &&
			!isCtrlX &&
			!isCtrlV &&
			!isEnterKey
		) {
			srAnnounce(i18n.t("participant-dob-invalid-keypress"));
			e.preventDefault();
		}
	};

	const handleBlur = (e) => {
		participantDateOfBirthKeyDown = false;
		onBlur(e);
	};

	return (
		<>
			<label
				htmlFor={name}
				className="form-label"
				aria-label={i18n.t("participant-dob-aria-label")}
			>
				{label}
			</label>
			<InputGroup>
				<InputGroup.Text>
					<FontAwesomeIcon icon={faCalendar} />
				</InputGroup.Text>
				<DatePicker
					selected={selected}
					className={`form-control ${
						formik.errors &&
						formik.errors[name] &&
						formik.touched &&
						formik.touched[name]
							? "is-invalid"
							: ""
					}`}
					id={id}
					name={name}
					placeholderText={placeholder}
					aria-label={ariaLabel}
					onChange={handleChange}
					onKeyDown={handleKeyDown}
					onSelect={handleSelect}
					onCalendarOpen={handleCalendarOpen}
					onBlur={handleBlur}
					isInvalid={isInvalid}
					disabled={disabled}
					locale={locale}
					dateFormat="yyyy-MM-dd"
					maxDate={moment().subtract(1, "days").toDate()}
					minDate={moment().subtract(125, "years").toDate()}
					autoComplete="off"
					required
					peekNextMonth
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					formik={formik}
					customInput={
						<MaskedTextInput
							type="text"
							mask={[
								/\d/,
								/\d/,
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
							]}
						/>
					}
					ref={participantDateOfBirthRef}
				/>
			</InputGroup>
			{formik.touched[name] && formik.errors[name] && (
				<div
					type="invalid"
					id="invalid-feedback-participant-date-of-birth"
					className="invalid-feedback-select"
				>
					{formik.errors[name]}
				</div>
			)}
			<div className="form-text text-primary">
				{i18n.t("participant-dob-format")}
			</div>
		</>
	);
}

export default ParticipantDateOfBirth;
