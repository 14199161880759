import React, { useState, useEffect, useRef } from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

import { validUsername, validFirstLastName, validEmail } from "../../../../../../../app/regex";
import { checkUsername, updateById } from "../../../../../../../features/user/userAPI"; 
import { userHasSigned, isUserAgeOfConsent, userHasImpediments } from "features/global/functions";
import { useSelector } from "react-redux";
import { selectEditUser } from "features/admin/adminSlice";
import { selectUser } from "features/user/userSlice";
import { UserRole } from "features/user/userRole";
import { useCheckboxTree } from "hooks/useCheckboxTree";
import { useSportsOrganizations } from "hooks/useSportsOrganizations";

/* Import components */
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Username from "components/Fields/Username";
import ParticipantFirstName from "components/Fields/ParticipantFirstName";
import ParticipantLastName from "components/Fields/ParticipantLastName";
import ParticipantEmail from "components/Fields/ParticipantEmail";
import ParticipantDateOfBirth from "components/Fields/ParticipantDateOfBirth";
import Province from "components/Fields/Province";
import HasImpediments from "components/Fields/HasImpediments";
import GuardianFirstName from "components/Fields/GuardianFirstName";
import GuardianLastName from "components/Fields/GuardianLastName";
import GuardianEmail from "components/Fields/GuardianEmail";
import GuardianYearOfBirth from "components/Fields/GuardianYearOfBirth";
import ParticipantViewingWithGuardian from "components/Fields/ParticipantViewingWithGuardian";
import LoadingSpinner from "components/LoadingSpinner";
import GenericModal from "components/GenericModal";
import UserStatistics from "../../../../../Profile/components/UserStatistics";
import { FocusError } from 'focus-formik-error';
import * as Yup from "yup";
import styles from "./EditUser.module.css";
import UserDisabled from "components/Fields/UserDisabled";
import MailedInConsentReceived from "components/Fields/MailedInConsentReceived";
import AdminAccessLevel from "components/Fields/AdminAccessLevel";
import SportSelection from "components/Fields/SportSelection";
import AdminAccessSportOrganizationTree from "../AdminAccessSportOrganizationTree/AdminAccessSportOrganizationTree";

function EditUser({ userID, language, submitSuccessCount, setSubmitSuccessCount }) {
	const navigate = useNavigate();
	const { i18n } = useTranslation();
    const user = useSelector(selectUser);
    const editUser = useSelector(selectEditUser);
    const sportOrganizations = useSportsOrganizations();

	const [formConditionals, setFormConditionals] = useState({ showForm: false, showGuardianSection: false, showDisabledUser: false, showUserConsentStatus: false, showAdminAccessLevel: false, showAdminAccessSportOrganizationTree: false });
	const [showErrorModal, setShowErrorModal] = useState(false);
	const [error, setError] = useState()
	const [isLoading, setIsLoading] = useState(false);
	const [showGenericModal, setShowGenericModal] = useState(false);

    //const profile = useRef();
	const usernameInputRef = useRef(null);
	const formikUsernameValid = useRef(true);
	const formikValidateOnBlur = useRef(false);
	const genericModalProps = useRef({titleText: "", bodyText: "", footerText: ""});
	const signed = useRef(null);
	const userIsOfAge = useRef(null);
    const hasImpediments = useRef(null);
    const sportCounter = useRef(0);

	// additional form validation if guardian section used
	let guardianValidationSchema = {}
	if (formConditionals.showGuardianSection) {
		guardianValidationSchema = {
			hasImpedimentsRadio: Yup.number()
			.required(i18n.t("has-impediments-required")),
			guardianFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(validFirstLastName, i18n.t("invalid-first-last-name-regex")),
			guardianLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(validFirstLastName, i18n.t("invalid-first-last-name-regex")),
			guardianEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			guardianYearOfBirth: Yup.date()
				.required(i18n.t("year-of-birth-required"))
                .test({
					name: "guardian-yob-validation",
					message: i18n.t("invalid-guardian-yob"),
					test: (value) => {
						var ageInYears = moment().diff(value, "years");
						if (ageInYears < 19) {
							toast.dismiss();
							toast.info(i18n.t("invalid-guardian-yob"));
							formik.setFieldValue("guardianYearOfBirth", null, false);
							return false;
						}

						return true;
					},
				}),
			participantViewingWithGuardianRadio: Yup.number()
				.required(i18n.t("participant-viewing-with-guardian-required")),
		}
	} else {
		guardianValidationSchema = null;
	}

    // additional form validation if user consent status used
	let userConsentStatusValidationSchema = {}
	if (formConditionals.showUserConsentStatus) {
		userConsentStatusValidationSchema = {
			mailedInConsentRadio: Yup.bool()
                .required(i18n.t("mailed-in-consent-required"))
        }
    } else {
		userConsentStatusValidationSchema = null;
	}

    // additional form validation if admin access level used
    let adminAccessLevelValidationSchema = {}
	if (formConditionals.showAdminAccessLevel) {
		adminAccessLevelValidationSchema = {
			adminAccessLevel: Yup.string()
        }
    } else {
		adminAccessLevelValidationSchema = null;
	}

    // additional form validation if Admin Access Sport Organizations used
    let adminAccessSportOrganizationsValidationSchema = {}
	if (formConditionals.showAdminAccessSportOrganizationTree) {
		adminAccessSportOrganizationsValidationSchema = {
			adminAccessSportOrganizations: Yup.array()
        }
    } else {
		adminAccessSportOrganizationsValidationSchema = null;
	}

    let initialValues = {
        username: "",
        participantFirstName: "",
        participantLastName: "",
        participantEmail: "",
        participantDateOfBirth: "",
        participantSport: "",
        sportMembershipNumber: "",
        participantProvince: "",
        userSports: [
            {
                id: "prefix" + sportCounter.current++,
                name: "",
                sport_organization_membership_number: "",
            },
        ],
        hasImpedimentsRadio: "",
        guardianFirstName: "",
        guardianLastName: "",
        guardianEmail: "",
        guardianYearOfBirth: "",
        participantViewingWithGuardianRadio: "",
        disabledRadio: "",
        mailedInConsentRadio: "",
        adminAccessLevel: "",
        adminAccessSportOrganizations: []
    }

	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
        initialValues,
		validationSchema: Yup.object({
			username: Yup.string()
				.required(i18n.t("username-required"))
				.matches(validUsername, i18n.t("invalid-username-regex"))
				.test({
					name: 'username-validation',
					message: i18n.t("duplicate-username"),
					test: async () => {
                        if (editUser.username.trim() == formik.values.username.trim()) {
                            return formikUsernameValid.current = true; 
                        }

                        await checkAndValidateUsername();
                        return formikUsernameValid.current
					}
				}),
			participantFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(validFirstLastName, i18n.t("invalid-first-last-name-regex")),
			participantLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(validFirstLastName, i18n.t("invalid-first-last-name-regex")),
			participantEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			participantDateOfBirth: Yup.date()
				.required(i18n.t("date-of-birth-required")),
			participantProvince: Yup.string()
				.required(i18n.t("participant-province-required")),
            userSports: Yup.array().of(
                Yup.object({
                    name: Yup.string().required(i18n.t("participant-sport-required")),
                    sport_organization_membership_number: Yup.string().nullable(),
                })
            ),
            disabledRadio: Yup.bool()
                .required(i18n.t("disabled-required")),
			...guardianValidationSchema,
            ...userConsentStatusValidationSchema,
            ...adminAccessLevelValidationSchema,
            ...adminAccessSportOrganizationsValidationSchema
		}),
		onSubmit: async (values) => {
            setIsLoading(true);

			let response;
			toast.dismiss(); // Hide any existing toasts

            // If user has already signed, do not perform this check
            if (signed.current == false) {
                userIsOfAge.current = isUserAgeOfConsent(formik.values.participantDateOfBirth)
                // Check user's age on form submit and display guardian section if under 19
                if (!userIsOfAge.current && formConditionals.showGuardianSection == false) {
                    setFormConditionals({...formConditionals, showGuardianSection: true })
                    toast.info(i18n.t("under-19-show-guardian-section"));
                    return;
                }
                // Hide guardian section if user is over 19 years of age and has no impediments
                else if (userIsOfAge.current && formik.values.hasImpedimentsRadio == 0 && formConditionals.showGuardianSection == true) {
                    setFormConditionals({...formConditionals, showGuardianSection: false })
                    toast.info(i18n.t("over-19-no-impediments-hide-guardian-section"));
                    return;
                }
            }

			// Create formatted variables for all form fields
			let usernameFormatted = values.username;
			let participantFirstNameFormatted = values.participantFirstName;
			let participantLastNameFormatted = values.participantLastName;
			let participantEmailFormatted = values.participantEmail;
			let participantDateOfBirthFormatted = values.participantDateOfBirth;
			let participantProvinceFormatted = values.participantProvince;
			let hasImpedimentsRadioFormatted = values.hasImpedimentsRadio;
			let guardianFirstNameFormatted = values.guardianFirstName;
			let guardianLastNameFormatted = values.guardianLastName;
			let guardianEmailFormatted = values.guardianEmail;
			let guardianYearOfBirthFormatted = values.guardianYearOfBirth;
			let participantViewingWithGuardianRadioFormatted = values.participantViewingWithGuardianRadio;
            let disabledRadioFormatted = values.disabledRadio;
            let adminAccessLevelFormatted = values.adminAccessLevel;
            let adminSportOrganizationIDFormatted = values.adminAccessSportOrganizations;

            // Create formatted variables for Sport Selections
			var sportOrganizationIdArrayFormatted = [];
			var sportOrganizationMembershipNumberArrayFormatted = [];
			const userSportsUpdated = values.userSports;
			for (const index in userSportsUpdated) {
				sportOrganizationIdArrayFormatted.push(userSportsUpdated[index].id);
				sportOrganizationMembershipNumberArrayFormatted.push(
					userSportsUpdated[index].sport_organization_membership_number
				);
			}

			// Trim white spaces
			usernameFormatted = usernameFormatted.trim();
			participantFirstNameFormatted = participantFirstNameFormatted.trim();
			participantLastNameFormatted = participantLastNameFormatted.trim();
			participantEmailFormatted = participantEmailFormatted.trim();
			if (guardianFirstNameFormatted) guardianFirstNameFormatted = guardianFirstNameFormatted.trim();
			if (guardianLastNameFormatted) guardianLastNameFormatted = guardianLastNameFormatted.trim();
			if (guardianEmailFormatted) guardianEmailFormatted = guardianEmailFormatted.trim();

			// Convert Participant Date to YYYY-MM-DD format
			participantDateOfBirthFormatted = moment(participantDateOfBirthFormatted).format("YYYY-MM-DD");

			// Convert Guardian Date to YYYY format (if exists)
			if (guardianYearOfBirthFormatted) guardianYearOfBirthFormatted = parseInt(moment(guardianYearOfBirthFormatted).format("YYYY"));

			// Set default values for guardian section
			if (!formConditionals.showGuardianSection) {
				guardianFirstNameFormatted = "";
				guardianLastNameFormatted = "";
				guardianEmailFormatted = "";
				guardianYearOfBirthFormatted = "";
				participantViewingWithGuardianRadioFormatted = "";
			}

            // Check if fields changed and add to submit object
            let updatedFields = {};
            if (editUser.username !== usernameFormatted) updatedFields.username = usernameFormatted;
            if (editUser.first_name !== participantFirstNameFormatted && !signed.current) updatedFields.participant_first_name = participantFirstNameFormatted;
            if (editUser.last_name !== participantLastNameFormatted && !signed.current) updatedFields.participant_last_name = participantLastNameFormatted;
            if (editUser.participant_email !== participantEmailFormatted) updatedFields.participant_email = participantEmailFormatted;
            if (editUser.date_of_birth !== participantDateOfBirthFormatted && !signed.current) updatedFields.date_of_birth = participantDateOfBirthFormatted;
            if (editUser.province !== participantProvinceFormatted) updatedFields.province = participantProvinceFormatted;
            if (editUser.has_impediments !== hasImpedimentsRadioFormatted && !signed.current) updatedFields.has_impediments = hasImpedimentsRadioFormatted;
            if (editUser.guardian_first_name !== guardianFirstNameFormatted && !signed.current) updatedFields.guardian_first_name = guardianFirstNameFormatted;
            if (editUser.guardian_last_name !== guardianLastNameFormatted && !signed.current) updatedFields.guardian_last_name = guardianLastNameFormatted;
            if (editUser.guardian_email !== guardianEmailFormatted) updatedFields.guardian_email = guardianEmailFormatted;
            if (editUser.guardian_year_of_birth !== guardianYearOfBirthFormatted && !signed.current) updatedFields.guardian_year_of_birth = guardianYearOfBirthFormatted;
            if (editUser.is_participant_viewing_the_program !== participantViewingWithGuardianRadioFormatted && !signed.current) updatedFields.is_participant_viewing_the_program = participantViewingWithGuardianRadioFormatted;
            if (editUser.disabled !== disabledRadioFormatted) updatedFields.disabled = disabledRadioFormatted;
            if (editUser.admin_roles[0] !== adminAccessLevelFormatted) updatedFields.admin_level = adminAccessLevelFormatted;
            updatedFields.sport_organization_id = sportOrganizationIdArrayFormatted;
            updatedFields.membership_number = sportOrganizationMembershipNumberArrayFormatted;
            if (adminAccessLevelFormatted == UserRole.LEVEL_1) updatedFields.admin_sport_organization_id = adminSportOrganizationIDFormatted;
            if (updatedFields.admin_level || updatedFields.admin_sport_organization_id) updatedFields.updating_admin_sport_organization = true;

            try {
                // Send request to update user
                response = await updateById(updatedFields, userID);

                setTimeout(() => {
                    switch (response) {
                        case 200: // Request successful
                            setSubmitSuccessCount(submitSuccessCount + 1);
                            formikValidateOnBlur.current = false;
                            genericModalProps.current = {
                                titleText: i18n.t("profile-success-modal-title"),
                                bodyText: i18n.t("profile-success-modal-body"),
                                footerText: i18n.t("profile-success-modal-footer-close")
                            }
                            handleGenericModalShow();
                            break;
                        case 401:
                            navigate("/login");
                            break;
                        case 409: // Duplicate username -- this error is handled by formik
                            break;
                        default: // Generic error message
                            throw "Generic error";
                    }

                    setIsLoading(false);
                }, 200)
            } catch (e) {
                genericModalProps.current = {
                    titleText: i18n.t("error"),
                    bodyText: i18n.t("error-failed-submission"),
                    footerText: i18n.t("close")
                }
                handleGenericModalShow();
                setIsLoading(false);
            }
		},
	})

	async function checkAndValidateUsername() {
        if (editUser.username.trim() != formik.values.username.trim()) {
            const value = await checkUsername(formik.values.username?.trim());
            if (value != null) formikUsernameValid.current = value;
        }
	}

    // Get initially checked admin sport organization IDs
    const getInitialExpanded = (initialChecked, sportOrganizations) => {
		let found = [];
		initialChecked.map((checked) => {
			sportOrganizations.find((element) => {
                    if (element.id == checked && found.indexOf(element.parent_id) == -1) {
					found.push(element.parent_id);
				}
			})
		})

        if (found.length && found.indexOf(0) == -1) {
            found.unshift(0);
        }

		return found;
	}
	
    let adminSportOrganizationIDs = editUser.admin_sport_organizations.map(element => element.id);
	const initialExpanded = getInitialExpanded(adminSportOrganizationIDs, sportOrganizations);
    const { checked, expanded, setExpanded, treeNodes, handleTreeChange, filterText, setFilterText } = useCheckboxTree(
        sportOrganizations,
        undefined,
        adminSportOrganizationIDs,
        initialExpanded,
        formik
    );

    // Set admin sport organization tree expanded values 
    useEffect(() => {
        handleTreeChange(adminSportOrganizationIDs);
        setExpanded(initialExpanded);
    }, [sportOrganizations, editUser])

    // Set formik values
	useEffect(() => {
        initialValues = {
            username: editUser.username,
            participantFirstName: editUser.first_name,
            participantLastName: editUser.last_name,
            participantEmail: editUser.participant_email,
            participantDateOfBirth: editUser.date_of_birth ? moment(editUser.date_of_birth, "YYYY-MM-DD").toDate() : null,
            participantProvince: editUser.province,
            userSports: editUser.sport_organizations,
            hasImpedimentsRadio: editUser.has_impediments,
            guardianFirstName: editUser.guardian_first_name ?? null,
            guardianLastName: editUser.guardian_last_name ?? null,
            guardianEmail: editUser.guardian_email ?? null,
            guardianYearOfBirth: editUser.guardian_year_of_birth ? moment(editUser.guardian_year_of_birth, "YYYY").toDate() : null,
            participantViewingWithGuardianRadio: editUser.is_participant_viewing_the_program ?? null,
            disabledRadio: editUser.disabled,
            mailedInConsentRadio: editUser.last_progress == "download" && false || editUser.last_progress == "mailed_in" && true,
            adminAccessLevel: editUser.admin_roles[0] || "none",
            adminAccessSportOrganizations: editUser.admin_sport_organizations.map(element => element.id)
        }

        formik.resetForm({
            values: initialValues
        });

        setFormConditionals({ 
            showForm: true, 
            showGuardianSection: editUser.guardian_email ? true : false,
            showDisabledUser: user && user.adminRoles.includes(UserRole.LEVEL_2) ? true : false,
            showUserConsentStatus: editUser.last_progress == "download" || editUser.last_progress == "mailed_in" ? true : false,
            showAdminAccessLevel: user && user.adminRoles.includes(UserRole.LEVEL_2) ? true : false,
            showAdminAccessSportOrganizationTree: user && user.adminRoles.includes(UserRole.LEVEL_2) && editUser.admin_roles.includes(UserRole.LEVEL_1) ? true : false
        })

        signed.current = userHasSigned(editUser.consents);
    }, [editUser]);

	// Show/hide guardian section based on criteria
	useEffect(() => {
        if (signed.current == true) return;

		userIsOfAge.current = isUserAgeOfConsent(formik.values.participantDateOfBirth)
        hasImpediments.current = userHasImpediments(formik.values.hasImpedimentsRadio)

        if (formConditionals.showForm) {
            if (formConditionals.showGuardianSection == false) {
                // Reveal guardian section if user is under 19 years of age
                if (!userIsOfAge.current) {
                    toast.dismiss();
                    setFormConditionals({...formConditionals, showGuardianSection: true })
                    toast.info(i18n.t("under-19-show-guardian-section"));
                }
                // Reveal guardian section if user has impediments
                else if (hasImpediments.current) {
                    toast.dismiss();
                    setFormConditionals({...formConditionals, showGuardianSection: true })
                    toast.info(i18n.t("has-impediments-show-guardian-section"));
                }
            }
            // Hide guardian section if user is over 19 years of age and has no impediments
            else if (formConditionals.showGuardianSection == true && userIsOfAge.current && !hasImpediments.current) {
                toast.dismiss();
                setFormConditionals({...formConditionals, showGuardianSection: false })
                toast.info(i18n.t("over-19-no-impediments-hide-guardian-section"));
            }
        }

	}, [formik.values.participantDateOfBirth, formik.values.hasImpedimentsRadio])

    // Show/hide admin access sport organization tree section based on criteria
    useEffect(() => {
        if (formConditionals.showForm) {
            setFormConditionals({
                ...formConditionals,
                showAdminAccessSportOrganizationTree: user && user.adminRoles.includes(UserRole.LEVEL_2) && formik.values.adminAccessLevel && formik.values.adminAccessLevel.includes(UserRole.LEVEL_1) ? true : false
            })
        }
    }, [formik.values.adminAccessLevel])

	// Focus username field if username is invalid and form submitted
	useEffect(() => {
		if (!formikUsernameValid.current) {
			usernameInputRef.current.focus();
		}
	}, [formik.submitCount]);

	// Handles showing/hiding of form submission success modal
	const handleGenericModalClose = () => setShowGenericModal(false);
	const handleGenericModalShow = () => setShowGenericModal(true);

	return (
		<>
        {isLoading &&
            <LoadingSpinner
                isLoading={isLoading}
            />
        }

			<GenericModal
				show={showGenericModal}
				onHide={handleGenericModalClose}
				backdrop="static"
				keyboard={false}
				titleText={genericModalProps.current.titleText}
                bodyText={genericModalProps.current.bodyText}
                footerText={genericModalProps.current.footerText}
			/>

			<Container fluid>
                <Form id="accountInformationForm" onSubmit={formik.handleSubmit} noValidate>
                    <FocusError formik={formik} />
                    <Row>
                        <Col>
                            <h4 className="fw-normal d-inline-block mb-2">{user && user.adminRoles.includes(UserRole.LEVEL_2) ? i18n.t("edit-user") : i18n.t("view-user")}</h4>
                            <div className="fw-normal text-muted">{i18n.t("edit-user-info")}</div>
                        </Col>
                    {signed.current && (
                        <Col lg={6}>
                            <div className={`${styles.disabledLabel} text-danger`}><em>{i18n.t("fields-disabled-label")}</em></div>
                        </Col>
                    )}
                    </Row>
                    <Row className="g-3 mt-2">
                    {editUser && (
                        <Col sm={12}>
                            <UserStatistics 
                                editUserProfile={editUser} 
                                adminAccessLevel={user && user.adminRoles}
                                userID={userID}
                            />
                        </Col>
                    )}
                        <Col sm={12} className="mb-3">
                            <Username
                                value={formik.values.username}
                                placeholder={i18n.t("username-placeholder")}
                                label={i18n.t("account-username-label")}
                                aria-label={i18n.t("username-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.username && formik.errors.username}
                                disabled={isLoading || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.username}
                                inputRef={usernameInputRef}
                            ></Username>
                        </Col>
						<Col sm={12} className="mb-3">
                            <ParticipantFirstName
                                value={formik.values.participantFirstName}
                                placeholder={i18n.t("participant-first-name-placeholder")}
                                label={i18n.t("participant-first-name-label")}
                                aria-label={i18n.t("participant-first-name-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.participantFirstName && formik.errors.participantFirstName}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.participantFirstName}
                            ></ParticipantFirstName>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <ParticipantLastName
                                value={formik.values.participantLastName}
                                placeholder={i18n.t("participant-last-name-placeholder")}
                                label={i18n.t("participant-last-name-label")}
                                aria-label={i18n.t("participant-last-name-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.participantLastName && formik.errors.participantLastName}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.participantLastName}
                            ></ParticipantLastName>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <ParticipantEmail
                                value={formik.values.participantEmail}
                                placeholder={i18n.t("participant-email-placeholder")}
                                label={i18n.t("participant-email-label")}
                                aria-label={i18n.t("participant-email-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.participantEmail && formik.errors.participantEmail}
                                disabled={isLoading || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.participantEmail}
                                showGuardianSection={formConditionals.showGuardianSection}
                                twoFactorLabel={i18n.t("two-factor-email-label")}
                            ></ParticipantEmail>
                        </Col>
						<Col sm={12} className="mb-3">
                            <ParticipantDateOfBirth
                                placeholder={i18n.t("participant-date-of-birth-placeholder")}
                                label={i18n.t("participant-dob-label")}
                                selected={formik.values.participantDateOfBirth}
                                onChange={(date) => { 
                                    formik.setFieldValue("participantDateOfBirth", date);
                                }}
                                onBlur={() => { 
                                    formik.validateField("participantDateOfBirth");
                                }}
                                isInvalid={formik.touched.participantDateOfBirth && formik.errors.participantDateOfBirth}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                locale={language}
                                errors={formik.errors.participantDateOfBirth}
                                formik={formik}
                            >
                            </ParticipantDateOfBirth>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <Province
                                provinceSelectedString={formik.values.participantProvince}
                                id="participantProvince"
                                name="participantProvince"
                                label={i18n.t("province-label")}
                                placeholder={i18n.t("province-placeholder")}
                                onChange={(e) => { formik.setFieldValue("participantProvince", e.value) }}
                                onBlur={() => { formik.setFieldTouched("participantProvince") }}
                                isInvalid={formik.touched.participantProvince && formik.errors.participantProvince}
                                disabled={isLoading || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.participantSport}
                                required
                                formik={formik}
                            />
                        </Col>
                        <hr className="my-4" />
                        <Col sm={12} className="text-center mb-3">
                            <HasImpediments
                                label={i18n.t("has-impediments-label")}
                                labelNo={i18n.t("has-impediments-no")}
                                labelYes={i18n.t("has-impediments-yes")}
                                autoComplete="on"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                yesChecked={formik.values.hasImpedimentsRadio == "1" ? true : false}
                                noChecked={formik.values.hasImpedimentsRadio == "0" ? true : false}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                formik={formik}
                            />
                        </Col>
					</Row>

				{formConditionals.showGuardianSection && (
                    <Row className="g-3">
                        <hr className="my-4" />
                        <Col sm={12} className="mb-3">
                            <GuardianFirstName
                                value={formik.values.guardianFirstName}
                                placeholder={i18n.t("guardian-first-name-placeholder")}
                                label={i18n.t("guardian-first-name-label")}
                                aria-label={i18n.t("guardian-first-name-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.guardianFirstName && formik.errors.guardianFirstName}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.guardianFirstName}
                            ></GuardianFirstName>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <GuardianLastName
                                value={formik.values.guardianLastName}
                                placeholder={i18n.t("guardian-last-name-placeholder")}
                                label={i18n.t("guardian-last-name-label")}
                                aria-label={i18n.t("guardian-last-name-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.guardianLastName && formik.errors.guardianLastName}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.guardianLastName}
                            ></GuardianLastName>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <GuardianEmail
                                value={formik.values.guardianEmail}
                                placeholder={i18n.t("guardian-email-placeholder")}
                                label={i18n.t("guardian-email-label")}
                                aria-label={i18n.t("guardian-email-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.guardianEmail && formik.errors.guardianEmail}
                                disabled={isLoading || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                errors={formik.errors.guardianEmail}
                                twoFactorLabel={i18n.t("two-factor-email-label")}
                            ></GuardianEmail>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <GuardianYearOfBirth
                                label={i18n.t("guardian-yob-label")}
                                placeholder={i18n.t("guardian-yob-placeholder")}
                                selected={formik.values.guardianYearOfBirth}
                                onChange={(date) => {
                                    formik.setFieldValue("guardianYearOfBirth", date)
                                }}
                                onBlur={() => { 
                                    formik.validateField("guardianYearOfBirth");
                                }}
                                isInvalid={formik.touched.guardianYearOfBirth && formik.errors.guardianYearOfBirth}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                locale={language}
                                errors={formik.errors.guardianYearOfBirth}
                                yearOfBirthLabel={i18n.t("year-of-birth-label")}
                                formik={formik}
                            >
                            </GuardianYearOfBirth>
                        </Col>
                        <hr className="mt-4 mb-1" />
                        <Col sm={12} className="text-center">
                            <ParticipantViewingWithGuardian
                                label={i18n.t("participant-viewing-with-guardian-label")}
                                labelNo={i18n.t("participant-viewing-with-guardian-no")}
                                labelYes={i18n.t("participant-viewing-with-guardian-yes")}
                                autoComplete="on"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                yesChecked={formik.values.participantViewingWithGuardianRadio == "1" ? true : false}
                                noChecked={formik.values.participantViewingWithGuardianRadio == "0" ? true : false}
                                disabled={isLoading || signed.current || (user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true)}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                )}

                <hr />

                <h4>{i18n.t("sport-selection-header")}</h4>
                <div className="form-text">{i18n.t("profile-sport-selection-info")}</div>
                <SportSelection
                    sportCounter={sportCounter}
                    isLoading={isLoading}
                    formik={formik}
                    sportOrganizations={sportOrganizations}
                    allowZeroItems={true}
                    disabled={user && user.adminRoles.includes(UserRole.LEVEL_2) ? false : true}
                />
                
                {formConditionals.showDisabledUser && (
                    <hr className="my-4" />
                )}

                {formConditionals.showDisabledUser && (
                    <div>
                        <Row className="mb-3">
                            <Col sm={12} className="form-group bg-light border rounded mb-3 p-2">
                                <UserDisabled
                                    label={i18n.t("user-access-label")}
                                    fieldLabel={i18n.t("user-disabled-label")}
                                    onChange={e => formik.setFieldValue('disabledRadio', e.target.checked)}
                                    onBlur={formik.handleBlur}
                                    checked={formik.values.disabledRadio}
                                    formik={formik}
                                >
                                </UserDisabled>
                            </Col>
                        </Row>
                    </div>
                )}
                {formConditionals.showUserConsentStatus && (
                    <Row className="mb-3">
                        <Col sm={12} className="form-group bg-light border rounded mb-3 p-2">
                            <MailedInConsentReceived
                                label={i18n.t("user-consent-status-label")}
                                fieldLabel={i18n.t("mailed-in-consent-form-received-label")}
                                onBlur={formik.handleBlur}
                                checked={formik.values.mailedInConsentRadio}
                                formik={formik}
                                disabled={editUser.last_progress == "mailed_in" || (user && user.adminRoles.includes(UserRole.LEVEL_1)) ? true : false}
                                userID={userID}
                                submitSuccessCount={submitSuccessCount}
                                setSubmitSuccessCount={setSubmitSuccessCount}
                            >
                            </MailedInConsentReceived>
                        </Col>
                    </Row>
                )}
                {formConditionals.showAdminAccessLevel && (
                    <Row className="mb-3">
                        <Col sm={12} className="form-group bg-light border rounded mb-3 p-2">
                            <AdminAccessLevel
                                adminAccessLevelSelectedString={formik.values.adminAccessLevel}
                                id="adminAccessLevel"
                                name="adminAccessLevel"
                                label={i18n.t("admin-access-level-label")}
                                placeholder={i18n.t("admin-access-level-placeholder")}
                                onChange={(e) => { formik.setFieldValue("adminAccessLevel", e.value) }}
                                onBlur={() => { formik.setFieldTouched("adminAccessLevel") }}
                                isInvalid={formik.touched.adminAccessLevel && formik.errors.adminAccessLevel}
                                disabled={isLoading}
                                errors={formik.errors.adminAccessLevel}
                                formik={formik}
                            />
                        </Col>
                    </Row>
                )}
                {formConditionals.showAdminAccessSportOrganizationTree && (
                    <Row className="mb-3">
                        <Col sm={12} className="form-group bg-light border rounded mb-3 p-2">
                            <AdminAccessSportOrganizationTree
                                id="adminAccessSportOrganization"
                                name="adminAccessSportOrganization"
                                label={i18n.t("admin-access-sport-organization-label")}
                                nodes={treeNodes}
                                checked={checked}
                                expanded={expanded}
                                onCheck={handleTreeChange}
                                onExpand={setExpanded}
                                filterText={filterText}
                                onFilterChange={setFilterText}
                            />
                        </Col>
                    </Row>
                )}
                {user && user.adminRoles.includes(UserRole.LEVEL_2) && (
					<Button 
                        type="submit" 
                        variant="primary" 
                        size="lg" 
                        className="w-75 mx-auto d-block mt-4 mb-2" 
                        onClick={() => {
                            formikValidateOnBlur.current = true;
                        }} 
                        disabled={isLoading}>
                        {!isLoading && i18n.t("save-profile")}
                        {isLoading && i18n.t("loading")}
                        {isLoading && (
                            <LoadingSpinner
                                as="span"
                                style={{ height: 25, width: 25, position: "absolute", right: 20, marginTop: 2 }}
                                animation="border"
                                role="status"
                                aria-hidden="true"
                            />
                        )}
                    </Button>
                )}
				</Form>
			</Container>	
		</>
	);
}

export default EditUser;
