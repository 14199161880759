import axios from "axios";
import config from "app/config";

export const searchUser = async (search, includeDisabled) => {
    try {
        const url = `${config.BASE_URL}/users/search`;
        const payload = {
            ...search,
            includeDisabled,
        };

        const response = await axios.post(url, payload, {
            withCredentials: true,
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return { error: `Unexpected status code: ${response.status}` };
        }
    } catch (error) {
        if (error.response && error.response.status === 401) {
            return { error: "Forbidden. Authentication failed." };
        }
        if (error.response && error.response.data && error.response.data.message) {
            return { error: error.response.data.message };
        }
        return { error: "An unexpected error occurred." };
    }
};

export const getStatistics = async () => {
	try {
		const response = await axios.get(`${config.BASE_URL}/users/statistics`, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 403) {
			return { error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the user retrieval process." };
	}
};

export const getSignature = async () => {
	try {
		const response = await axios.get(`${config.BASE_URL}/consents/signature`, {
			responseType: "blob",
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		return error.response.status;
	}
};

export const getSignatureForUser = async (userId) => {
	try {
		const response = await axios.get(`${config.BASE_URL}/consents/signature/${userId}`, {
			responseType: "blob",
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		return error.response.status;
	}
};

export const getTemplate = async () => {
	try {
		const response = await axios.get(`${config.BASE_URL}/consents/template`, {
			responseType: "blob",
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		return error.response.status;
	}
};

export const saveMailedIn = async (userId, signature, uccmsVersion) => {
	try {
		const response = await axios.put(
			`${config.BASE_URL}/consents/user/${userId}`,
			{
				signature,
				uccms_version: uccmsVersion,
			},
			{ withCredentials: true }
		);

		return response.status;
	} catch (error) {
		return error.response.status;
	}
};

export const saveSignature = async (signature, isSignatureTyped) => {
	try {
		const response = await axios.post(
			`${config.BASE_URL}/consents`,
			{
				signature,
				is_signature_typed: isSignatureTyped,
			},
			{ withCredentials: true }
		);

		return response.status;
	} catch (error) {
		return error.response.status;
	}
};

export const update = async (payload) => {
	try {
		const response = await axios.put(`${config.BASE_URL}/users/current`, payload, {
			withCredentials: true,
		});

		return response.status;
	} catch (error) {
		return error.response.status;
	}
};

export const updateById = async (payload, userId) => {
	try {
		const response = await axios.put(`${config.BASE_URL}/users/${userId}`, payload, {
			withCredentials: true,
		});

		return response.status;
	} catch (error) {
		return error.response.status;
	}
};

export const searchReport = async (reportUrl, filters) => {
	try {
		const url = config.BASE_URL + reportUrl;
		const response = await axios.post(url, filters, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		if (error.response && error.response.data && error.response.data.message) {
			return { error: error.response.data.message };
		}
		return { error: "An unexpected error occurred." };
	}
};

export const searchUserReport = async (filters) => {
	return searchReport("/users/report", filters);
};

export const searchConsentStatusReport = async (filters) => {
	return searchReport("/users/status-report", filters);
};

export const searchAdminReport = async (filters) => {
	return searchReport("/users/admin-report", filters);
};

export const searchAdminActivityReport = async (filters) => {
	return searchReport("/admin-activities/report", filters);
};

export const searchUserSportOrganizationTrackingReport = async (filters) => {
	return searchReport("/user-sport-organizations/report", filters);
};

export const resetPasswordAuthenticated = async (current_password, new_password) => {
	try {
		const payload = {
			current_password,
			new_password,
		};

		const response = await axios.patch(`${config.BASE_URL}/users/password/authenticated-reset`, payload, {
			withCredentials: true,
		});

		return response;
	} catch (error) {
		return error.response;
	}
};

export const resetPassword = async (username, password = null, code = null) => {
	try {
		const payload = {
			username,
			...(password && { password: password }),
			...(code && { email_verification_code: code }),
		};

		const response = await axios.patch(`${config.BASE_URL}/users/password`, payload, { withCredentials: true });

		return response;
	} catch (error) {
		return error.response;
	}
};

export const register = async (
	participantFirstName,
	participantLastName,
	participantEmail,
	password,
	language,
	username,
	dateOfBirth,
	hasImpediments,
	province,
	sportOrganizationIds,
	sportOrganizationMembershipNumbers = [],
	isParticipantViewingTheProgram = null,
	guardianEmail = null,
	guardianFirstName = null,
	guardianLastName = null,
	guardianYearOfBirth = null
) => {
	try {
		const payload = {
			participant_first_name: participantFirstName,
			participant_last_name: participantLastName,
			participant_email: participantEmail,
			password,
			language,
			username,
			date_of_birth: dateOfBirth,
			has_impediments: hasImpediments,
			province,
			sport_organization_id: sportOrganizationIds,
			sport_organization_membership_number: sportOrganizationMembershipNumbers,
		};

		if (guardianFirstName) payload.guardian_first_name = guardianFirstName;
		if (guardianLastName) payload.guardian_last_name = guardianLastName;
		if (guardianEmail) payload.guardian_email = guardianEmail;
		if (guardianYearOfBirth) payload.guardian_year_of_birth = guardianYearOfBirth;
		if (isParticipantViewingTheProgram) {
			payload.is_participant_viewing_the_program = isParticipantViewingTheProgram;
		}

		const response = await axios.post(`${config.BASE_URL}/users`, payload, {
			withCredentials: true,
		});

		return response;
	} catch (error) {
		return error.response;
	}
};

export const login = async (username, password, code = null) => {
	try {
		const payload = {
			username,
			password,
			...(code && { email_verification_code: code }),
		};

		const response = await axios.post(`${config.BASE_URL}/users/session`, payload, { withCredentials: true });

		return response;
	} catch (error) {
		return error.response;
	}
};

export const logout = async () => {
	try {
		const response = await axios.delete(`${config.BASE_URL}/users/session`, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the logout process." };
	}
};

export const getUser = async () => {
	try {
		const response = await axios.get(`${config.BASE_URL}/users/current`, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the user retrieval process." };
	}
};

export const getProfile = async () => {
	try {
		const response = await axios.get(`${config.BASE_URL}/users/current/profile`, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return { status: response.status, data: response.data };
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { status: error.response.status, error: "Forbidden. Authentication failed." };
		}
		return { status: error.response.status, error: "An error occurred during the user retrieval process." };
	}
};

export const getProfileById = async (userId) => {
	try {
		const response = await axios.get(`${config.BASE_URL}/users/${userId}/profile`, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return { status: response.status, data: response.data };
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { status: error.response.status, error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the user profile retrieval process." };
	}
};

export const checkUsername = async (username) => {
	try {
		const url = `${config.BASE_URL}/users/username-availability`;
		const response = await axios.get(url, {
			params: {
				username: username,
			},
			withCredentials: true,
		});
		if (response.status === 200) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		return false;
	}
};

export const getMaskedEmails = async (participantFirstName, participantLastName, dateOfBirth) => {
	try {
		const url = `${config.BASE_URL}/users/masked-emails`;
		const response = await axios.get(url, {
			params: {
				participant_first_name: participantFirstName,
				participant_last_name: participantLastName,
				date_of_birth: dateOfBirth,
			},
			withCredentials: true,
		});
		if (response.status === 200 && response.data.emails) {
			return response.data.emails;
		} else {
			return [];
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		if (error.response && error.response.data && error.response.data.message) {
			return { error: error.response.data.message };
		}
		return { error: "An unexpected error occurred." };
	}
};
