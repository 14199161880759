import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { resetPasswordAuthenticated } from "../../../../features/user/userAPI";

/* Import components */
import { Container, Form, Button, Row, Col, Spinner } from "react-bootstrap";
import Password from "../../../../components/Fields/Password";
import GenericModal from "../../../../components/GenericModal";
import LoadingSpinner from "components/LoadingSpinner";
import { FocusError } from 'focus-formik-error';

function ResetPasswordTab({ }) {
	const { i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [showGenericModal, setShowGenericModal] = useState(false);

    const formikValidateOnBlur = useRef(false);
    const genericModalProps = useRef({titleText: "", bodyText: "", footerText: ""});

	const formik = useFormik({
        validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
        initialValues: {
			currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
		},
        validationSchema: Yup.object({
            currentPassword: Yup.string()
                .min(8, i18n.t("invalid-password"))
                .required(i18n.t("password-required")),
            newPassword: Yup.string()
                .min(8, i18n.t("invalid-new-password"))
                .required(i18n.t("new-password-required")),
            confirmNewPassword: Yup.string()
                .min(8, i18n.t("invalid-new-password"))
                .required(i18n.t("new-password-required"))
                .oneOf([Yup.ref("newPassword")], i18n.t("new-passwords-must-match")),
        }),
        onSubmit: async (values) => {
            setIsLoading(true);
            let response;

            try {
                response = await resetPasswordAuthenticated(values.currentPassword, values.newPassword)

                setTimeout(() => {
                    switch (response.status) {
                        case 200: // Request successful
                            genericModalProps.current = {
                                titleText: i18n.t("password-reset-success-modal-title"),
                                bodyText: i18n.t("password-reset-success-modal-body"),
                                footerText: i18n.t("password-reset-success-modal-footer-button")
                            }
                            handleGenericModalShow(true);
                            
                            formikValidateOnBlur.current = false;
                            formik.resetForm();
                            break;
                        case 401: // Request denied
                            genericModalProps.current = {
                                titleText: i18n.t("password-reset-incorrect-modal-title"),
                                bodyText: i18n.t("password-reset-incorrect-modal-body"),
                                footerText: i18n.t("password-reset-incorrect-modal-footer-button")
                            }
                            handleGenericModalShow(true);
                            break;
                        default: // Generic error message
                            throw "Generic error";
                    }

                    setIsLoading(false);
                }, 200)
            } catch (e) {
                genericModalProps.current = {
                    titleText: i18n.t("error"),
                    bodyText: i18n.t("error-failed-submission"),
                    footerText: i18n.t("close")
                }
                handleGenericModalShow();
                setIsLoading(false);
            }
        }
	});

	// Handles showing/hiding of form submission success modal
    const handleGenericModalClose = () => setShowGenericModal(false);
    const handleGenericModalShow = () => setShowGenericModal(true);

    return (
        <>
        {isLoading && (
            <LoadingSpinner
                isLoading={isLoading}
            />
        )}

            <GenericModal
                show={showGenericModal}
                onHide={handleGenericModalClose}
                backdrop="static"
                keyboard={false}
                titleText={genericModalProps.current.titleText}
                bodyText={genericModalProps.current.bodyText}
                footerText={genericModalProps.current.footerText}
            />

            <Container fluid>
                <Form id="resetPasswordForm" onSubmit={formik.handleSubmit} noValidate>
                    <FocusError formik={formik} />
                    <h4 role="heading" aria-level={2} className="fw-normal mb-2">{i18n.t("reset-password-header")}</h4>
                    <span className="fw-normal text-muted">{i18n.t("profile-reset-password-info")}</span>
                    <Row className="g-3 mt-2">
                        <Col sm={12} className="mb-3">
                            <Password
                                value={formik.values.currentPassword}
                                id="currentPassword"
                                name="currentPassword"
                                label={i18n.t("current-password-label")}
                                placeholder={i18n.t("current-password-placeholder")}
                                ariaLabel={i18n.t("current-password-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.currentPassword && formik.errors.currentPassword}
                                disabled={formik.isSubmitting}
                                errors={formik.errors.currentPassword}
                            ></Password>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <Password
                                value={formik.values.newPassword}
                                id="newPassword"
                                name="newPassword"
                                label={i18n.t("new-password-label")}
                                placeholder={i18n.t("new-password-placeholder")}
                                ariaLabel={i18n.t("new-password-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.newPassword && formik.errors.newPassword}
                                disabled={formik.isSubmitting}
                                errors={formik.errors.newPassword}
                                invalidFeedbackID="newPassword-invalid-feedback"
                            ></Password>
                        </Col>
                        <Col sm={12} className="mb-3">
                            <Password
                                value={formik.values.confirmNewPassword}
                                id="confirmNewPassword"
                                name="confirmNewPassword"
                                label={i18n.t("confirm-new-password-label")}
                                placeholder={i18n.t("confirm-new-password-placeholder")}
                                ariaLabel={i18n.t("confirm-new-password-placeholder")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                isInvalid={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword}
                                disabled={formik.isSubmitting}
                                errors={formik.errors.confirmNewPassword}
                                invalidFeedbackID="confirmPassword-invalid-feedback"
                            ></Password>
                        </Col>
                        <Button 
                            type="submit" 
                            variant="primary" 
                            size="lg" 
                            className="w-75 mx-auto d-block mt-4 mb-2" 
                            onClick={() => {
                                formikValidateOnBlur.current = true;
                            }} 
                            disabled={formik.isSubmitting}>
                            {!isLoading && i18n.t("save-now")}
                            {isLoading && i18n.t("loading")}
                            {isLoading && (
                                <Spinner
                                    as="span"
                                    style={{ height: 25, width: 25, position: "absolute", right: 20, marginTop: 2 }}
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                            )}
                        </Button>
                    </Row>
                </Form>
            </Container>
        </>
    );
}

export default ResetPasswordTab;
