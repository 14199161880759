import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import {
	Form,
	Button,
	InputGroup,
	FormControl,
	Row,
	Col,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { saveSignature, getTemplate, update } from "features/user/userAPI";
import { useTranslation, Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import styles from "./Signature.module.css";
import { validFirstLastName } from "app/regex";
import { FocusError } from "focus-formik-error";
import { srAnnounce } from "features/global/functions";

function Signature({ continueProgress }) {
	const navigate = useNavigate();
	const sigCanvas = useRef(null);
	const canvasContainer = useRef(null);
	const aspectRatio = 3 / 1;
	const [canvasSize, setCanvasSize] = useState({
		width: 800,
		height: 266.66,
	});
	const [template, setTemplate] = useState();
	const { i18n } = useTranslation();
	const typedSignatureRef = useRef(null);

	// resize signature canvas area
	useEffect(() => {
		const updateCanvasSize = () => {
			if (canvasContainer.current && sigCanvas.current) {
				sigCanvas.current.off();

				const parentWidth = Math.min(
					canvasContainer.current.offsetWidth,
					800
				);

				setCanvasSize({
					width: parentWidth,
					height: parentWidth / aspectRatio,
				});

				sigCanvas.current.clear();
				sigCanvas.current.on();
			}
		};

		updateCanvasSize();
		window.addEventListener("resize", updateCanvasSize);

		// Initialize a variable to keep track of the previous width
		let previousWidth = null;

		// Create a ResizeObserver instance
		const resizeObserver = new ResizeObserver((entries) => {
			for (let entry of entries) {
				// Get the current inner width of the observed element
				const innerWidth = entry.contentRect.width;

				// Check if the width has changed
				if (innerWidth !== previousWidth) {
					// Update the previous width
					previousWidth = innerWidth;

					// Perform actions based on the inner width change
					updateInnerWidth(innerWidth);
				}
			}
		});

		// Observer div.page element to detect when sidebar collapsed or expanded
		const elementToObserve = document.querySelector("div.page");

		// Start observing the element's size changes
		resizeObserver.observe(elementToObserve);

		// Function to handle inner width change
		function updateInnerWidth(innerWidth) {
			updateCanvasSize();
		}

		return () => {
			resizeObserver.disconnect();
			window.removeEventListener("resize", updateCanvasSize);
		};
	}, [aspectRatio]);

	// load in pdf template
	useEffect(() => {
		const downloadTemplate = async () => {
			const templateData = await getTemplate();
			setTemplate(templateData);
		};
		downloadTemplate();
	}, [i18n.language]);

	const isCanvasEmpty = () =>
		!formik.values.isSignatureTyped && sigCanvas.current.isEmpty();

	const handleClear = () => {
		srAnnounce(i18n.t("signature-cleared"), true);
		if (!formik.values.isSignatureTyped) {
			sigCanvas.current.clear();
		} else {
			formik.setFieldValue("typedSignature", "");
			setTimeout(() => {
				if (typedSignatureRef.current) {
					typedSignatureRef.current.focus();
				}
			}, 300);
		}
	};

	const handleSigDrawn = () => {
		if (
			sigCanvas.current &&
			!sigCanvas.current.isEmpty() &&
			!formik.values.isSignatureTyped
		) {
			formik.setFieldValue("typedSignature", "");
		}
	};

	const handleSave = async () => {
		if (!isCanvasEmpty()) {
			const signature = formik.values.isSignatureTyped
				? formik.values.typedSignature
				: sigCanvas.current.toDataURL();
			await saveSignature(signature, formik.values.isSignatureTyped);
			continueProgress();
		}
	};

	const handleDownload = async () => {
		update({
			last_progress: "download",
		});
		const fileName = i18n.t("signature-file-name");
		const url = window.URL.createObjectURL(new Blob([template]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
	};

	const validationSchema = Yup.object({
		isSignatureTyped: Yup.boolean(),
		typedSignature: Yup.string().when("isSignatureTyped", {
			is: true,
			then: () =>
				Yup.string()
					.required(i18n.t("signature-required"))
					.matches(
						validFirstLastName,
						i18n.t("invalid-typed-signature-regex")
					),
			otherwise: () => Yup.string(),
		}),
		signatureCanvas: Yup.string().when("isSignatureTyped", {
			is: false,
			then: () =>
				Yup.string().test(
					"signature-canvas",
					i18n.t("draw-signature-required"),
					function () {
						return !isCanvasEmpty();
					}
				),
		}),
	});

	const formik = useFormik({
		initialValues: {
			isSignatureTyped: false,
			typedSignature: "",
		},
		validationSchema: validationSchema,

		onSubmit: handleSave,
	});

	// Announce naviation to screen reader
	useLayoutEffect(() => {
		srAnnounce(
			i18n.t("sign-the-participant-consent") +
				". " +
				i18n.t("sign-the-participant-consent-instructions-1") +
				" " +
				i18n.t("sign-the-participant-consent-instructions-2") +
				i18n.t("mail-in-address") +
				". " +
				i18n.t("sign-the-participant-consent-instructions-3")
		);
	}, [navigate, i18n]);

	useEffect(() => {
		if (!formik.values.isSignatureTyped && formik.errors.signatureCanvas) {
			setTimeout(() => {
				srAnnounce(formik.errors.signatureCanvas, true);
			}, 200);
		}
	}, [formik]);

	useEffect(() => {
		if (formik.touched.typedSignature && formik.errors.typedSignature) {
			setTimeout(() => {
				srAnnounce(formik.errors.typedSignature, true);
			}, 200);
		}
	}, [formik]);

	return (
		<>
			<div className="page-header mb-3 mt-2 badge">
				<h1>{i18n.t("sign-the-participant-consent")}</h1>
			</div>
			<Form
				noValidate
				onSubmit={formik.handleSubmit}
				className="border-bottom pb-4"
			>
				<FocusError formik={formik} />
				<Row>
					<Col>
						<Trans i18nKey="sign-the-participant-consent-instructions-1" />
						<div
							role="radiogroup"
							className="bg-sdrcc border rounded p-4"
						>
							<Form.Check
								inline
								id="draw-signature-radio"
								type="radio"
								label={i18n.t("draw-signature")}
								checked={!formik.values.isSignatureTyped}
								onChange={() =>
									formik.setFieldValue(
										"isSignatureTyped",
										false
									)
								}
								name="isSignatureTyped"
							/>
							<Form.Check
								inline
								id="type-signature-radio"
								type="radio"
								label={i18n.t("type-signature")}
								checked={formik.values.isSignatureTyped}
								onChange={() => {
									formik.setFieldValue(
										"isSignatureTyped",
										true
									);
									formik.setFieldTouched(
										"typedSignature",
										false,
										false
									);
								}}
								name="isSignatureTyped"
							/>
							<div className="mt-3">
								{formik.values.isSignatureTyped ? (
									<InputGroup className="mb-3">
										<FormControl
											placeholder={i18n.t(
												"type-your-signature-here"
											)}
											{...formik.getFieldProps(
												"typedSignature"
											)}
											aria-labelledby="invalid-feedback-typedSignature"
											title={i18n.t("type-signature")}
											ref={typedSignatureRef}
										/>
									</InputGroup>
								) : (
									<div
										ref={canvasContainer}
										className={styles.signatureContainer}
										aria-hidden="true"
									>
										<SignatureCanvas
											ref={sigCanvas}
											canvasProps={{
												className:
													styles.signatureCanvas,
												width: canvasSize.width,
												height: canvasSize.height,
											}}
											aria-labelledby="invalid-feedback-signatureCanvas"
											title={i18n.t("draw-signature")}
											onEnd={handleSigDrawn}
										/>
									</div>
								)}
							</div>
							{formik.touched.typedSignature &&
							formik.errors.typedSignature ? (
								<div
									id="invalid-feedback-typedSignature"
									className="text-danger"
								>
									{formik.errors.typedSignature}
								</div>
							) : null}
							{!formik.values.isSignatureTyped &&
								formik.errors.signatureCanvas && (
									<div
										id="invalid-feedback-signatureCanvas"
										className="text-danger mt-2"
									>
										{formik.errors.signatureCanvas}
									</div>
								)}
							<div className="mt-3">
								<Button
									variant="secondary"
									onClick={handleClear}
									className="me-2"
									aria-label={i18n.t("clear-signature")}
								>
									{i18n.t("clear")}
								</Button>
								<Button
									type="submit"
									variant="primary"
									className="me-2"
									aria-label={i18n.t("submit-signature")}
								>
									{i18n.t("submit")}
								</Button>
							</div>
						</div>
						<div className="mt-4" id="mailedin-instructions">
							<Trans i18nKey="sign-the-participant-consent-instructions-2" />
							<div
								className="alert alert-light text-center font-italic"
								style={{ maxWidth: 500 }}
							>
								<Trans i18nKey="mail-in-address" />
							</div>
							<p>
								<Trans
									i18nKey="sign-the-participant-consent-instructions-3"
									components={[
										<Link
											to="https://support.respectgroupinc.com"
											target="_blank"
											rel="noopener noreferrer"
											tabIndex={-1}
										/>,
									]}
								/>
							</p>
						</div>
						<Button
							variant="pantone-390c"
							onClick={handleDownload}
							className="me-2 text-start"
							aria-describedby="mailedin-instructions"
						>
							<FontAwesomeIcon icon={faDownload} />{" "}
							{i18n.t("download-unsigned-pdf")}
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
}

export default Signature;
