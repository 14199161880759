import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function GuardianLastName({ value, id="guardianLastName", name="guardianLastName", placeholder, label, ariaLabel, onChange, onBlur, isInvalid, disabled=false, errors, invalidFeedbackID="invalid-feedback-guardian-last-name" }) {
	return (
		<>
            <label htmlFor={name} className="form-label">
                {label}
            </label>
			<InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
                <Form.Control
                    value={value}
                    type="text"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
                    onChange={onChange}
                    onBlur={onBlur}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    aria-labelledby={[id,invalidFeedbackID].join(' ')}
                    required
                />
                <Form.Control.Feedback type="invalid" id={invalidFeedbackID}>{errors}</Form.Control.Feedback>
            </InputGroup>
		</>
	);
}

export default GuardianLastName;