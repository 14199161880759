import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function ParticipantEmail({
	value,
	id = "participantEmail",
	name = "participantEmail",
	placeholder,
	label,
	ariaLabel,
	onChange,
	onBlur,
	isInvalid,
	disabled = false,
	errors,
	invalidFeedbackID = "invalid-feedback-email",
	showGuardianSection,
	twoFactorLabel,
}) {
	return (
		<>
			<label htmlFor={name} className="form-label">
				{label}
			</label>
			<InputGroup>
				<InputGroup.Text>
					<FontAwesomeIcon icon={faEnvelope} />
				</InputGroup.Text>
				<Form.Control
					value={value}
					type="email"
					id={id}
					name={name}
					placeholder={placeholder}
					aria-label={ariaLabel}
					aria-describedby={`${id}-form-text`}
					onChange={onChange}
					onBlur={onBlur}
					isInvalid={isInvalid}
					disabled={disabled}
					aria-labelledby={[id, invalidFeedbackID].join(" ")}
					required
				/>
				<Form.Control.Feedback type="invalid" id={invalidFeedbackID}>
					{errors}
				</Form.Control.Feedback>
			</InputGroup>
			{!showGuardianSection && (
				<div id={`${id}-form-text`} className="form-text text-primary">
					{twoFactorLabel}
				</div>
			)}
		</>
	);
}

export default ParticipantEmail;
