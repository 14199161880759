import axios from "axios";
import config from "../../app/config";

export const getSportOrganizations = async (language = "en") => {
	try {
		const url = `${config.BASE_URL}/sport-organizations/?language=${language}`;
		const response = await axios.get(url, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data.sport_organizations;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the sport organizations retrieval process." };
	}
};

export const getAdminSportOrganizations = async (language = "en") => {
	try {
		const url = `${config.BASE_URL}/admin-sport-organizations/?language=${language}`;
		const response = await axios.get(url, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data.sport_organizations;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		return { error: "An error occurred during the sport organizations retrieval process." };
	}
};

export const searchSignatoriesReport = async (filters) => {
	try {
		const url = config.BASE_URL + "/sport-organizations/report";
		const response = await axios.post(url, filters, {
			withCredentials: true,
		});

		if (response.status === 200) {
			return response.data;
		} else {
			return { error: `Unexpected status code: ${response.status}` };
		}
	} catch (error) {
		if (error.response && error.response.status === 401) {
			return { error: "Forbidden. Authentication failed." };
		}
		if (error.response && error.response.data && error.response.data.message) {
			return { error: error.response.data.message };
		}
		return { error: "An unexpected error occurred." };
	}
};
