import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Row, Col, Button, ListGroup, Table, Alert, Spinner, InputGroup, Container } from "react-bootstrap";
import { searchUser } from "features/user/userAPI";
import styles from "./SearchUsers.module.css";
import { useDebounce } from "hooks/useDebounce";
import LoadingSpinner from "components/LoadingSpinner";
import { UserRole } from "features/user/userRole";
import { selectUser } from "features/user/userSlice.js";
import { useSelector } from "react-redux";

function SearchUsers(props) {
	const { i18n } = useTranslation();

	const user = useSelector(selectUser);
	const [isLoading, setIsLoading] = useState(false);
	const [search, setSearch] = useState("");
	const [users, setUsers] = useState([]);
	const [selectedUserId, setSelectedUserId] = useState(null);
	const [includeDisabledUsers, setIncludeDisabledUsers] = useState(false);
	const debouncedSearch = useDebounce(search);

	const handleIncludeDisabledUsersToggle = () => {
		setIncludeDisabledUsers((prev) => !prev)
	}

	const handleInputChange = (e) => {
		setSearch(e.target.value);
	};

	const selectEditUser = (event, userId) => {
		if (event.key && event.key !== "Enter") return;

		if (userId === selectedUserId) setSelectedUserId(null);
		else setSelectedUserId(userId);
	};

	const editUser = (userId) => {
		props.onEditUser(userId);
	};

	// Search for users based on search string
	useEffect(() => {
		const loadUsers = async () => {
			if (!debouncedSearch.trim()) {
				setUsers([]);
				setIsLoading(false);
				return;
			}
			setIsLoading(true);
			const result = await searchUser({ query: debouncedSearch }, includeDisabledUsers);
			setUsers(result.users);
			setIsLoading(false);
		};

		loadUsers();
	}, [debouncedSearch, includeDisabledUsers]);

	return (
		<Container fluid>
			<Form onSubmit={(e) => {e.preventDefault();}}>
				<h5 className="fw-normal">{i18n.t("search-users")}</h5>
				<div className="fw-normal text-muted mb-4">{i18n.t("admin-search-user-info")}</div>
				<Row className="d-flex flex-column">
					<Col md={12} className="mb-3">
						<label htmlFor="search" className="form-label">
							{i18n.t("admin-search-user-label")}
						</label>
						<Form.Control
							type="text"
							name="search"
							id="search"
							placeholder={i18n.t("search")}
							value={search}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={12} className="mb-3">
						<Form.Check
							inline
							id="includeDisabledUsersRadio"
							name="includeDisabledUsersRadio"
							type="switch"
							checked={includeDisabledUsers}
							label="Include Disabled Users"
							onChange={handleIncludeDisabledUsersToggle}
						/>
					</Col>
				</Row>
			</Form>

			{/* Display loading */}
			{isLoading && (
				<Alert aria-live="polite" variant="warning" className="text-center fst-italic fw-bold">
					{i18n.t("admin-search-please-wait")}
					<Spinner animation="border" role="status" size="sm" className="ms-1" />
				</Alert>
			)}

			{/* Display results */}
			{!isLoading && search.trim() && users.length > 0 && (
				<>
					<label htmlFor="search-results" className="form-label text-muted">
						{i18n.t("admin-search-results-label")}
					</label>
					<div className={`${styles.searchResults}`}>
						<Table role="alert" name="search-results" className={`${styles.table}`} striped bordered hover responsive aria-label={i18n.t("search-results-found")}>
							<thead>
								<tr>
									<th className={`${styles.th}`}>{i18n.t("admin-search-full-name")}</th>
									<th className={`${styles.th}`}>{i18n.t("admin-search-username")}</th>
									<th className={`${styles.th}`}>{i18n.t("admin-search-email")}</th>
								</tr>
							</thead>
							<tbody>
								{users.map((user) => (
									<tr
										key={user.id}
										className={`${selectedUserId === user.id ? styles.selectedUser : ""}`}
										onClick={(event) => selectEditUser(event, user.id)}
										onKeyDown={(event) => selectEditUser(event, user.id)}
										onDoubleClickCapture={() => editUser(user.id)}
										tabIndex={0}
										aria-selected={`${selectedUserId === user.id ? "true" : "false"}`}
										role="row"
									>
										<td className={`${styles.td} ${user.disabled && "text-decoration-line-through"}`}>
											{user.participant_first_name} {user.participant_last_name}
										</td>
										<td className={`${styles.td} ${user.disabled && "text-decoration-line-through"}`}>{user.username}</td>
										<td className={`${styles.td} ${user.disabled && "text-decoration-line-through"}`}>{user.participant_email}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
					<div>
						<Button
							variant="primary"
							size="lg"
							className={`w-50 mx-auto d-block mt-4 mb-2 text-decoration-none ${
								selectedUserId ? " pe-auto" : " pe-none "
							}`}
							onClick={() => {
								editUser(selectedUserId);
							}}
							disabled={selectedUserId ? false : true}
						>
							{!isLoading && user && user.adminRoles.includes(UserRole.LEVEL_2) ? i18n.t("edit-user") : i18n.t("view-user")}
							{isLoading && i18n.t("loading")}
							{isLoading && (
								<LoadingSpinner
									as="span"
									style={{ height: 25, width: 25, position: "absolute", right: 20, marginTop: 2 }}
									animation="border"
									role="status"
									aria-hidden="true"
								/>
							)}
						</Button>
					</div>
				</>
			)}

			{/* Display no results found */}
			{!isLoading && search.trim() && users.length == 0 && (
				<Alert aria-live="polite" variant="danger" className="text-center fst-italic fw-bold">
					{i18n.t("admin-search-no-users-found")}
				</Alert>
			)}
		</Container>
	);
}

export default SearchUsers;
