import React, { memo, useLayoutEffect, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setUser, selectUser } from "features/user/userSlice";
import { setLanguage } from "features/language/languageSlice";
import { logout, update } from "features/user/userAPI";
import { srAnnounce } from "features/global/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAddressCard,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faDownload,
	faQuestionCircle,
	faSignOutAlt,
	faSignature,
	faTools,
	faFlag,
} from "@fortawesome/free-solid-svg-icons";

function Navigation({ sidebarState, setSidebarState }) {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(selectUser);

	const hasAdminRoles =
		user && Array.isArray(user.adminRoles) && user.adminRoles.length > 0;

	const handleNavigation = (event, path) => {
		if (event.key && event.key !== "Enter") return;

		if (user && user.lastProgress && user.lastProgress === "completion") {
			const updatedUser = { ...user, lastProgress: "completed" };
			dispatch(setUser(updatedUser));
		}
		navigate(path);
	};

	const handleLogout = async (event) => {
		if (event.key && event.key !== "Enter") return;

		await logout();

		srAnnounce(i18n.t("sr-annouce-logged-out"));
		setTimeout(() => {
			dispatch(setUser(null));
			navigate("/login");
		}, 300);
	};

	const handleLanguageSelection = async (event) => {
		if (event.key && event.key !== "Enter") return;

		const newLanguage = i18n.language === "en" ? "fr" : "en";
		try {
			await update({ language: newLanguage });
			dispatch(setLanguage(newLanguage));
			srAnnounce(
				newLanguage === "en"
					? i18n.t("sr-annouce-language-change-en")
					: i18n.t("sr-annouce-language-change-fr")
			);
		} catch (error) {
			console.error("Error updating language:", error);
		}
	};

	const handleSidebarState = (event) => {
		if (event.key && event.key !== "Enter") return;
		sidebarState ? setSidebarState(false) : setSidebarState(true);
		srAnnounce(
			sidebarState
				? i18n.t("sr-annouce-sidebar-expanded")
				: i18n.t("sr-annouce-sidebar-collapsed")
		);
	};

	// Annouce navigation function
	const announceNavigation = useCallback(
		(path) => {
			switch (path) {
				case "/program":
					srAnnounce(i18n.t("sr-annouce-navigate-program-page"));
					break;
				case "/profile":
					srAnnounce(i18n.t("sr-annouce-navigate-profile-page"));
					break;
				case "/resources":
					srAnnounce(i18n.t("sr-annouce-navigate-resources-page"));
					break;
				case "/help":
					srAnnounce(i18n.t("sr-annouce-navigate-help-page"));
					break;
				case "/admin":
					srAnnounce(i18n.t("sr-annouce-navigate-admin-page"));
					break;
				// Add more cases for other routes as needed
				default:
					// do nothing
					break;
			}
		},
		[i18n]
	);

	// Announce naviation when window/tab refocused
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				// Add your logic here when tab is refocused
				announceNavigation(window.location.pathname);
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, [announceNavigation]);

	// Functions to execute when page navigate occurs and page has rendered
	useLayoutEffect(() => {
		// scroll pages to top on navigation
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});

		// Announce naviation to screen reader
		announceNavigation(window.location.pathname);
	}, [navigate, i18n, location.pathname, announceNavigation]);

	return (
		<div className="sidebar">
			<div className="sb-item-list">
				{user && user.lastProgress !== "completed" && (
					<div
						className={`sb-item ${
							location.pathname.startsWith("/program")
								? "active"
								: ""
						}`}
						role="navigation"
						aria-label={i18n.t("sidebar-program-page-aria-label")}
						onClick={(event) => handleNavigation(event, "/program")}
						onKeyDown={(event) =>
							handleNavigation(event, "/program")
						}
						tabIndex={0}
					>
						<FontAwesomeIcon
							icon={faSignature}
							className="sb-icon"
						/>
						<span className="sb-text sb-text-two-lines">
							{i18n.t("sidebar-consent-form")}
						</span>
					</div>
				)}
				<div
					className={`sb-item ${
						location.pathname.startsWith("/profile") ? "active" : ""
					}`}
					role="navigation"
					aria-label={i18n.t("sidebar-profile-page-aria-label")}
					onClick={(event) => handleNavigation(event, "/profile")}
					onKeyDown={(event) => handleNavigation(event, "/profile")}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faAddressCard} className="sb-icon" />
					<span className="sb-text">
						{i18n.t("sidebar-profile-page")}
					</span>
				</div>
				<div
					className={`sb-item ${
						location.pathname.startsWith("/resources")
							? "active"
							: ""
					}`}
					role="navigation"
					aria-label={i18n.t("sidebar-resources-page-aria-label")}
					onClick={(event) => handleNavigation(event, "/resources")}
					onKeyDown={(event) => handleNavigation(event, "/resources")}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faDownload} className="sb-icon" />
					<span className="sb-text">
						{i18n.t("sidebar-resources-page")}
					</span>
				</div>
				<div
					className={`sb-item ${
						location.pathname.startsWith("/help") ? "active" : ""
					}`}
					role="navigation"
					aria-label={i18n.t("sidebar-help-page-aria-label")}
					onClick={(event) => handleNavigation(event, "/help")}
					onKeyDown={(event) => handleNavigation(event, "/help")}
					tabIndex={0}
				>
					<FontAwesomeIcon
						icon={faQuestionCircle}
						className="sb-icon"
					/>
					<span className="sb-text">
						{i18n.t("sidebar-help-page")}
					</span>
				</div>
				{hasAdminRoles && (
					<div
						className={`sb-item ${
							location.pathname.startsWith("/admin")
								? "active"
								: ""
						}`}
						role="navigation"
						aria-label={i18n.t("sidebar-admin-page-aria-label")}
						onClick={(event) => handleNavigation(event, "/admin")}
						onKeyDown={(event) => handleNavigation(event, "/admin")}
						tabIndex={0}
					>
						<FontAwesomeIcon icon={faTools} className="sb-icon" />
						<span className="sb-text">
							{i18n.t("sidebar-admin-page")}
						</span>
					</div>
				)}
				<div
					className="sb-item"
					role="navigation"
					aria-label={
						i18n.language === "en"
							? i18n.t("sidebar-language-fr-aria-label")
							: i18n.t("sidebar-language-en-aria-label")
					}
					onClick={(event) => handleLanguageSelection(event)}
					onKeyDown={(event) => handleLanguageSelection(event)}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faFlag} className="sb-icon" />
					<span className="sb-text">
						{i18n.t("sidebar-language")}
					</span>
				</div>
				<div
					className="sb-item"
					role="navigation"
					aria-label={i18n.t("sidebar-logout-aria-label")}
					onClick={(event) => handleLogout(event)}
					onKeyDown={(event) => handleLogout(event)}
					tabIndex={0}
				>
					<FontAwesomeIcon icon={faSignOutAlt} className="sb-icon" />
					<span className="sb-text">{i18n.t("sidebar-logout")}</span>
				</div>
				<div
					role="navigation"
					aria-label={
						sidebarState
							? i18n.t("sidebar-expand-aria-label")
							: i18n.t("sidebar-collapse-aria-label")
					}
					className="btn-toggle-sidebar sb-item"
					onClick={(event) => handleSidebarState(event)}
					onKeyDown={(event) => handleSidebarState(event)}
					tabIndex={0}
				>
					<FontAwesomeIcon
						icon={faAngleDoubleLeft}
						className="sb-icon"
					/>
					<span className="sb-text sb-text-small">
						{i18n.t("sidebar-collapse")}
					</span>
					<FontAwesomeIcon
						icon={faAngleDoubleRight}
						className="sb-icon"
					/>
				</div>
			</div>
		</div>
	);
}

export default memo(Navigation);
