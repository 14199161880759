import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./CreateUser.module.css";

function CreateUser() {
	const { i18n } = useTranslation();
	return <></>;
}

export default CreateUser;
