import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setUser, selectUser } from "features/user/userSlice.js";
import { getUser, update } from "features/user/userAPI.js";
import { selectLanguage } from "features/language/languageSlice.js";
import useAxiosInterceptor from "hooks/useAxiosInterceptor";
import Header from "./Header.js";
import Navigation from "./Navigation";
import Footer from "./Footer.js";
import LoadingSpinner from "components/LoadingSpinner.js";
import { Button } from "react-bootstrap";
import i18n from "app/i18nConfig.js";

function PostLogin({
	children,
	setIsAuthenticated = null,
	maxWidth = false,
	alignCenter = true,
	ariaLabel,
	ariaDescription,
}) {
	const dispatch = useDispatch();
	let user = useSelector(selectUser);
	const language = useSelector(selectLanguage);
	const navigate = useNavigate();
	const location = useLocation();
	const [shouldRender, setShouldRender] = useState(Boolean(user));
	const [skipToMainContentFocused, setSkipToMainContentFocused] =
		useState(false);
	const headerRef = useRef();

	// Sidebar menu state (expanded/visible=true)
	let [sidebarState, setSidebarState] = useState(
		window.innerWidth <= 575.98 ? true : false
	);

	// Navigate to language page if language not set
	useEffect(() => {
		if (!language && location.pathname !== "/language") {
			navigate("/language");
		}
	}, [language, navigate, location]);

	// Add post-login class to body element
	useEffect(() => {
		document.body.classList.add("post-login");
		return () => {
			document.body.classList.remove("post-login");
		};
	}, []);

	useAxiosInterceptor(navigate);

	useEffect(() => {
		const checkUserAuthentication = async () => {
			if (user === null || !user.username) {
				user = await getUser();
			}
			if (user && !user.error) {
				dispatch(setUser(user));
				setShouldRender(true);
				if (setIsAuthenticated) {
					setIsAuthenticated(true);
				}
				if (user.language !== language) {
					update({ language: language });
				}
			} else {
				navigate("/login");
			}
		};
		checkUserAuthentication();
	}, [navigate, dispatch]);

	if (!shouldRender) {
		return <LoadingSpinner isLoading={true} />;
	}

	// Return Post Login component
	return (
		<>
			<Button
				variant="primary"
				className="position-absolute"
				style={{
					zIndex: skipToMainContentFocused ? 10000 : -1,
					marginLeft: "auto",
					marginRight: "auto",
					left: skipToMainContentFocused ? 0 : -10000,
					right: 0,
					top: "10px",
					width: language === "en" ? "200px" : "250px",
					height: "40px",
				}}
				tabIndex={1}
				onFocus={() => {
					setSkipToMainContentFocused(true);
				}}
				onBlur={() => {
					setSkipToMainContentFocused(false);
				}}
				onClick={(e) => {
					headerRef.current.focus();
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						headerRef.current.focus();
					}
				}}
			>
				{i18n.t("skip-to-main-content")}
			</Button>
			<Header
				sidebarState={sidebarState}
				setSidebarState={setSidebarState}
			/>
			<main className={`sb-wrapper ${sidebarState ? "sb-collapse" : ""}`}>
				<Navigation
					sidebarState={sidebarState}
					setSidebarState={setSidebarState}
				/>
				<div
					id="main-content"
					style={{
						position: "absolute",
						top: 0,
						left: `${!sidebarState ? 200 : 60}px`,
						width: `${
							!sidebarState
								? "calc(100% - 200px)"
								: "calc(100% - 60px)"
						}`,
						height: "100%",
						zIndex: -1,
					}}
					ref={headerRef}
					tabIndex={-1}
					aria-label={ariaLabel}
					aria-description={
						": " +
						(ariaDescription ? ariaDescription + " " : "") +
						i18n.t("keyboard-nav-instructions")
					}
				></div>
				<div
					className={alignCenter ? "page mx-auto p-3" : "page p-3"}
					style={{ maxWidth: maxWidth }}
					aria-label={ariaLabel}
					role="main"
				>
					{children}
					<Footer />
				</div>
			</main>
		</>
	);
}

export default PostLogin;
