import React, { useEffect, useLayoutEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { selectLanguage } from "features/language/languageSlice";
import { useTranslation } from "react-i18next";
import { srAnnounce } from "features/global/functions";
import Header from "./Header";
import Footer from "./Footer";

function PreLogin({
	children,
	maxWidth = false,
	alignCenter = true,
	ariaLabel,
}) {
	const language = useSelector(selectLanguage);
	const navigate = useNavigate();
	const location = useLocation();
	const { i18n } = useTranslation();

	// Annouce navigation function
	const announceNavigation = useCallback(
		(path) => {
			switch (path) {
				case "/language":
					srAnnounce(
						"Language selection page. Please select your preferred language " +
							i18n.t("keyboard-nav-instructions", {
								lng: "en",
							})
					);
					break;
				case "/login":
					srAnnounce(i18n.t("sr-annouce-navigate-login-page"));
					break;
				case "/recovery":
					srAnnounce(i18n.t("sr-annouce-navigate-recovery-page"));
					break;
				case "/registration":
					srAnnounce(
						i18n.t("sr-annouce-navigate-registration-page") +
							". " +
							i18n.t("important-note-registration") +
							". " +
							i18n.t("registration-header-question")
					);
					break;
				// Add more cases for other routes as needed
				default:
					// do nothing
					break;
			}
		},
		[i18n]
	);

	// Navigate to language page if language not set
	useEffect(() => {
		if (!language && location.pathname !== "/language") {
			navigate("/language");
		}
	}, [language, navigate, location]);

	// Add pre-login class to body element
	useEffect(() => {
		document.body.classList.add("pre-login");
		return () => {
			document.body.classList.remove("pre-login");
		};
	}, []);

	// Announce naviation when window/tab refocused
	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				// Add your logic here when tab is refocused
				announceNavigation(window.location.pathname);
			}
		};

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener(
				"visibilitychange",
				handleVisibilityChange
			);
		};
	}, [announceNavigation]);

	// Functions to execute when page navigate occurs and page has rendered
	useLayoutEffect(() => {
		// scroll pages to top on navigation
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant",
		});
		// Announce naviation to screen reader
		announceNavigation(window.location.pathname);
	}, [navigate, i18n, location.pathname, announceNavigation]);

	// Return PreLogin component
	return (
		<>
			<Header />
			<main
				className={alignCenter ? "mx-auto ps-2 pe-2" : "ps-2 pe-2"}
				style={{ maxWidth: maxWidth }}
				aria-label={ariaLabel}
			>
				{children}
			</main>
			<Footer />
		</>
	);
}

export default PreLogin;
