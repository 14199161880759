import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, setUser } from "features/user/userSlice";
import PostLogin from "components/PostLogin/PostLogin";
import Completion from "./components/Completion/Completion";
import Signature from "./components/Signature/Signature";
import Player from "./components/Player/Player";
import { update } from "features/user/userAPI";
import styles from "./Program.module.css";

function ProgramPage() {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const { i18n } = useTranslation();
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

	const completeProgress = () => {
		const updatedUser = { ...user, lastProgress: "completed" };
		dispatch(setUser(updatedUser));
	};

	const updateProgress = (progress) => {
		const updatedUser = { ...user, lastProgress: progress };
		dispatch(setUser(updatedUser));
		update({
			last_progress: progress,
		});
	};

	const continueProgress = () => {
		const updatedUser = { ...user, lastProgress: "completion" };
		dispatch(setUser(updatedUser));
		update({
			last_progress: "completed",
		});
	};

	const renderBasedOnUserProgress = () => {
		if (!user) {
			return null;
		}
		if (user.lastProgress === "completion") {
			return <Completion completeProgress={completeProgress} />;
		} else if (user.lastProgress === "completed") {
			navigate("/profile");
		} else if (
			user.lastProgress === "sign" ||
			user.lastProgress === "download"
		) {
			return <Signature continueProgress={continueProgress} />;
		} else {
			return <Player updateProgress={updateProgress} />;
		}
	};

	return (
		<PostLogin
			setIsAuthenticated={setIsUserAuthenticated}
			ariaLabel={
				user?.lastProgress === "completion"
					? i18n.t("consent-signed")
					: user?.lastProgress === "sign" ||
					  user?.lastProgress === "download"
					? i18n.t("sign-the-participant-consent")
					: i18n.t("sidebar-consent-form")
			}
			ariaDescription={
				user?.lastProgress === "completion"
					? i18n.t("congratulations") +
					  " " +
					  i18n.t("congratulations-message")
					: user?.lastProgress === "sign" ||
					  user?.lastProgress === "download"
					? i18n
							.t("sign-the-participant-consent-instructions-1")
							.replace(/<[^>]+>/g, "")
					: ""
			}
		>
			{isUserAuthenticated && renderBasedOnUserProgress()}
		</PostLogin>
	);
}

export default ProgramPage;
