import React from "react";
import { Trans, useTranslation } from "react-i18next";
// import { pdf } from "/resources/en/Abuse-Free-Sport-ItemsMentionedinProgram.pdf"

/* Import components */
import { Row, Col } from "react-bootstrap";
import PostLogin from "../../../components/PostLogin/PostLogin";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Resources.module.css";
import { Link } from "react-router-dom";

function ResourcesPage() {
	const { i18n } = useTranslation();

	return (
		<PostLogin
			maxWidth={"1400px"}
			alignCenter={true}
			ariaLabel={i18n.t("resources-header")}
			ariaDescription={i18n.t("resources-info")}
		>
			<div className="page-header mb-3 mt-2 badge">
				<h1>{i18n.t("resources-header")}</h1>
			</div>
			<Row>
				<Col lg={6} className="mt-2 mb-4">
					<h4 role="heading" aria-level={2} className="fw-normal">
						{i18n.t("about-this-page")}
					</h4>
					<span className="fw-normal text-muted">
						{i18n.t("resources-info")}
					</span>
				</Col>
			</Row>
			<Row
				className={`${styles["resources-container"]} bg-sdrcc justify-content-center border rounded p-4 g-0`}
			>
				<Col md={6}>
					<h4 role="heading" aria-level={3} className="ms-3">
						{i18n.t("pdf-resources")}
					</h4>
					<ul
						className="list-group list-group-flush"
						aria-roledescription={i18n.t("pdf-resources")}
					>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="pdf-resource-1"
								components={[
									<Link
										to="https://sportintegritycommissioner.ca/files/UCCMS-v6.0-20220531.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://commissaireintegritesport.ca/files/CCUMS-v6.0-20220531.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="pdf-resource-2"
								components={[
									<Link
										to="https://sportintegritycommissioner.ca/files/Annotated-UCCMS-EN.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://commissaireintegritesport.ca/files/Annotated-UCCMS-FR.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="pdf-resource-3"
								components={[
									<Link
										to="/resources/en/Abuse-Free-Sport-ItemsMentionedinModule.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="/resources/fr/Abuse-Free-Sport-ItemsMentionedinModule-fr.pdf"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
					</ul>
				</Col>
				<Col md={6}>
					<h4 role="heading" aria-level={3} className="ms-3">
						{i18n.t("link-resources")}
					</h4>
					<ul
						className="list-group list-group-flush"
						aria-roledescription={i18n.t("link-resources")}
					>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="link-resource-1"
								components={[
									<Link
										to="https://intro-uccms.abuse-free-sport.ca/"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://intro-ccums.sport-sans-abus.ca/"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="link-resource-2"
								components={[
									<Link
										to="https://sportintegritycommissioner.ca/consent-form-faq"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://commissaireintegritesport.ca/faq-formulaire-consentement"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="link-resource-3"
								components={[
									<Link
										to="https://sportintegritycommissioner.ca/process/overview"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://commissaireintegritesport.ca/processus/apercu"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
						<li className="list-group-item bg-sdrcc">
							<Trans
								i18nKey="link-resource-4"
								components={[
									<Link
										to="https://abuse-free-sport.ca/helpline"
										target="_blank"
										rel="noopener noreferrer"
									/>,
									<Link
										to="https://sport-sans-abus.ca/ligne-dassistance"
										target="_blank"
										rel="noopener noreferrer"
									/>,
								]}
							/>
						</li>
					</ul>
				</Col>
			</Row>
		</PostLogin>
	);
}

export default ResourcesPage;
