import { Fade, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
function LoadingSpinner({ isLoading }) {
	const { i18n } = useTranslation();
	return (
		<>
			<Fade in={isLoading} timeout={5000} appear={true}>
				<div className="loading-spinner-container">
					<Spinner
						className="loading-spinner"
						role="alert"
						aria-label={i18n.t("loading").replace(/\./g, "")}
					></Spinner>
				</div>
			</Fade>
		</>
	);
}

export default LoadingSpinner;
