import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";

// Custom Input Component to add name attribute
const CustomInput = (props) => {
	return (
		<components.Input
			{...props}
			name={props.selectProps.name}
			aria-autocomplete="off"
		/>
	);
};

function Province({
	provinceSelectedString,
	id = "participantProvince",
	name = "participantProvince",
	label,
	placeholder,
	onChange,
	onBlur,
	isInvalid,
	disabled = false,
	errors,
	formik,
}) {
	const { i18n } = useTranslation();
	const provinces = [
		{ value: "AB", label: i18n.t("alberta") },
		{ value: "BC", label: i18n.t("british-columbia") },
		{ value: "MB", label: i18n.t("manitoba") },
		{ value: "NB", label: i18n.t("new-brunswick") },
		{ value: "NL", label: i18n.t("newfoundland-and-labrador") },
		{ value: "NT", label: i18n.t("northwest-territories") },
		{ value: "NS", label: i18n.t("nova-scotia") },
		{ value: "NU", label: i18n.t("nunavut") },
		{ value: "ON", label: i18n.t("ontario") },
		{ value: "PE", label: i18n.t("prince-edward-island") },
		{ value: "QC", label: i18n.t("quebec") },
		{ value: "SK", label: i18n.t("saskatchewan") },
		{ value: "YT", label: i18n.t("yukon") },
		{ value: "OC", label: i18n.t("outside-of-canada") },
	];

	var provinceSelected = null;
	if (provinceSelectedString !== undefined && provinceSelectedString !== "") {
		provinceSelected = provinces.find(
			(province) => province.value === provinceSelectedString
		);
	}

	// Custom Styles for react-select dropdown and vailation errors
	const customStyles = {
		control: (base, state) => ({
			...base,
			borderColor:
				formik.errors &&
				formik.errors[name] &&
				formik.touched &&
				formik.touched[name]
					? "var(--bs-form-invalid-border-color)"
					: state.isFocused
					? "#80afb0"
					: base.borderColor,
			"&:hover": {
				borderColor:
					formik.errors &&
					formik.errors[name] &&
					formik.touched &&
					formik.touched[name]
						? "var(--bs-form-invalid-border-color)"
						: base.borderColor,
			},
			boxShadow:
				state.isFocused &&
				formik.errors &&
				formik.errors[name] &&
				formik.touched &&
				formik.touched[name]
					? "0 0 0 0.25rem rgba(220, 53, 69, 0.25)" // Bootstrap danger color with 25% opacity
					: state.isFocused
					? "0 0 0 0.25rem rgba(0, 95, 97, 0.25)"
					: base.boxShadow,
		}),
		indicatorSeparator: (base, state) => ({
			...base,
			backgroundColor:
				formik.errors &&
				formik.errors[name] &&
				formik.touched &&
				formik.touched[name]
					? "var(--bs-form-invalid-border-color)"
					: base.backgroundColor,
		}),
		dropdownIndicator: (base, state) => ({
			...base,
			color:
				formik.errors &&
				formik.errors[name] &&
				formik.touched &&
				formik.touched[name]
					? "var(--bs-form-invalid-border-color)"
					: base.color,
			"&:hover": {
				color:
					formik.errors &&
					formik.errors[name] &&
					formik.touched &&
					formik.touched[name]
						? "var(--bs-form-invalid-border-color)"
						: base.color,
			},
		}),
	};

	return (
		<>
			<label htmlFor={name} id={`${id}Label`} className="form-label">
				{label}
			</label>
			<Select
				styles={customStyles}
				className={
					disabled ? "react-select-disabled" : "react-select-enabled"
				}
				value={provinceSelected}
				id={id}
				name={name}
				placeholder={
					<div className="province-placeholder-text">
						{placeholder}
					</div>
				}
				options={provinces}
				onChange={(e) => {
					// revalidate the field (if previously validated)
					if (formik.touched[name] && formik.errors[name]) {
						formik.setFieldTouched(name, true, true);
						formik.setFieldValue(name, e.value, true);
					}
					onChange(e);
				}}
				onBlur={onBlur}
				isInvalid={isInvalid}
				disabled={disabled}
				errors={errors}
				isDisabled={disabled}
				required
				aria-labelledby={`invalid-feedback-province ${id}Label`}
				components={{ Input: CustomInput }}
			/>
			{formik.touched[name] && formik.errors[name] && (
				<div
					id="invalid-feedback-province"
					type="invalid"
					className="invalid-feedback-select"
				>
					{formik.errors[name]}
				</div>
			)}
		</>
	);
}

export default Province;
