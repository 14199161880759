import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import styles from "./Province.module.css";

function Province({ province, setProvince }) {
    const { i18n } = useTranslation();

    const provinceOptions = [
        { value: "", label: i18n.t("select-a-province") },
        { value: "AB", label: i18n.t("alberta") },
        { value: "BC", label: i18n.t("british-columbia") },
        { value: "MB", label: i18n.t("manitoba") },
        { value: "NB", label: i18n.t("new-brunswick") },
        { value: "NL", label: i18n.t("newfoundland-and-labrador") },
        { value: "NT", label: i18n.t("northwest-territories") },
        { value: "NS", label: i18n.t("nova-scotia") },
        { value: "NU", label: i18n.t("nunavut") },
        { value: "ON", label: i18n.t("ontario") },
        { value: "PE", label: i18n.t("prince-edward-island") },
        { value: "QC", label: i18n.t("quebec") },
        { value: "SK", label: i18n.t("saskatchewan") },
        { value: "YT", label: i18n.t("yukon") },
        { value: "OC", label: i18n.t("outside-of-canada") }
    ];

    const handleChange = selectedOption => {
        setProvince(selectedOption.value);
    };

    return (
        <Select
            id="province"
            value={provinceOptions.find(option => option.value === province)}
            onChange={handleChange}
            options={provinceOptions}
            placeholder={i18n.t("select-a-province")}
            className={`${styles.province}`}
            classNamePrefix="select"
        />
    );
}

export default Province;
