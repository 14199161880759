import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/user/userSlice";
import languageReducer from "../features/language/languageSlice";
import sportsReducer from "../features/sports/sportsSlice";
import adminReducer from "../features/admin/adminSlice"

export const store = configureStore({
  reducer: {
    user: userReducer,
    language: languageReducer,
    sports: sportsReducer,
    admin: adminReducer
  },
});
