import { Form, InputGroup } from "react-bootstrap";

function SportMembershipNumber({
	value,
	id = "sportMembershipNumber",
	name = "sportMembershipNumber",
	placeholder,
	label,
	optionalText,
	ariaLabel,
	ariaDescribedBy,
	onChange,
	onBlur,
	isInvalid,
	disabled = false,
	errors,
	invalidFeedbackID = "invalid-feedback-sport-membership-number",
}) {
	return (
		<>
			<label htmlFor={name} className="form-label">
				{label} <span className="text-muted">{optionalText}</span>
			</label>
			<InputGroup>
				<Form.Control
					value={value}
					type="text"
					id={id}
					name={name}
					placeholder={placeholder}
					aria-label={ariaLabel}
					aria-describedby={ariaDescribedBy}
					onChange={onChange}
					onBlur={onBlur}
					isInvalid={isInvalid}
					disabled={disabled}
					aria-labelledby={[id, invalidFeedbackID].join(" ")}
				/>
				<Form.Control.Feedback
					type="invalid"
					id={`${invalidFeedbackID}-${id}`}
				>
					{errors}
				</Form.Control.Feedback>
			</InputGroup>
		</>
	);
}

export default SportMembershipNumber;
