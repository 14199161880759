import React from "react";
import { searchConsentReport } from "features/consent/consentAPI";
import { useSportsOrganizations } from "hooks/useSportsOrganizations";
import { useDataTable } from "hooks/useDataTable";
import { useCheckboxTree } from "hooks/useCheckboxTree";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReportTable from "../ReportTable/ReportTable";
import ReportCheckboxTree from "../ReportCheckboxTree/ReportCheckboxTree";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import styles from "./ConsentFormSignedReport.module.css";
import Province from "../Province/Province";

function ConsentFormSignedReport() {
	const { i18n } = useTranslation();
	const sportOrganizations = useSportsOrganizations();

	const initialFilters = {
		is_signature_typed: "",
		consent_date: "",
		uccms_version: "",
		participant_first_name: "",
		participant_last_name: "",
		participant_email: "",
		province: "",
		guardian_first_name: "",
		guardian_last_name: "",
		guardian_email: "",
		sorting: [{ field: "participant_first_name", order: "asc" }],
		page: 1,
		per_page: 50,
		sport_organization_id: [],
		consent_date_start: "",
		consent_date_end: "",
	};

	const {
		filters,
		setFilters,
		reportData,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
		handleSearch,
		hasFetched,
		isLoading,
		handleExport,
	} = useDataTable(initialFilters, searchConsentReport);

	const { checked, expanded, setExpanded, treeNodes, handleTreeChange, filterText, setFilterText } = useCheckboxTree(
		sportOrganizations,
		setFilters
	);

	const columns = [
		{
			name: <div>{i18n.t("report-type")}</div>,
			exportKey: "report-type",
			selector: (row) => row.report_type,
			sortable: true,
			sortField: "report_type",
			cell: (row) => <div>{i18n.t(row.report_type)}</div>,
		},
		{
			name: <div>{i18n.t("first-name")}</div>,
			exportKey: "first-name",
			selector: (row) => row.participant_first_name,
			sortable: true,
			sortField: "participant_first_name",
			cell: (row) => <div>{row.participant_first_name}</div>,
		},
		{
			name: <div>{i18n.t("last-name")}</div>,
			exportKey: "last-name",
			selector: (row) => row.participant_last_name,
			sortable: true,
			sortField: "participant_last_name",
			cell: (row) => <div>{row.participant_last_name}</div>,
		},
		{
			name: <div>{i18n.t("email")}</div>,
			exportKey: "email",
			selector: (row) => row.participant_email,
			sortable: true,
			sortField: "participant_email",
			cell: (row) => <div>{row.participant_email}</div>,
			minWidth: "250px",
		},
		{
			name: <div>{i18n.t("primary")}</div>,
			exportKey: "primary",
			selector: (row) => row.primary,
			sortable: true,
			sortField: "primary",
			cell: (row) => <div>{row.primary}</div>,
		},
		{
			name: <div>{i18n.t("guardian-first-name")}</div>,
			exportKey: "guardian-first-name",
			selector: (row) => row.guardian_first_name,
			sortable: true,
			sortField: "guardian_first_name",
			cell: (row) => <div>{row.guardian_first_name}</div>,
		},
		{
			name: <div>{i18n.t("guardian-last-name")}</div>,
			exportKey: "guardian-last-name",
			selector: (row) => row.guardian_last_name,
			sortable: true,
			sortField: "guardian_last_name",
			cell: (row) => <div>{row.guardian_last_name}</div>,
		},
		{
			name: <div>{i18n.t("guardian-email")}</div>,
			exportKey: "guardian-email",
			selector: (row) => row.guardian_email,
			sortable: true,
			sortField: "guardian_email",
			cell: (row) => <div>{row.guardian_email}</div>,
			minWidth: "250px",
		},
		{
			name: <div>{i18n.t("username")}</div>,
			exportKey: "username",
			selector: (row) => row.username,
			sortable: true,
			sortField: "username",
			cell: (row) => <div>{row.username}</div>,
		},
		{
			name: <div>{i18n.t("sport-membership-number")}</div>,
			exportKey: "sport-membership-number",
			selector: (row) => row.sport_membership_number,
			sortable: true,
			sortField: "sport_membership_number",
			cell: (row) => <div>{row.sport_membership_number}</div>,
		},
		{
			name: <div>{i18n.t("signed")}</div>,
			exportKey: "signed",
			selector: (row) => row.signed,
			sortable: true,
			sortField: "signed",
			cell: (row) => <div>{row.signed}</div>,
		},
		{
			name: <div>{i18n.t("sign-date")}</div>,
			exportKey: "sign-date",
			selector: (row) => row.sign_date,
			sortable: true,
			sortField: "sign_date",
			cell: (row) => <div>{row.sign_date}</div>,
		},
		{
			name: <div>{i18n.t("date-of-birth")}</div>,
			exportKey: "date-of-birth",
			selector: (row) => row.date_of_birth,
			sortable: true,
			sortField: "date_of_birth",
			cell: (row) => <div>{row.date_of_birth}</div>,
		},
		{
			name: <div>{i18n.t("province")}</div>,
			exportKey: "province",
			selector: (row) => row.province,
			sortable: true,
			sortField: "province",
			cell: (row) => <div>{row.province}</div>,
		},
		{
			name: <div>{i18n.t("sport-organization-name")}</div>,
			exportKey: "sport-organization-name",
			selector: (row) => row.sport_organization_name,
			sortable: true,
			sortField: "sport_organization_name",
			cell: (row) => <div>{row.sport_organization_name}</div>,
		},
		{
			name: <div>{i18n.t("language")}</div>,
			exportKey: "language",
			selector: (row) => row.language,
			sortable: true,
			sortField: "language",
			cell: (row) => <div>{row.language}</div>,
		},
	];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container fluid>
			<h5 className="fw-normal">{i18n.t("consent-form-signed-report")}</h5>
			<div className="fw-normal text-muted mb-4">{i18n.t("admin-consent-form-signed-report-description")}</div>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					handleSearch();
				}}
			>
				<Row className="form-group bg-light border rounded mb-4 p-3">
					<h6 className="fw-normal mb-4">{i18n.t("report-filters")}</h6>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_first_name">{i18n.t("participant-first-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_first_name"
							placeholder={i18n.t("admin-enter-participant-first-name")}
							value={filters.participant_first_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_last_name">{i18n.t("participant-last-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_last_name"
							placeholder={i18n.t("admin-enter-participant-last-name")}
							value={filters.participant_last_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_email">{i18n.t("participant-email-label")}</label>
						<Form.Control
							type="text"
							name="participant_email"
							placeholder={i18n.t("admin-enter-participant-email")}
							value={filters.participant_email}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="province">{i18n.t("province-label")}</label>
						<Province
							province={filters.province}
							setProvince={(value) => setFilters(prev => ({ ...prev, province: value }))}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="guardian_first_name">{i18n.t("guardian-first-name-label")}</label>
						<Form.Control
							type="text"
							name="guardian_first_name"
							placeholder={i18n.t("admin-enter-guardian-first-name")}
							value={filters.guardian_first_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="guardian_last_name">{i18n.t("guardian-last-name-label")}</label>
						<Form.Control
							type="text"
							name="guardian_last_name"
							placeholder={i18n.t("admin-enter-guardian-last-name")}
							value={filters.guardian_last_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="guardian_email">{i18n.t("guardian-email-label")}</label>
						<Form.Control
							type="text"
							name="guardian_email"
							placeholder={i18n.t("admin-enter-guardian-email")}
							value={filters.guardian_email}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="sign_date_start">{i18n.t("sign-date-start")}</label>
						<DatePicker
							selected={filters.consent_date_start}
							onChange={(date) =>
								setFilters((prev) => ({
									...prev,
									consent_date_start: date,
								}))
							}
							dateFormat="yyyy-MM-dd"
							placeholderText={i18n.t("admin-enter-signed-date-start-label")}
							className="form-control"
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							peekNextMonth
							locale={i18n.language === "fr" ? fr : undefined}
							name="sign_date_start"
							id="sign_date_start"
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="sign_date_end">{i18n.t("sign-date-end")}</label>
						<DatePicker
							selected={filters.consent_date_end}
							onChange={(date) =>
								setFilters((prev) => ({
									...prev,
									consent_date_end: date,
								}))
							}
							dateFormat="yyyy-MM-dd"
							placeholderText={i18n.t("admin-enter-signed-date-end-label")}
							className="form-control"
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							peekNextMonth
							locale={i18n.language === "fr" ? fr : undefined}
							name="sign_date_end"
							id="sign_date_end"
						/>
					</Col>
					<ReportCheckboxTree
						nodes={treeNodes}
						checked={checked}
						expanded={expanded}
						onCheck={handleTreeChange}
						onExpand={setExpanded}
						filterText={filterText}
						onFilterChange={setFilterText}
					/>
				</Row>
				<ReportTable
					columns={columns}
					handleSearch={handleSearch}
					handleExport={handleExport}
					reportData={reportData}
					handleSort={handleSort}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
					isLoading={isLoading}
					hasFetched={hasFetched}
				/>
			</Form>
		</Container>
	);
}

export default ConsentFormSignedReport;
