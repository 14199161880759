import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const useAxiosInterceptor = (navigate) => {
	useEffect(() => {
		const axiosInterceptor = axios.interceptors.response.use(
			(response) => response,
			(error) => {
				if (error.response) {
					if (error.response.status === 401 && error.response.data.message === "Unauthenticated.") {
						navigate("/login");
					}

					if (error.response.status === 429 && error.response.data.message.includes("Too many attempts")) {
						toast.error("You have made too many requests in a short period. Please try again later.");
					}
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.response.eject(axiosInterceptor);
		};
	}, [navigate]);
};

export default useAxiosInterceptor;
