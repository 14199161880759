import axios from "axios";
import config from "../../app/config";

export const searchConsentReport = async (filters) => {
  try {
    const url = `${config.BASE_URL}/consents/report`;
    const response = await axios.post(url, filters, {
      withCredentials: true,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      return { error: `Unexpected status code: ${response.status}` };
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return { error: "Forbidden. Authentication failed." };
    }
    if (error.response && error.response.data && error.response.data.message) {
      return { error: error.response.data.message };
    }
    return { error: "An unexpected error occurred." };
  }
};
