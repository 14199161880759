import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { resetPassword } from "features/user/userAPI";
import { setUser } from "features/user/userSlice";
import { validAuthenticationCode } from "app/regex";

/* Import components */
import { Form, Button, Spinner, Row, Col, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLogin from "components/PreLogin/PreLogin";
import Username from "components/Fields/Username";
import AuthenticationCode from "components/Fields/AuthenticationCode";
import Password from "components/Fields/Password";
import { FocusError } from "focus-formik-error";
import { srAnnounce } from "features/global/functions";

function RecoveryPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { i18n } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const authenticationCodeRef = useRef(null);
	const [isAuthenticationCodeAvailable, setisAuthenticationCodeAvailable] =
		useState(false);

	const formikValidateOnBlur = useRef(false);

	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
		initialValues: {
			username: null,
			authenticationCode: null,
			password: null,
			confirmPassword: null,
		},
		validationSchema: Yup.object({
			username: Yup.string().required(i18n.t("username-required")),
			...(isAuthenticationCodeAvailable && {
				authenticationCode: Yup.string()
					.matches(validAuthenticationCode, i18n.t("invalid-code"))
					.required(i18n.t("auth-code-required")),
				password: Yup.string()
					.min(8, i18n.t("invalid-password"))
					.required(i18n.t("new-password-required")),
				confirmPassword: Yup.string()
					.min(8, i18n.t("invalid-password"))
					.required(i18n.t("new-password-required"))
					.oneOf(
						[Yup.ref("password")],
						i18n.t("new-passwords-must-match")
					),
			}),
		}),
		onSubmit: async (values) => {
			setIsLoading(true);

			let response;

			if (isAuthenticationCodeAvailable) {
				response = await resetPassword(
					values.username,
					values.password,
					values.authenticationCode
				);
			} else {
				response = await resetPassword(values.username);
			}

			setTimeout(() => {
				toast.dismiss();
				switch (response.status) {
					case 200: // Request successful
						if (isAuthenticationCodeAvailable) {
							handleSuccessModalShow(true);

							// Set user session, save new password and log in
							dispatch(setUser(response.data));
						} else {
							// Display authentication code and reset password fields
							toast.info(i18n.t("authentication-code-emailed"));
							setisAuthenticationCodeAvailable(true);
							formik.resetForm({
								values: values,
							});
							formikValidateOnBlur.current = false;
						}
						break;
					case 401: // Invalid username (does not exist)
						toast.info(i18n.t("invalid-username"));
						break;
					case 403: // Too many invalid authentication code attempts
						if (
							response.data.message ===
							"This account has been disabled."
						)
							toast.info(i18n.t("invalid-account-disabled"));
						if (
							response.data.message ===
							"Too many invalid attempts. Your verification code has been invalidated. Please start the reset password process again."
						)
							toast.info(
								i18n.t("invalid-authentication-code-attempts")
							);
						break;
					case 422: // Invalid authentication code
						toast.info(i18n.t("invalid-authentication-code"));
						break;
					default: // Generic error message
						toast.info(i18n.t("invalid-generic"));
						break;
				}

				setIsLoading(false);
			}, 200);
		},
	});

	// Handles showing/hiding of form submission success modal
	//const handleSuccessModalClose = () => setShowSuccessModal(false);
	const handleSuccessModalShow = () => setShowSuccessModal(true);

	// Send user to home page after successful login
	const navigateToHome = () => {
		srAnnounce(i18n.t("sr-annouce-logged-in"));
		setTimeout(() => {
			navigate("/");
		}, 300);
	};

	// Create and resend authentication code
	const resendCode = async (e) => {
		e.preventDefault();
		if (isLoading) return;
		setIsLoading(true);

		const response = await resetPassword(formik.values.username);

		setTimeout(() => {
			toast.dismiss();
			switch (response.status) {
				case 200:
					toast.info(i18n.t("authentication-code-emailed"));
					break;
				default: // Generic error message
					toast.info(i18n.t("invalid-generic"));
					break;
			}

			setIsLoading(false);

			// Focus and clear the input element
			setTimeout(() => {
				if (authenticationCodeRef.current) {
					authenticationCodeRef.current.value = ""; // Clear the value
					authenticationCodeRef.current.focus();
				}
			}, 100);
		}, 200);
	};

	return (
		<>
			<Modal
				show={showSuccessModal}
				backdrop="static"
				keyboard={false}
				aria-labelledby="password-reset-success-modal-title"
				aria-describedby="password-reset-success-modal-body"
			>
				<Modal.Header>
					<Modal.Title
						id="password-reset-success-modal-title"
						className="h5"
					>
						{i18n.t("password-reset-success-modal-title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body id="password-reset-success-modal-body">
					<div>{i18n.t("password-reset-success-modal-body")}</div>
					<div className="mt-3">
						{i18n.t("password-reset-success-modal-modal-body")}
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="primary"
						onClick={navigateToHome}
						autoFocus
					>
						{i18n.t("password-reset-success-modal-footer-button")}
					</Button>
				</Modal.Footer>
			</Modal>

			<PreLogin
				maxWidth={"20rem"}
				alignCenter={true}
				ariaLabel={i18n.t("account-recovery")}
			>
				<div className="bg-sdrcc py-3 px-3 border rounded">
					<h1 className="h3 mb-3 fw-normal text-center">
						{i18n.t("account-recovery")}
					</h1>
					<Form
						id="recoveryForm"
						onSubmit={formik.handleSubmit}
						noValidate
					>
						<FocusError formik={formik} />
						<Row className="mt-2 gap-2">
							<Col sm={12}>
								<Username
									placeholder={i18n.t(
										"username-login-placeholder"
									)}
									aria-label={i18n.t(
										"username-login-placeholder"
									)}
									showLabel={false}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.username &&
										formik.errors.username
									}
									disabled={
										isLoading ||
										isAuthenticationCodeAvailable
									}
									errors={formik.errors.username}
								></Username>
							</Col>
							{isAuthenticationCodeAvailable && (
								<Col sm={12}>
									<AuthenticationCode
										ref={authenticationCodeRef}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={
											formik.touched.authenticationCode &&
											formik.errors.authenticationCode
										}
										disabled={isLoading}
										errors={
											formik.errors.authenticationCode
										}
										autoFocus={
											isAuthenticationCodeAvailable
										}
									></AuthenticationCode>
								</Col>
							)}
							{isAuthenticationCodeAvailable && (
								<Col sm={12}>
									<Password
										placeholder={i18n.t(
											"new-password-label"
										)}
										ariaLabel={i18n.t(
											"new-password-placeholder"
										)}
										showLabel={false}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={
											formik.touched.password &&
											formik.errors.password
										}
										disabled={isLoading}
										errors={formik.errors.password}
									></Password>
								</Col>
							)}
							{isAuthenticationCodeAvailable && (
								<Col sm={12}>
									<Password
										id="confirmPassword"
										name="confirmPassword"
										placeholder={i18n.t(
											"confirm-new-password-label"
										)}
										ariaLabel={i18n.t(
											"confirm-new-password-placeholder"
										)}
										showLabel={false}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={
											formik.touched.confirmPassword &&
											formik.errors.confirmPassword
										}
										disabled={isLoading}
										errors={formik.errors.confirmPassword}
										invalidFeedbackID="confirmPassword-invalid-feedback"
									></Password>
								</Col>
							)}
							{isAuthenticationCodeAvailable && (
								<Col sm={12} className="mt-0 text-center">
									<a
										href="/"
										onClick={resendCode}
										style={{ fontSize: "12px" }}
										className={`text-decoration-none ${
											isLoading
												? " pe-none "
												: " pe-auto "
										}`}
										aria-disabled={isLoading}
									>
										<FontAwesomeIcon
											icon={faRotate}
											className="me-2"
										/>
										{i18n.t("resend-code")}
									</a>
								</Col>
							)}
							<Col className="d-grid gap-2 mt-2">
								<Button
									type="submit"
									variant="primary"
									size="lg"
									onClick={() => {
										formikValidateOnBlur.current = true;
									}}
									disabled={isLoading}
								>
									{!isLoading && i18n.t("next")}
									{isLoading && i18n.t("loading")}
									{isLoading && (
										<Spinner
											as="span"
											style={{
												height: 25,
												width: 25,
												position: "absolute",
												right: 20,
												marginTop: 2,
											}}
											animation="border"
											size="sm"
											role="status"
											aria-hidden="true"
										/>
									)}
								</Button>
							</Col>
							<Col sm={12} className="mt-1 text-center">
								<Link
									to="/login"
									relative="path"
									className="text-decoration-none"
									disabled={isLoading}
								>
									{i18n.t("back-to-login-page")}
								</Link>
							</Col>
						</Row>
					</Form>
				</div>
				<ToastContainer position="bottom-right" />
			</PreLogin>
		</>
	);
}

export default RecoveryPage;
