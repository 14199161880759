import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "features/user/userSlice.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import PostLogin from "../PostLogin/PostLogin.js";
import styles from "./AdminLevel.module.css";

function AdminLevel({ children, levels }) {
	const user = useSelector(selectUser);
	const navigate = useNavigate();
	const [hasError, setHasError] = useState(false);
	const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

	useEffect(() => {
		if (isUserAuthenticated) {
			const checkUserAuthentication = async () => {
				if (user === null) {
					navigate("/login");
				} else if (
					!user.adminRoles ||
					!levels.some((level) => user.adminRoles.includes(level))
				) {
					setHasError(true);
				}
			};
			checkUserAuthentication();
		}
	}, [user, navigate, isUserAuthenticated, levels]);

	if (hasError) {
		navigate("/profile");
	}

	return (
		<PostLogin
			maxWidth={"1400px"}
			setIsAuthenticated={setIsUserAuthenticated}
		>
			{children}
		</PostLogin>
	);
}

export default AdminLevel;
