import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { update } from "../../../../features/user/userAPI";
import {
	setSports,
	selectSports,
} from "../../../../features/sports/sportsSlice";
import { getSportOrganizations } from "../../../../features/sports/sportsAPI";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Form, Button, Container } from "react-bootstrap";
import GenericModal from "../../../../components/GenericModal";
import { FocusError } from "focus-formik-error";
import LoadingSpinner from "components/LoadingSpinner";
import { selectProfile } from "features/user/userSlice";
import SportSelection from "components/Fields/SportSelection";

function SportSelectionTab({
	language,
	submitSuccessCount,
	setSubmitSuccessCount,
}) {
	const dispatch = useDispatch();
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	const sportOrganizations = useSelector(selectSports);
	const profile = useSelector(selectProfile);

	const [isLoading, setIsLoading] = useState(false);
	const [showGenericModal, setShowGenericModal] = useState(false);

	const sportCounter = useRef(0);
	const genericModalProps = useRef({
		titleText: "",
		bodyText: "",
		footerText: "",
	});

	// form validation using formik library
	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			userSports: [
				{
					id: "prefix" + sportCounter.current++,
					name: "",
					sport_organization_membership_number: "",
				},
			],
		},
		validationSchema: Yup.object({
			userSports: Yup.array().of(
				Yup.object({
					name: Yup.string().required(
						i18n.t("participant-sport-required")
					),
					sport_organization_membership_number:
						Yup.string().nullable(),
				})
			),
		}),
		onSubmit: async (values) => {
			setIsLoading(true);
			let response;

			let sportOrganizationIdArray = [];
			let sportOrganizationMembershipNumberArray = [];
			const userSportsUpdated = values.userSports;
			for (const index in userSportsUpdated) {
				sportOrganizationIdArray.push(userSportsUpdated[index].id);
				sportOrganizationMembershipNumberArray.push(
					userSportsUpdated[index]
						.sport_organization_membership_number
				);
			}

			try {
				response = await update({
					sport_organization_id: sportOrganizationIdArray,
					membership_number: sportOrganizationMembershipNumberArray,
				});

				setTimeout(() => {
					switch (response) {
						case 200:
							setSubmitSuccessCount(++submitSuccessCount);
							genericModalProps.current = {
								titleText: i18n.t(
									"profile-success-modal-title"
								),
								bodyText: i18n.t("profile-success-modal-body"),
								footerText: i18n.t(
									"profile-success-modal-footer-close"
								),
							};
							handleGenericModalShow();
							break;
						case 401:
							navigate("/login");
							break;
						default: // Generic error message
							throw "Generic error";
					}

					setIsLoading(false);
				}, 200);
			} catch (e) {
				genericModalProps.current = {
					titleText: i18n.t("error"),
					bodyText: i18n.t("error-failed-submission"),
					footerText: i18n.t("close"),
				};
				handleGenericModalShow();
				setIsLoading(false);
			}
		},
	});

	// Handles showing/hiding of form submission success modal
	const handleGenericModalClose = () => setShowGenericModal(false);
	const handleGenericModalShow = () => setShowGenericModal(true);

	// Get sport organizations
	useEffect(() => {
		async function fetchSports() {
			const response = await getSportOrganizations(language);

			if (response && !response.error) {
				dispatch(setSports(response));
			} else {
				toast.error(i18n.t(response.error));
			}
		}
		fetchSports();
	}, [i18n.language, dispatch]);

	useEffect(() => {
		if (profile.sport_organizations.length > 0) {
			formik.resetForm({
				values: {
					userSports: profile.sport_organizations,
				},
			});
		}
	}, [profile]);

	if (profile.sport_organizations)
		return (
			<>
				{isLoading && <LoadingSpinner isLoading={isLoading} />}

				<GenericModal
					show={showGenericModal}
					onHide={handleGenericModalClose}
					backdrop="static"
					keyboard={false}
					titleText={genericModalProps.current.titleText}
					bodyText={genericModalProps.current.bodyText}
					footerText={genericModalProps.current.footerText}
				/>

				<Container fluid>
					<Form
						id="sportSelectionForm"
						onSubmit={formik.handleSubmit}
						noValidate
					>
						<FocusError formik={formik} />
						<h4
							role="heading"
							aria-level={2}
							className="fw-normal mb-2"
						>
							{i18n.t("sport-selection-header")}{" "}
						</h4>
						<span className="fw-normal text-muted">
							{i18n.t("profile-sport-selection-info")}
						</span>

						<SportSelection
							sportCounter={sportCounter}
							isLoading={isLoading}
							formik={formik}
							sportOrganizations={sportOrganizations}
							allowZeroItems={true}
						/>

						<Button
							type="submit"
							variant="primary"
							size="lg"
							className="w-75 mx-auto d-block mt-4 mb-2"
							disabled={isLoading}
						>
							{i18n.t("save-sport-selections-button")}
						</Button>
					</Form>
				</Container>
			</>
		);
}

export default SportSelectionTab;
