import React, { useEffect, useState } from "react";
import { Row, Col, Spinner, Fade } from "react-bootstrap";
import { getStatistics } from "features/user/userAPI";
import { useTranslation } from "react-i18next";

function GlobalStatistics() {
	const [stats, setStats] = useState({
		totalRegisteredUsers: 0,
		totalSignedUsers: 0,
		totalDisabledUsers: 0,
		error: null,
	});
	const [isLoading, setIsLoading] = useState(true);
	const { i18n } = useTranslation();

	useEffect(() => {
		const fetchStats = async () => {
			const response = await getStatistics();
			if (response.error) {
				setStats({ ...stats, error: response.error });
			} else {
				setStats({
					totalRegisteredUsers: response.total_registered_users,
					totalSignedUsers: response.total_signed_users,
					totalDisabledUsers: response.total_disabled_users,
					error: null,
				});
			}
			setIsLoading(false);
		};

		fetchStats();
	}, []);

	if (isLoading) {
		return (
			<Fade in={isLoading} timeout={5000} appear={true}>
				<div className="loading-spinner-container">
					<Spinner
						className="loading-spinner"
						role="status"
						aria-hidden="true"
					>
					</Spinner>
				</div>
			</Fade>
		);
	}

	if (stats.error) {
		return (
			<div>
				{i18n.t("error")}: {stats.error}
			</div>
		);
	}

	return (
		<Row className="admin-stats bg-sdrcc border rounded gx-0 mb-3 p-3 p-sm-1">
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("total-registered-users")}:</div>
				<div>
					<strong>{stats.totalRegisteredUsers}</strong>
				</div>
			</Col>
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("total-signed-users")}:</div>
				<div>
					<strong>{stats.totalSignedUsers}</strong>
				</div>
			</Col>
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("total-disabled-users")}:</div>
				<div>
					<strong>{stats.totalDisabledUsers}</strong>
				</div>
			</Col>
		</Row>
	);
}

export default GlobalStatistics;
