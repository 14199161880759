import PrivacyPolicy from "components/PrivacyPolicy";

function PrivacyPolicyTab() {
	return (
		<>
			<div className="sr-only" tabIndex={0} id="accessible-element">
				<PrivacyPolicy />
			</div>
			<PrivacyPolicy />
		</>
	);
}

export default PrivacyPolicyTab;
