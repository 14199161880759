import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectUser } from "features/user/userSlice";

function Header({ sidebarState, setSidebarState }) {
	const { i18n } = useTranslation();
	const user = useSelector(selectUser);
	const username = user ? user.username : null;

	return (
		<>
			<nav className={`navbar navbar-light bg-light ${sidebarState ? "sb-collapse" : ""}`}>
				<div className="container-fluid">
					<button
						className="navbar-toggler"
						type="button"
						aria-expanded="false"
						aria-label="Toggle navigation"
						onClick={() => (sidebarState ? setSidebarState(false) : setSidebarState(true))}
					>
						<span className="navbar-toggler-icon" />
					</button>
					<div className="navbar-brand p-0">
						<span className="d-none d-sm-inline-block">
							{i18n.t("abuse-free-sport")}
							<span className="d-none d-md-inline-block">: {i18n.t("participant-consent")}</span>
						</span>
						{typeof username !== "undefined" && (
							<div className="navbar-username d-none d-sm-block">
								{i18n.t("logged-in-as")} <strong>{username}</strong>
							</div>
						)}
					</div>
					<img src="/images/logo.svg" alt="" width={84} height={40} className="d-flex align-text-top" />
				</div>
			</nav>
		</>
	);
}

export default Header;
