import { forwardRef } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";

function AuthenticationCode(
	{
		id = "authenticationCode",
		name = "authenticationCode",
		onChange,
		onBlur,
		isInvalid,
		disabled = false,
		autoFocus = false,
		errors,
		invalidFeedbackID = "invalid-feedback-authentication-code",
	},
	ref
) {
	const { i18n } = useTranslation();

	return (
		<>
			<InputGroup>
				<InputGroup.Text>
					<FontAwesomeIcon icon={faKey} />
				</InputGroup.Text>
				<Form.Control
					type="text"
					inputMode="numeric"
					pattern="[0-9]*"
					id={id}
					name={name}
					maxLength={6}
					placeholder={i18n.t("authentication-code")}
					aria-label={i18n.t("authentication-code")}
					title={i18n.t("authentication-code")}
					onChange={onChange}
					onBlur={onBlur}
					isInvalid={isInvalid}
					disabled={disabled}
					autoFocus={autoFocus}
					aria-labelledby={[id, invalidFeedbackID].join(" ")}
					required
					ref={ref}
				/>
				<Form.Control.Feedback type="invalid" id={invalidFeedbackID}>
					{errors}
				</Form.Control.Feedback>
			</InputGroup>
		</>
	);
}

export default forwardRef(AuthenticationCode);
