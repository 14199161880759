import { Form, InputGroup } from "react-bootstrap";

function ParticipantViewingWithGuardian({
	label,
	labelNo,
	labelYes,
	autoComplete,
	onChange,
	onBlur,
	noChecked,
	yesChecked,
	disabled = false,
	formik,
}) {
	return (
		<>
			<label className="fw-bold" id="participantViewingWithGuardianLabel">
				{label}
			</label>
			<InputGroup
				role="radiogroup"
				key="inline-radio"
				className="mt-2 d-block mx-auto"
			>
				<Form.Check
					inline
					label={labelYes}
					id="participantViewingWithGuardianYes"
					name="participantViewingWithGuardianRadio"
					type="radio"
					defaultValue={1}
					autoComplete={autoComplete}
					onChange={onChange}
					onBlur={onBlur}
					checked={yesChecked}
					disabled={disabled}
					aria-labelledby="participantViewingWithGuardianLabel invalid-feedback-participantViewingWithGuardian participantViewingWithGuardianYes "
				/>
				<Form.Check
					inline
					label={labelNo}
					id="participantViewingWithGuardianNo"
					name="participantViewingWithGuardianRadio"
					type="radio"
					defaultValue={0}
					autoComplete={autoComplete}
					onChange={onChange}
					onBlur={onBlur}
					checked={noChecked}
					disabled={disabled}
					aria-labelledby="participantViewingWithGuardianLabel invalid-feedback-participantViewingWithGuardian participantViewingWithGuardianNo"
				/>
			</InputGroup>
			{formik.touched.participantViewingWithGuardianRadio &&
				formik.errors.participantViewingWithGuardianRadio && (
					<div
						id="invalid-feedback-participantViewingWithGuardian"
						type="invalid"
						className="invalid-feedback-select"
					>
						{formik.errors.participantViewingWithGuardianRadio}
					</div>
				)}
		</>
	);
}

export default ParticipantViewingWithGuardian;
