import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import styles from "./PrivacyPolicy.module.css";

function PrivacyPolicy({}) {
	const { i18n } = useTranslation();

	if (i18n.language === "en") {
		return (
			<Container fluid>
				<Row className="g-3 mt-0">
					<Col sm={12} className="my-0">
						<h4 className="fw-normal">User Privacy Policy</h4>
						<div className="fw-normal text-muted">
							{i18n.t("profile-privacy-policy-info")}
						</div>
					</Col>
					<Col sm={12}>
						This Abuse-Free Sport Participant Consent Module
						(“Module”) and the information collected through it are
						subject to the Protection of Privacy Policy of the Sport
						Dispute Resolution Centre of Canada (“SDRCC Policy”) and
						to the Privacy Policy of Headversity (“Headversity User
						Privacy Policy”), as reproduced below. To use this
						Module, you must confirm your acceptance to the SDRCC
						Policy and to the Headversity Policy by clicking the
						“ACCEPT” button below. Accepting these terms is a
						prerequisite but does not in itself constitute consent
						to become a participant of Abuse-Free Sport. You must
						complete the Module in full and provide your express
						consent as indicated in the Module to confirm your
						Participant status with Abuse-Free Sport.
					</Col>
					<Col sm={12}>
						<hr />
						<h5>
							SPORT DISPUTE RESOLUTION CENTRE OF CANADA (SDRCC)
						</h5>
						<h5 className="fw-normal">
							Protection of Privacy Policy
						</h5>
						<strong>Final Version</strong>
						<br />
						Adopted by resolution of the Board of Directors,
						February 24, 2017.
						<br />
						Revised by resolution of the Board of Directors, June
						15, 2022; effective June 20, 2022.
						<br />
					</Col>
					<Col sm={12}>
						<h5>Introduction</h5>
						<p>
							The Centre is committed to respecting the privacy
							and confidentiality of all Personal Information
							gathered in administering its programs. The present
							Policy describes the way in which the Centre will
							adhere to and promote accountable Personal
							Information management practices in a manner that is
							consistent with applicable legislative and
							regulatory privacy requirements.{" "}
						</p>
						<div className="ps-4">
							<strong>A. Purpose</strong>
							<p>
								The Policy establishes how the Centre collects,
								uses, and discloses Personal Information during
								the course of its activities by providing
								principles for the management of Personal
								Information while ensuring optimal balance
								between the need of Personal Information to
								conduct the Centre’s operations, the right to
								privacy of its clients, Employees, Board Members
								and stakeholders and other fundamental rights in
								accordance with the principle of
								proportionality.
							</p>
						</div>
						<div className="ps-4">
							<strong>B. Scope</strong>
							<p>
								The Centre shall at all relevant times and in
								all of its operations comply with applicable
								federal, provincial and territorial privacy
								legislation and their regulations (“applicable
								privacy legislation”), as may be amended from
								time to time. It shall also comply with the
								International Standard for the Protection of
								Privacy and Personal Information, as published
								and amended from time to time by the Word
								Anti-Doping Agency in managing its Doping
								Tribunal and Doping Appeal Tribunal.
							</p>
						</div>
						<div className="ps-4">
							<strong>C. Application</strong>
							<p>
								The Policy applies to all of the Centre’s
								Employees, Board Members, Dispute Resolution
								Professionals, as well as to any Third Party
								engaged by the Centre.
							</p>
						</div>
						<div className="ps-4">
							<strong>D. Amendments</strong>
							<p>
								The Policy may be updated or modified from time
								to time by the Centre’s Board of Directors for
								any reason, including to account for the
								introduction of new technologies, business
								practices, stakeholder needs or applicable laws
								and regulations.
							</p>
						</div>
						<div className="ps-4">
							<strong>E. Disclaimer</strong>
							<p>
								While the procedural rules governing the conduct
								of its dispute resolution services provide for
								confidentiality safeguards, as outlined in the
								Canadian Sport Dispute Resolution Code and/or
								the OSIC Confidentiality Policy (as applicable),
								the Centre cannot be held responsible for the
								conduct of the parties, Authorized
								Representatives or witnesses involved in dispute
								resolution proceedings (or in any Complaint
								procedures of the OSIC) which may cause unlawful
								disclosure of Personal Information that forms
								part of the evidentiary record before the
								Centre.
							</p>
							<p>
								In delivering most of its services virtually,
								the Centre shall take reasonable steps to
								prevent unauthorized access to Personal
								Information in electronic form while stored on
								its own servers, however it cannot be held
								responsible for any breach caused by email or
								Internet service providers of intended email
								recipients.
							</p>
							<p>
								The Centre’s websites, including those of the
								OSIC and of Abuse-Free Sport, provide links to
								Third Party websites. The Centre is not
								responsible for the collection, use or
								disclosure of Personal Information obtained by
								those Third Party websites. It is strongly
								recommended that the Centre’s website visitors
								consult the privacy policies of those Third
								Parties before disclosing any Personal
								Information
							</p>
						</div>
						<div className="ps-4">
							<strong>F. Definitions</strong>
							<p>
								<i>Authorized Representative:</i> any lawyer
								appearing before the Tribunal on behalf of the
								Individual, any other person so designated in
								writing by the Individual, or, in the case of a
								minor Individual who is not emancipated, any
								parent, legal guardian or authorized
								representative;
							</p>
							<p>
								<i>Board Member:</i> any member of the Board of
								Directors of the Centre, as may be appointed
								from time to time by Canada’s minister
								responsible for sport;
							</p>
							<p>
								<i>Case Management Portal:</i> the proprietary
								online platform used by the Centre’s Employees
								in the management of Tribunal proceedings to
								share information and documents with Dispute
								Resolution Professionals, Parties and their
								Authorized Representatives;
							</p>
							<p>
								<i>Complaint:</i> a submitted complaint intake
								form, the receipt by the OSIC of information
								expressly deemed by the OSIC to constitute a
								complaint, or a complaint initiated by the OSIC,
								in each case regarding an alleged violation of
								the UCCMS;
							</p>
							<p>
								<i>Complaint Management Portal:</i> the online
								platform used by the Centre’s Employees and OSIC
								Professionals in the management of Complaints to
								share information and documents with the
								Director of Sanctions and Outcomes, Dispute
								Resolution Professionals, Parties and their
								Authorized Representatives;
							</p>
							<p>
								<i>Dispute Resolution Professional:</i> any
								member of the Centre’s roster of mediators and
								arbitrators acting upon appointment by the
								Centre or by consent of the Parties;
							</p>
							<p>
								<i>Employee</i>: any person hired by the Centre
								to execute tasks in the conduct of its
								operations in exchange for monetary compensation
								or co-op education credits. For the sake of
								clarity, a Dispute Resolution Professional is
								not an Employee;
							</p>
							<p>
								<i>Express Consent</i>: consent given
								electronically, in writing or orally when
								necessary by an Individual, which must always be
								unequivocal and not require inference on the
								part of the Centre;
							</p>
							<p>
								<i>Implied Consent</i>: consent that can be
								reasonably inferred from an Individual’s actions
								or inaction;
							</p>
							<p>
								<i>Individual</i>: a person whose Personal
								Information is collected, used, disclosed or
								retained by the Centre;
							</p>
							<p>
								<i>OSIC</i>: Office of the Sport Integrity
								Commissioner, an independent function within the
								Centre, responsible to administer and enforce
								the UCCMS;
							</p>
							<p>
								<i>OSIC Professional</i>: any member of the
								Centre’s roster of OSIC independent
								investigators acting upon appointment by the
								Centre;
							</p>
							<p>
								<i>Party</i>: user of the Centre’s dispute
								resolution services or OSIC services including,
								but not limited to, a claimant, a respondent, an
								affected party or an intervenor as defined in
								the Canadian Sport Dispute Resolution Code or
								other OSIC policies and procedures;
							</p>
							<p>
								<i>Personal Information</i>: information about
								an identifiable Individual, excluding
								information otherwise publicly available;
							</p>
							<p>
								<i>Privacy Officer</i>: the Centre’s Director of
								Operations or any other Board Member or Employee
								that the Board of Directors may nominate from
								time to time;
							</p>
							<p>
								<i>Request</i>: right of the Individual to
								access and ask for the correction of his/her
								Personal Information as well as a demand, by any
								person, to access information other than his/her
								Personal Information (access Request);
							</p>
							<p>
								<i>Sensitive Personal Information</i>: more
								delicate Personal Information such as, but not
								limited to Personal Information relating to an
								Individual’s racial or ethnic origin, commission
								of offences (criminal or otherwise), health and
								genetic information;
							</p>
							<p>
								<i>Service User</i>: any person providing
								Personal Information to the Centre for the
								purpose of receiving services or taking part in
								a Centre’s program other than the Tribunal
								services, such as but not limited to,
								registrants to events held by the Centre,
								applicants to the roster of Dispute Resolution
								Professionals, applicants to the Board of
								Directors and applicants for employment and
								internship positions.
							</p>
							<p>
								<i>Third Party</i>: any person, institution,
								corporation or other entity that has entered
								into contractual agreements with the Centre in
								the conduct of its operations to provide
								services which involve access or potential
								access to Personal Information including, but
								not limited to, investigators, Canadian Sport
								Helpline operators, consultants, and other
								suppliers or service providers. For the sake of
								clarity, the definition of a Third Party
								excludes Dispute Resolution Professionals;
							</p>
							<p>
								<i>Tribunal</i>: Dispute Resolution Secretariat
								of the Centre;
							</p>
							<p>
								<i>UCCMS</i>: Universal Code of Conduct to
								Prevent and Address Maltreatment in Sport
								(UCCMS), available{" "}
								<Link
									to="https://sportintegritycommissioner.ca/uccms"
									target="_blank"
									rel="noopener noreferrer"
									tabIndex={-1}
								>
									here.
								</Link>
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							1 - Accountability and Openness
						</h5>
						<div className="ps-4">
							<p>
								<strong>1.1 Privacy Officer</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The role of the Privacy Officer is to ensure
								that the Policy complies with applicable privacy
								laws and regulations, to monitor the Centre’s
								compliance with the Policy, and to report and
								respond to privacy complaints and breaches.
							</p>
							<p>
								b) The Privacy Officer oversees the
								implementation of the Centre’s other policies
								and procedures which also contribute to the
								protection of Personal Information.
							</p>
							<p>
								c) The Privacy Officer may be contacted by email
								at{" "}
								<Link
									to="mailto:priv@crsdc-sdrcc.ca"
									tabIndex={-1}
								>
									priv@crsdc-sdrcc.ca
								</Link>{" "}
								or by calling the Centre’s main office telephone
								number or in person or by mail at{" "}
							</p>
						</div>
					</Col>
					<Col sm={12} className="mt-0 text-center">
						<small>
							6400, Auteuil, Suite 245
							<br />
							Brossard (Québec)
							<br />
							J4Z 3P5
							<br />
						</small>
					</Col>
					<Col sm={12}>
						<div className="ps-5">
							<p>
								d) In the absence or incapacity of the Privacy
								Officer, or at any time deemed necessary, the
								Centre’s Board of Directors shall designate a
								Board Member or Employee to replace the Privacy
								Officer.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<div className="ps-4">
							<p>
								<strong>1.2 Publication of the Policy</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Policy shall be made publicly available
								through the Centre’s website(s) and upon
								request.
							</p>
							<p>
								b) The Centre shall make accessible, through the
								Policy or otherwise:
							</p>
							<p className="ps-4">
								i. A description of the type and purpose of
								Personal Information gathered;
							</p>
							<p className="ps-4">
								ii. The methods for Individuals to gain access
								to their Personal Information on the Centre’s
								records; and
							</p>
							<p className="ps-4">
								iii. Where Personal Information is shared with
								Third Parties, a justification for giving access
								to those Third Parties.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>1.3 Amendments</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Amendments to the Policy shall be made
								publicly available, after their adoption but at
								least one (1) month prior to becoming effective,
								through the Centre’s website or upon request. It
								is recommended to Individuals sharing Personal
								Information with the Centre to check the Policy
								regularly for changes and updates.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>1.4 Discrepancies</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) In the event that there are any discrepancies
								between applicable privacy legislation and the
								Policy, the applicable privacy legislation shall
								take precedence.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							2 - Identifying Purpose and Type of Information
							Collected
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									2.1 Types of Information Collected
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Centre collects Personal Information that
								is necessary for its operations and/or required
								by law.
							</p>
							<p>
								b) The Centre’s operations minimally require
								last names, given names, and contact information
								(email address, telephone number and mailing
								address) of Service Users, Parties and, if
								applicable, their Authorized Representatives.
							</p>
							<p>
								c) The Centre is also seized by the Parties or
								their Authorized Representatives of certain
								Personal Information and/or Sensitive Personal
								Information through the evidentiary record and
								submissions filed in the course of dispute
								resolution proceedings, through the Complaint
								management process or administration of the
								UCCMS, such as health information, criminal
								offences, last name, given name, contact
								information, and information relating to
								complaints against individuals and related
								sanctions. Personal Information provided by the
								Parties or their Authorized Representatives,
								including without limitations, financial
								information, health information, last name,
								given name and contact information, information
								regarding Complaints or other information about
								procedures before the Centre, may also be
								collected in order to determine the
								admissibility to certain programs offered by the
								Centre (e.g. legal aid, mental health referrals)
								and in order to offer such programs to the
								eligible Parties.
							</p>
							<p>
								d) The Centre collects Personal Information from
								its Employees, Board Members and Dispute
								Resolution Professionals which includes, but is
								not limited to financial information, last
								names, given names and contact information.
							</p>
							<p>
								e) The Centre’s Case Management Portal and the
								OSIC Complaint Management Portal collect cookies
								on user accounts such as IP addresses, sections
								of portal visited, and information downloaded.
							</p>
							<p>
								f) The Centre’s websites also collect
								non-identifiable information such as cookies
								including, but not limited to, IP addresses,
								sections of website visited, and information
								downloaded.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>2.2 Purpose</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The purposes for which Personal Information
								is collected by the Centre are enumerated in
								Appendix A.
							</p>
							<p>
								b) The Centre will inform the Individual of the
								applicable purposes at or before the time that
								the Personal Information is collected and used.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							3 – Obtaining Valid, Informed Consent
						</h5>
						<div className="ps-4">
							<p>
								<strong>3.1 When to Seek Consent</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Except when it is reasonable to think that
								implicit consent was given, in case of emergency
								or when not required by law, the Centre must
								obtain valid consent from the Individual, or
								Authorized Representative, at or before the time
								of collection for the use and disclosure of
								Personal Information.
							</p>
							<p>
								b) Except when permitted by law, if the Personal
								Information collected is to be used for purposes
								not originally agreed upon by the Individual,
								the Centre will notify and obtain consent for
								any new purposes for which it intends to use
								such information.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>3.2 Express and Implied Consent</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Consent can either be Express Consent or
								Implied Consent and may be provided by the
								Individual or by an Authorized Representative.
								In determining the form of the consent required,
								the Centre will take into account the
								sensitivity of the Personal Information and the
								reasonable expectations of the Individual.
								Notwithstanding the above, except when permitted
								by law, the Centre shall seek Express Consent
								when the Personal Information is likely to be
								considered sensitive.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>3.3 Withdrawal of Consent</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) An Individual may withdraw his or her consent
								at any time, on reasonable notice, subject to
								legal or contractual restrictions. The Centre
								shall inform the Individual of the implications
								of such a withdrawal, which may result in
								forfeiture of certain services or information.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							4 – Limiting Collection and Use
						</h5>
						<div className="ps-4">
							<p>
								<strong>4.1 Collection</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Centre shall only collect Personal
								Information by fair and lawful means necessary
								for the identified purposes.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>4.2 Use and Disclosure</strong>
							</p>
						</div>
						<div className="ps-5">
							<p className="ps-3">
								<strong>4.2.1 General Principle</strong>
							</p>
							<p>
								a) The Centre shall only use and disclose
								Personal Information for the identified purposes
								and such purposes shall be limited solely to
								fulfilling the necessary functions of the
								Centre.
							</p>
						</div>
						<div className="ps-5">
							<p className="ps-3">
								<strong>
									4.2.2 Applications of the Principle
								</strong>
							</p>
							<p>
								a) Personal Information described in section 2.1
								b) may be shared with other Parties involved in
								the same dispute or Complaint and with their
								Authorized Representatives during the
								proceedings.
							</p>
							<p>
								b) Personal Information described in section 2.1
								c) may, at the sole discretion of the OSIC in
								accordance with OSIC policies and procedures
								and/or by any OSIC Professional in the course of
								an investigation, or at the sole discretion of
								the Dispute Resolution Professional appointed to
								hear a case, be disclosed in the investigation
								report and/or arbitral awards when necessary to
								provide reasoning for the decisions rendered.
							</p>
							<p>
								c) Any Personal Information described in section
								2.1 c) that is disclosed in a decision of the
								Doping Tribunal and that allows for the
								identification of an Individual against whom a
								doping violation has been asserted shall be
								redacted after 10 years of the decision date
							</p>
							<p>
								d) Personal Information described in section 2.1
								d) shall be used strictly for purposes of human
								resources management, governance and Tribunal
								activities of the Centre respectively.
							</p>
							<p>
								e) Where possible and if it can serve the same
								purpose, the Personal Information described in
								sections 2.1 e) and 2.1 f) will be used in
								aggregate forms.
							</p>
							<p>
								f) Access to, use and disclosure of Personal
								Information will be limited to the Centre’s
								Employees, Board Members, Dispute Resolution
								Professionals, OSIC Professionals and Third
								Parties in accordance with the reasonable limits
								required to fulfill their duties and
								responsibilities with the Centre.
							</p>
							<p>
								g) Personal information that is subject to a
								request shall be retained for as long as is
								necessary to allow the Individual to exhaust any
								recourse that he/she may have;
							</p>
							<p>
								h) Any Personal Information collected by the
								Centre shall be managed in accordance with
								Section 6 herein, <i>Safeguards and Security</i>
								.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<div className="ps-4">
							<p>
								<strong>4.3 Retention</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Personal Information shall be retained only
								as long as reasonably necessary and still
								relevant for the purposes for which it was
								collected.
							</p>
							<p>
								b) As a general rule, retaining Sensitive
								Personal Information requires stronger or more
								compelling reasons and justifications than
								retaining other types of Personal Information.
							</p>
							<p>
								c) Procedures for the retention, backups and
								archiving of Personal Information shall be in
								accordance with the Safeguard and Security
								standards stated in Section 6 herein and
								Appendix A of the{" "}
								<i>
									International Standard for the Protection of
									Privacy and Personal Information
								</i>
								, as published and amended from time to time by
								the Word Anti-Doping Agency.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">5 – Accuracy of Information</h5>
						<div className="ps-4">
							<p>
								a) The Centre will take reasonable steps to
								ensure that Personal Information is accurate,
								complete, and as up-to-date as is necessary for
								the identified purposes.
							</p>
							<p>
								b) The Centre requires that each Individual be
								responsible to provide accurate Personal
								Information and to ensure it remains current by
								communicating any changes promptly to the
								Centre.
							</p>
							<p>
								c) The Centre is not responsible for any loss of
								services or benefits resulting from Individuals
								who fail to advise the Centre in writing of any
								changes to their Personal Information on file.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">6 – Safeguards and Security</h5>
						<div className="ps-4">
							<p>
								<strong>6.1 General Provisions</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Centre has implemented safeguards to
								protect against loss, theft, unauthorized
								access, disclosure, copying, use or modification
								of Personal Information. These security
								safeguards vary according to the sensitivity of
								Personal Information; the more sensitive the
								information the higher the level of protection.
								The Centre commits to maintain those measures or
								equivalent ones as they may be modified from
								time to time.
							</p>
							<p>
								b) The security methods employed by the Centre
								include, but are not limited to:
							</p>
							<p className="ps-4">
								i. Physical measures including, but not limited
								to, securing documents containing Personal
								Information in lockable filing cabinets and
								safes, controlling access to offices and filing
								cabinets to Employees only and shredding of
								paper files no longer in use;
							</p>
							<p className="ps-4">
								ii. Administrative measures including, but not
								limited to, appointing a Privacy Officer,
								limiting access to data on a need-to-know basis,
								providing confidentiality training and requiring
								the signature of confidentiality agreements
								before providing access to Personal Information;
								and
							</p>
							<p className="ps-4">
								iii. Technological measures including, but not
								limited to, managing access to the Centre’s
								office server, securing data transmission
								protocols, applying reasonable standards of data
								security to the Case Management Portal,
								permanently deleting emails after they have been
								downloaded as well as a disaster recovery plan.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.2 Specific Areas of Safeguarding
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Personal Information stored on the Centre’s
								server and computers will be safeguarded as
								follows:
							</p>
							<p className="ps-4">
								i. Access to specific areas of the server is
								restricted through individual password-protected
								user accounts, based on personal profile of each
								Employee’s responsibilities and needs; and
							</p>
							<p className="ps-4">
								ii. Backups are stored on password-protected
								devices and kept in a safe.
							</p>
							<p>
								b) Any necessary transfer of Personal
								Information held by the Centre shall be
								conducted as follows:
							</p>
							<p className="ps-4">
								i. All servers used by the Centre, including the
								server hosting the Case Management Portal and
								OSIC Complaint Management Portal, are physically
								located in Canada, including any server
								redundancy equipment;
							</p>
							<p className="ps-4">
								ii. The Centre does not transfer Personal
								Information through cloud technologies and
								limits to the extent possible the transfer of
								Personal Information by electronic mail;
							</p>
							<p className="ps-4">
								iii. Email messages containing Personal
								Information shall be marked as being
								confidential and sent only to authorized
								recipients;
							</p>
							<p className="ps-4">
								iv. Where temporary copies, on any devices, are
								required for Employees to work remotely,
								duplicates are permanently deleted as soon as no
								longer needed.
							</p>
							<p>
								c) Personal Information stored on the Case
								Management Portal and OSIC Complaint Management
								Portal shall be safeguarded as follows:
							</p>
							<p className="ps-4">
								i. Access to the Case Management Portal and OSIC
								Complaint Management Portal administration area
								is granted exclusively to Employees and to Third
								Parties responsible for technical support
								through password-protected user accounts;
							</p>
							<p className="ps-4">
								ii. Access to case-specific areas of the Case
								Management Portal and OSIC Complaint Management
								Portal is restricted to Employees, Dispute
								Resolution Professionals, OSIC Professionals,
								Parties and their Authorized Representatives who
								are concerned by this specific case, and Third
								Parties through password-protected user
								accounts;
							</p>
							<p className="ps-4">
								iii. Personalization of Case Management Portal
								and OSIC Complaint Management Portal passwords
								is governed by rules imposing robust password
								strength;
							</p>
							<p className="ps-4">
								iv. All files uploaded to the Case Management
								Portal and OSIC Complaint Management Portal are
								encrypted; and
							</p>
							<p className="ps-4">
								v. The entire case files on the Case Management
								Portal are permanently deleted on the 21st day
								following the case being declared closed by the
								Centre. Where a case is subject to further
								appeals and at the request of Parties and their
								Authorized Representatives, the above period may
								be extended until such time as the subsequent
								proceedings are terminated. For clarity, this
								timeline shall not apply to case file and
								information contained on the OSIC Complaint
								Management Portal, which information shall be
								maintained indefinitely.
							</p>
							<p>
								d) The Centre’s Chief Executive Officer is the
								only person with access to Employee personnel
								files.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.3 Privacy Education, Training and
									Agreements
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) All Employees, Board Members, Dispute
								Resolution Professionals and OSIC Professionals
								shall take part in an orientation session, which
								includes training on the Policy as well as clear
								directives related to the collection, use and
								disclosure of Personal Information, in the
								execution of their respective duties with the
								Centre.
							</p>
							<p>
								b) All Employees, Board Members, Dispute
								Resolution Professionals and OSIC Professionals
								shall, prior to the commencement of their
								employment, term or mandate with the Centre,
								execute some form of agreement which binds them
								to the Centre’s Policy as follows:
							</p>
							<p className="ps-4">
								i. All Employees shall sign an employment
								contract containing a confidentiality clause and
								are required to commit in writing, annually, to
								abide by the relevant provisions of the Centre’s
								Workplace/Employment Policy and Procedures
								Handbook;
							</p>
							<p className="ps-4">
								ii. All Board Members upon appointment shall
								sign a confidentiality agreement which
								stipulates that they agree to respect the
								confidentiality of information that they may be
								privy to during the course of their duties, in
								accordance with the confidentiality policies of
								the Centre; and
							</p>
							<p className="ps-4">
								iii. All Dispute Resolution Professionals and
								OSIC Professionals shall, upon appointment by
								the Centre, sign a memorandum of agreement that
								refers to the Policy and the Centre’s Mediators
								and Arbitrators Code of Conduct or other
								relevant OSIC policies and procedures (as
								applicable)
							</p>
							<p>
								c) Where Personal Information is being disclosed
								to or used by a Third Party, the Centre shall
								ensure that such Third Parties adhere to the
								safeguard and security standards of the Centre
								through contractual means.
							</p>
							<p>
								d) Parties and their Authorized Representatives
								are bound by the relevant provisions of the
								Canadian Sport Dispute Resolution Code which
								stipulate that they and any other persons
								attending the proceedings on their behalf shall
								not disclose any information or document
								obtained through their participation in the
								resolution process, unless required by law.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.4 Destruction, Deletion or
									De-Identification{" "}
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Personal information will be destroyed,
								deleted, permanently anonymized or, in the case
								of paper files, shredded, once it is no longer
								relevant or necessary for the purposes of the
								collection.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							7 – Individual Access and Correction
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									7.1 Access and Corrections to Information
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								<small>Subject to section 7.4,</small>
							</p>
							<p>
								a) It is the right of any Individual to access
								his or her Personal Information upon Request;
							</p>
							<p>
								b) The Centre shall also provide, upon Request,
								an account of the use of the Individual’s
								Personal Information, including disclosure to
								Third Parties;
							</p>
							<p>
								c) The Individual is entitled to Request the
								correction of any demonstrable errors; and
							</p>
							<p>
								d) Where necessary for the conduct of its
								operations or the maintenance of services and
								benefits to the Individual, the Centre shall
								transmit the amended Personal Information to
								Third Parties with authorized access.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>7.2 Identification</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Only Requests made in writing by Individuals
								having properly identified themselves or by
								Authorized Representatives having the proper
								authority on behalf of such Individual to obtain
								the requested Personal Information may be
								fulfilled.
							</p>
							<p>
								b) Proper identification of the requestor shall
								include two government issued identification
								document (passport, driver’s license, birth
								certificate, etc.), at least one of which must
								bear a photo of the requestor.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>7.3 Time to Respond to Request</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Centre shall respond no later than 30
								days from the date of receipt of a written
								Request by an Individual.
							</p>
							<p>
								b) Under reasonable circumstances including, but
								not limited to, Requests of voluminous
								information, impracticable Requests, or Requests
								requiring a conversion of information, the
								Centre may require an extension of time beyond
								the 30-day time limit. In such cases, the
								requestor will be notified in writing before the
								expiration of the 30 days, of the reasons for
								extending the time limit and of their right to
								make a complaint to the Privacy Officer in
								respect of the extension.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>7.4 Refusing a Request</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) The Centre may refuse a correction Request,
								with reasons, under certain limited instances
								including, but not limited to, where the
								Individual fails to provide sufficient proof
								that such information is incorrect. When it is
								impossible to amend a document, the correction
								shall be made by a note to file.
							</p>
							<p>
								b) Despite a general right to access Personal
								Information upon Request, the Centre may refuse
								an access Request if the information is
								protected by solicitor-client privilege,
								litigation privilege or settlement privilege,
								or, in civil law, by the professional secrecy of
								lawyers and notaries;
							</p>
							<p>
								c) The Centre may err on the side of caution and
								deny any such access Request in certain other
								situations such as, but not limited to:
							</p>
							<p className="ps-4">
								i. Fulfilling the access Request may cause harm
								to the Individual or to another Individual;
							</p>
							<p className="ps-4">
								ii. Fulfilling the access Request may compromise
								the administration, investigation or preparation
								for adjudication of a Complaint;
							</p>
							<p className="ps-4">
								iii. Fulfilling the access Request would reveal
								Personal Information of another Individual that
								is not severable without his or her consent, and
								is not needed to avoid harm to that other
								individual; or
							</p>
							<p className="ps-4">
								iv. Any reasonable doubt exists in the proper
								identification or authority of the requestor,
								whether the Individual or the person alleged to
								have authority to act on behalf of the
								Individual.
							</p>
							<p>
								d) The Centre may, where reasonable and
								possible, allow access to Personal Information
								in a redacted form in order to avoid harm.
							</p>
							<p>
								e) The Centre will be deemed to have refused an
								access Request if it fails to respond within the
								30-day time limit.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">8 – Challenging Compliance</h5>
						<div className="ps-4">
							<p>
								<strong>
									8.1 Receipt of Inquiries and Complaints
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) All privacy inquiries, concerns and
								complaints are to be forwarded to the Privacy
								Officer upon receipt.
							</p>
							<p>
								b) The Privacy Officer shall encourage, but not
								require, Individuals to submit their inquiries,
								concerns or complaints in writing.
							</p>
							<p>
								c) An Individual may also file a written
								complaint with a Privacy Officer under
								applicable privacy legislation.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									8.2 Handling of Inquiries and Complaints
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) When an Individual makes an inquiry or lodges
								a concern or a complaint regarding a possible
								confidentiality breach by:
							</p>
							<p className="ps-4">
								i. a Party, the Privacy Officer shall refer the
								Individual to the relevant provisions of the{" "}
								<i>Canadian Sport Dispute Resolution Code</i> on
								breaches of such code or to the relevant OSIC
								policies and procedures, as applicable;
							</p>
							<p className="ps-4">
								ii. a Dispute Resolution Professional or an OSIC
								Professional relating to a proceeding governed
								by the{" "}
								<i>Canadian Sport Dispute Resolution Code</i> or
								otherwise pursuant to an OSIC policy or
								procedure, the Privacy Officer shall refer the
								Individual to the Centre’s{" "}
								<i>Complaints Process Policy</i>; and
							</p>
							<p className="ps-4">
								iii. an Employee, Board Member or Third Party,
								the Privacy Officer shall refer the Individual
								to the section 8.2 of the Policy and comply with
								this section.
							</p>
							<p>
								b) Unless the Privacy Officer determines that
								there is sufficient cause to handle the inquiry,
								concern or complaint in another manner, the
								Centre will investigate all concerns and
								complaints.
							</p>
							<p>
								c) The Privacy Officer will complete an initial
								review of any concerns or complaints within a
								reasonable period of time. In any event, the
								Privacy Officer will inform the Individual
								having lodged the concern or complaint of the
								progress of the review with an estimated date of
								completion.
							</p>
							<p>
								d) If a concern or complaint is not resolved to
								the satisfaction of the Individual, the Centre
								will:
							</p>
							<p className="ps-4">
								i. record the substance of the unresolved
								concern or complaint with the relevant records
								about the Individual; and
							</p>
							<p className="ps-4">
								ii. where appropriate, transmit the existence of
								the unresolved concern or complaint to any Third
								Parties having access to the Personal
								Information in question.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>8.3 Privacy Breaches</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Privacy breaches include, but are not limited
								to, any inadvertent or intentional theft or loss
								of Personal Information, any unauthorized
								collection, use or disclosure of Personal
								Information, any unauthorized modification or
								destruction of Personal Information, or any
								non-compliance with this Policy.
							</p>
							<p>
								b) In the event of any actual or suspected
								incidents of privacy breaches, the Privacy
								Officer shall report to either the Chairperson
								of the Board of Directors or the Chief Executive
								Officer of the Centre.
							</p>
							<p>
								c) The Privacy Officer is obligated to ensure,
								minimally:
							</p>
							<p className="ps-4">
								i. Containment from further harm and
								unauthorized theft, loss, use, or disclosure;
							</p>
							<p className="ps-4">
								ii. Prompt notification of all affected, or
								possibly affected, Individuals;
							</p>
							<p className="ps-4">
								iii. Investigation of the breach, including a
								review of relevant systems and policies, and
								practices and procedures; and
							</p>
							<p className="ps-4">
								iv. Recommendations to, at his or her
								discretion, either the Chairperson of the Board
								of Directors or the Chief Executive Officer of
								the Centre for remediation, rectification and,
								where appropriate, disciplinary measures.
							</p>
							<p>
								d) The Privacy Officer shall keep a record of
								all incidents and notifications with supporting
								reasons and inform the Individual of the outcome
								of the investigation regarding his or her
								concern or complaint.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>8.4 Independent Audit</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) As deemed necessary, the Centre’s Board of
								Directors, may initiate an independent audit of
								its own compliance with the Policy.
							</p>
						</div>
					</Col>
					<Col sm={12} className="mt-4 text-center">
						<h5 className="mb-3">Appendix A</h5>
					</Col>
					<Col sm={12}>
						<p>
							The Centre collects Personal Information in respect
							of Individuals for the purposes set out below:
						</p>
						<h6>From and about all Individuals:</h6>
						<ul>
							<li>
								to organize events involving their
								participation;
							</li>
							<li>
								to refund admissible expenses incurred by
								Employees, Board Members or Third Parties, in
								the form of invoices, receipts and travel
								information;
							</li>
							<li>
								to respond to the Individuals’ complaints or
								inquiries;{" "}
							</li>
							<li>
								to receive, process, administer, investigate,
								mediate and adjudicate Complaints related to the
								matter of the UCCMS, to publish decisions
								rendered pursuant to the UCCMS, and to maintain
								a registry of relevant information regarding
								Complaints and adjudication under the UCCMS;
							</li>
							<li>
								to assist the Individuals with administrative or
								technical support in the use of the Centre’s
								systems and services;{" "}
							</li>
							<li>
								to collect the Individuals’ opinions and
								comments in regard to the Centre’s operations;
							</li>
							<li>
								to administer the physical security of the
								Centre’s office, through the collection of
								Personal Information of visitors and clients in
								the form of images captured on the security
								video surveillance system;
							</li>
							<li>
								such other collections and uses of Personal
								Information from such persons and for such
								purposes for which the Centre may obtain consent
								from time to time; and
							</li>
							<li>as otherwise required or permitted by law.</li>
						</ul>
						<h6>From Individuals other than Employees:</h6>
						<ul>
							<li>
								as part of the Individuals’ requests for the
								Centre’s dispute resolution services or dispute
								prevention services;
							</li>
							<li>
								as part of the Individuals’ applications to
								participate in one of the Centre’s programs;
							</li>
							<li>
								to advise Individuals about new programs and
								services that may be of interest to them or to
								their organizations;
							</li>
							<li>
								to monitor the use of the Case Management Portal
								and OSIC Complaint Management Portal and detect
								possible fraudulent attempted use; and
							</li>
							<li>
								for the purposes of statistical reporting and
								clients’ sport profiles.
							</li>
						</ul>
						<h6>From Employees:</h6>
						<ul>
							<li>
								for the purpose of recruitment for positions at
								the Centre;
							</li>
							<li>
								for the purpose of the administration of the
								Centre’s policies and procedures regarding the
								training, retention and evaluation of Employees;
							</li>
							<li>
								for the purposes of coaching, mentoring and
								professional development;
							</li>
							<li>
								for the purposes of managing productivity,
								including making accommodations and allowances;
							</li>
							<li>
								from Third Party providers of benefits, pension
								arrangements and insurance and other related
								Employee services, for the purpose of providing
								compensation and such services and fulfilling
								taxation requirements in respect of same;
							</li>
							<li>
								to comply with other requirements imposed by law
								including, but not limited to, collecting
								Personal Information as required by applicable
								workplace insurance and safety legislation and
								occupational health and safety legislation; and
							</li>
							<li>
								for the purpose of assisting in the
								administration of Workers’ Compensation program
								after a work-related illness or injury.
							</li>
						</ul>
					</Col>

					<Col sm={12} className="font-italic fw-bold">
						<hr />
						<h5>Headversity User Privacy Policy</h5>
						<em>Last updated November 24, 2023</em>
					</Col>
					<Col sm={12}>
						<div>
							<strong>
								Headversity.com, and its affiliates or
								affiliated websites, including
								macromindmedia.com/ Respect Group Inc and its
								affiliated websites.
							</strong>{" "}
							(“<strong>Headversity</strong>” / “
							<strong>Respect Group</strong>”, “
							<strong>we</strong>” or “<strong>us</strong>”),
							respect the privacy of the people (“
							<strong>you</strong>” or “<strong>User</strong>”)
							who interact with our website (the “
							<strong>Site</strong>”) and use the training tools
							and programs we provide to you via the Site (the “
							<strong>Services</strong>”). This Privacy Policy
							applies to information that can directly or
							indirectly identify you (“
							<strong>Personal Information</strong>”) and that you
							provide to us when you use our Site and Services,
							and when you access, download, purchase or use any
							information, services, products, or content
							appearing on the Site.
						</div>
					</Col>
					<Col sm={12}>
						Our Chief Technology Officer (“<strong>CTO</strong>”) is
						responsible for our compliance with applicable Personal
						Information protection legislation.
					</Col>
					<Col sm={12}>
						Our CTO can be reached at the following coordinates:
					</Col>
					<Col sm={12}>
						<a href="mailto:Russ@headversity.com" tabIndex={-1}>
							Russ@headversity.com
						</a>
					</Col>
					<Col sm={12}>
						Our Chief Privacy Officer (“<strong>CPO</strong>”) is
						responsible for our compliance with applicable Personal
						Information protection legislation.
					</Col>
					<Col sm={12}>
						Our CPO can be reached as the following coordinates:
					</Col>
					<Col sm={12}>By Email:</Col>
					<Col sm={12}>
						<a
							href="mailto:PrivacyOfficer@headversity.com"
							tabIndex={-1}
						>
							PrivacyOfficer@headversity.com
						</a>
					</Col>
					<Col sm={12}>By Mail</Col>
					<Col sm={12}>
						<em>
							Respect Group Inc.
							<br />
							340, 12th Ave SW,
							<br />
							Calgary, AB <br />
							T2R 1L5
							<br />
						</em>
					</Col>
					<Col sm={12}>
						This Privacy Policy may be updated from time to time. We
						will notify you of any material changes by posting the
						new Privacy Policy on the Site. Your interaction with
						our Site and use of its Services constitutes your
						consent to and acceptance of the terms and conditions of
						this Privacy Policy.
					</Col>
					<Col sm={12}>
						As used in this policy, the terms “using” and
						“processing” information include using cookies on a
						computer, subjecting the information to statistical or
						other analysis and using or handling information in any
						way, including, but not limited to collecting, storing,
						evaluating, modifying, deleting, combining, disclosing
						and transferring information within our organization or
						among our affiliates within Canada or internationally.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							PERSONAL INFORMATION COLLECTION AND USE
						</h6>
					</Col>
					<Col sm={12}>Personal Information You Share With Us</Col>
					<Col sm={12}>
						When you register to become a member of or use the Site,
						you provide us with Personal Information including:
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								<u>Coordinates</u>: your name, phone number, and
								email address.
							</li>
							<li className={`${styles.li}`}>
								<u>Profile information</u>: your name and photo.
							</li>
							<li className={`${styles.li}`}>
								<u>User Account information</u>: your password,
								username, security question and answer for
								username/ password/certificate recovery.
							</li>
							<li className={`${styles.li}`}>
								<u>
									Additional registration information
									requested by client
								</u>
								: date of birth, phone number or other Personal
								Information to facilitate the matching of your
								profile in our data base with that in your
								organization's data base.
							</li>
							<li className={`${styles.li}`}>
								<u>Other</u>: any information you share online
								in response to open-ended questions to which you
								respond when participating in our program.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Your Personal Information is used for the following
						purposes:
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								to establish and maintain our commercial
								relationship with you. This includes to
								administer user accounts, process new accounts,
								and to fulfill contractual and legal obligations
								such as registering users, identifying users
								with their respective organizations, or
								verifying user status;
							</li>
							<li className={`${styles.li}`}>
								to develop and manage our business and
								operations. This may include the sharing of
								Personal Information by and between our
								personnel;
							</li>
							<li className={`${styles.li}`}>
								to detect errors and protect us and other third
								parties against fraud, theft and other illegal
								activity, and to audit compliance with our
								policies and contractual and legal obligations;
							</li>
							<li className={`${styles.li}`}>
								to provide you with further information and
								offers from us or from third parties that we
								believe you may find useful or interesting,
								including newsletters, marketing or promotional
								materials and other information on related
								services. If you decide at any time that you no
								longer wish to receive such communications,
								please follow the unsubscribe instructions
								provided in any of the communications. Further,
								you may easily adjust your privacy and e-mail
								settings via your account settings at any time;
							</li>
							<li className={`${styles.li}`}>
								to engage in a business transaction, including
								the purchase, sale, lease, merger, amalgamation
								or any other type of acquisition, disposal,
								securitization or financing involving us;
							</li>
							<li className={`${styles.li}`}>
								to understand and respond to Users, supplier and
								other third party needs and preferences,
								including to contact and communicate with such
								parties and to conduct surveys, research, and
								evaluations;
							</li>
							<li className={`${styles.li}`}>
								to develop, enhance, market, sell or otherwise
								provide our products and Services;
							</li>
							<li className={`${styles.li}`}>
								to comply with any legal or regulatory
								requirements or provisions; and
							</li>
							<li className={`${styles.li}`}>
								for any other purpose to which you consent.
							</li>
						</ol>
					</Col>
					<Col sm={12}>Log Data</Col>
					<Col sm={12}>
						When you interact with or use the Site to access our
						Services, we may also collect the following Personal
						Information as our servers automatically record
						information that the browser sends whenever it points to
						a Site or online service (“Log Data”). Log Data may
						include, but is not limited to:
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								your computer’s Internet Protocol address,
							</li>
							<li className={`${styles.li}`}>
								your browser type,
							</li>
							<li className={`${styles.li}`}>
								the uniform resource locator (“
								<strong>URL</strong>”) of the web site you
								visited before and /or after interacting with
								our site and information you search for on a
								Site.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Your Log Data is used for the following purposes
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								to monitor and analyze use of the Site and
								Services;
							</li>
							<li className={`${styles.li}`}>
								to technically administer the Site and Services
								to increase functionality and user-friendliness,
								and to better tailor it to our Users’ needs. For
								example, some of this information is collected
								so that when you visit the Site again, it will
								recognize you and serve information appropriate
								to your interests;
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Like many websites, we use “cookies” to collect your Log
						Data. A cookie is a small data file that we transfer to
						your computer’s hard disk for record-keeping purposes.
						We use “persistent cookies” to save your username and
						login password for future logins to the Site. We use
						“session ID cookies” to enable certain features of our
						service, to better understand how you interact with the
						Site and to monitor web traffic routing on the Site and
						aggregate usage of the Site. You can instruct your
						browser, by changing its options, to stop accepting
						cookies or to prompt you before accepting a cookie from
						the websites you visit. If you do not accept cookies,
						however, you may not be able to use all portions of the
						Site or all functionality of the services.
					</Col>
					<Col sm={12}>Invite-A-Friend</Col>
					<Col sm={12}>
						If you choose to invite your friends to use our
						Services, using our invitation options, we may ask for
						information such as an e-mail address. We will
						automatically send your friend(s) a one-time e-mail
						invitation to visit the Site. We store this information
						to send this one-time invitation, and to track the
						success of this referral feature.
					</Col>
					<Col sm={12}>
						<strong>
							Please note that depending on the Services that we
							are providing, we may be asked by your organisation
							to collect additional Personal Information to that
							listed above. If you believe this to be the case and
							are interested in knowing what this Personal
							Information is and how it will be used, we invite
							you to contact our CTO/CPO at the coordinates
							provided above.
						</strong>
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							INFORMATION SHARING AND DISCLOSURE
						</h6>
					</Col>
					<Col sm={12}>
						We will not disclose to any unauthorized third party a
						User’s name or contact information. We will not monitor,
						edit or disclose the contents of any User’s information
						unless required to do so by law or in the good faith
						belief that such action is necessary. In this case, the
						people to whom we might disclose your Personal
						Information include:
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								a person who, in our reasonable judgment, is
								providing or seeking the information as your
								agent;
							</li>
							<li className={`${styles.li}`}>
								the organization that requested you complete one
								of our programs, the organization’s governing
								body or parent;
							</li>
							<li className={`${styles.li}`}>
								any of the companies and business entities that
								form part of our organization;
							</li>
							<li className={`${styles.li}`}>
								an organization or individual retained by us to
								perform functions on our behalf, such as
								contractors, consultants, auditors, software
								developers (including web-site developers and
								hosts), data processing, document management and
								office services;
							</li>
							<li className={`${styles.li}`}>
								our auditors and professional advisors;
							</li>
							<li className={`${styles.li}`}>
								another person or corporation as part of a
								business transaction as this term is defined in
								applicable Personal Information protection
								legislation;
							</li>
							<li className={`${styles.li}`}>
								another company or person for the development,
								enhancement, marketing or provision of any of
								our products or Services;
							</li>
							<li className={`${styles.li}`}>
								an agent or third party retained by us in
								connection with our administration or provision
								of our products or Services;
							</li>
							<li className={`${styles.li}`}>
								applicable public authorities where such
								disclosure is required pursuant to applicable
								laws;
							</li>
							<li className={`${styles.li}`}>
								a public authority or agent of a public
								authority, if in our reasonable judgment, it
								appears that there is imminent danger to life or
								property which could be avoided or minimized by
								disclosure of this information;
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						<strong>Please note</strong> that some limited Profile
						information you submit to the Site may also be available
						to other Users who search our Site through specific
						tools created by us to show accreditation or
						certification in any of our programs. These items will
						only provide confirmation of accreditation or
						certification of programs when certain limited
						information is provided: First name, Last Name, Email.
					</Col>
					<Col sm={12}>
						Aggregate Information and Non-Identifying Information.
						We may share aggregated information that includes
						non-identifying information and Log Data with third
						parties for industry analysis, demographic
						acknowledgement, and to deliver targeted programming
						about other products and services.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							HOW LONG DO WE STORE YOUR INFORMATION
						</h6>
					</Col>
					<Col sm={12}>
						We will not retain your Personal Information longer than
						is necessary to fulfil the purpose for which it was
						collected including for the purposes of satisfying any
						legal, accounting, or reporting requirements and, where
						required for us to assert or defend against legal
						claims, until the end of the relevant retention period
						or until the claims in question have been settled, after
						which we will use our best efforts to destroy or
						anonymise it. As this purpose varies depending upon the
						Personal Information involved, we invite you to contact
						our CTO for any inquiries pertaining specifically to
						you.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							ACCURACY OF YOUR INFORMATION
						</h6>
					</Col>
					<Col sm={12}>
						We use reasonable efforts to ensure that your Personal
						Information is kept as accurate, complete and up to date
						as possible. We do not routinely update your Personal
						Information, unless such an update is necessary. To help
						us maintain and ensure that your Personal Information is
						accurate and up to date, you must inform us, without
						delay, of any change in the Personal Information you
						provide to us.
					</Col>
					<Col sm={12}>
						Additionally, all members may review, update, correct or
						delete the Personal Information in their registration
						profile by logging into their account and changing the
						“User preferences” associated with their account. If you
						completely delete all such Personal Information, your
						account may be deactivated. We may retain an archived
						copy of your records as required by law or for
						legitimate business purposes.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">SECURITY</h6>
					</Col>
					<Col sm={12}>
						We employ administrative, physical, and electronic
						measures designed to safeguard and protect your Personal
						Information from unauthorized access and disclosure
						including encrypting your communication by utilizing
						Secure Sockets Layer (“<strong>SSL</strong>”) software.
					</Col>
					<Col sm={12}>
						We will make any legally required disclosures of any
						breach of the security, confidentiality, or integrity of
						your unencrypted electronically stored Personal
						Information to you via email or conspicuous posting on
						this Site in the most expedient time possible and
						without unreasonable delay, insofar as consistent with
						(i) the legitimate needs of law enforcement or (ii) any
						measures necessary to determine the scope of the breach
						and restore the reasonable integrity of the data system.
					</Col>
					<Col sm={12}>
						You post and share your Personal Information and content
						on the Site at your own risk. While we go to great
						lengths to create a safe and enjoyable experience on the
						Site, we cannot control the actions of other users with
						whom you may choose to share your content and/or profile
						information. In addition, we cannot anticipate other
						users’ ability to circumvent privacy settings. You
						understand that, even after removal of your Personal
						Information and content from the Site, copies of your
						information may remain viewable in cached or archived
						Web pages or on the local computers of users who may
						have downloaded or stored your information.
					</Col>
					<Col sm={12}>
						Even with all the appropriate precautions we take to
						protect your information, we recommend you refrain from
						sending private information or Personal Information by
						e-mail, chat, or other messaging services.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">INTERNATIONAL TRANSFER</h6>
					</Col>
					<Col sm={12}>
						Your Personal Information may be transferred to and
						maintained on computers located outside of your state,
						province, country or other governmental jurisdiction
						where the privacy laws may not be as protective as those
						in your jurisdiction. If you are located outside Canada
						and choose to provide information to us, please be
						advised that we transfer Personal Information to Canada
						and process it there. Your consent to this Privacy
						Policy followed by your submission of such information
						represents your agreement to that transfer.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">LINKS TO OTHER SITES</h6>
					</Col>
					<Col sm={12}>
						The Site and Services may contain links to other
						websites, such as an organization’s EAP or other support
						resources provided by an employer or membered
						association. If you choose to click on another
						third-party link, you will be directed to that third
						party’s website. The fact that we link to a website is
						not an endorsement, authorization, or representation
						that we are affiliated with that third party, nor is it
						an endorsement of their privacy or information security
						policies or practices. We do not exercise control over
						third party websites. These other websites may place
						their own cookies or other files on your computer,
						collect data or solicit Personal Information from you.
						Other websites and services follow different rules
						regarding the use or disclosure of the Personal
						Information you submit to them. We encourage you to read
						the privacy policies or statements of the other websites
						you visit.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">YOUR RIGHTS</h6>
					</Col>
					<Col sm={12}>
						Depending upon the jurisdiction in which you live, you
						may have some or all of the following rights with
						respect to your Personal Information:
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								Access: You have the right to request whether we
								hold Personal Information on you and to request
								a copy of such information.
							</li>
							<li className={`${styles.li}`}>
								Accuracy: We aim to keep your Personal
								Information accurate, current, and complete. We
								encourage you to contact us to let us know if
								any Personal Information is not accurate or
								changes, so that we can update your Personal
								Information. You can also make changes to your
								Personal Information in the “user preferences”
								section of the Site.
							</li>
							<li className={`${styles.li}`}>
								Withdraw consent: If you have provided your
								consent to the processing of your Personal
								Information, you have the right to fully or
								partly withdraw your consent. Once we have
								received notification that you have withdrawn
								your consent, we will no longer process your
								information for the purpose(s) to which you
								originally consented unless there is another
								legal ground for the processing. Withdrawal of
								consent to receive marketing communications will
								not affect the processing of Personal
								Information for the provision of our services.
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									Cessation of Dissemination: You have the
									right to request that we cease disseminating
									your Personal Information if the
									dissemination is contrary to the law or a
									court order.
								</p>
								<p className={`${styles.p}`}>
									You also have the right to request that we
									cease disseminating your Personal
									Information where the following conditions
									are met:
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										the dissemination of the information
										causes you serious injury in relation to
										your right to have your reputation or
										privacy respected;
									</li>
									<li className={`${styles.li}`}>
										the injury is clearly greater than the
										public’s interest in knowing the
										information or the interest of any
										person’s right to express themselves
										freely; and
									</li>
									<li className={`${styles.li}`}>
										the cessation of dissemination requested
										does not exceed what is necessary for
										preventing the perpetuation of the
										injury.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									De-indexation: You have the right to request
									that we de-index any hyperlink attached to
									your name that provides access to
									information by a technological means if the
									dissemination is contrary to the law or a
									court order.
								</p>
								<p className={`${styles.p}`}>
									You also have the right to request that we
									de-index a link providing access to
									information where the following conditions
									are met:
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										the dissemination of the information
										causes you serious injury in relation to
										your right to have your reputation or
										privacy respected;
									</li>
									<li className={`${styles.li}`}>
										the injury is clearly greater than the
										public’s interest in knowing the
										information or the interest of any
										person’s right to express themselves
										freely; and{" "}
									</li>
									<li className={`${styles.li}`}>
										the cessation of dissemination requested
										does not exceed what is necessary for
										preventing the perpetuation of the
										injury.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									Re-indexation: You also have the right to
									request that we re-index a link providing
									access to information where the following
									conditions are met:
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										a failure to do so causes you serious
										injury in relation to your right to have
										your reputation or privacy respected;
									</li>
									<li className={`${styles.li}`}>
										the injury caused by a failure to
										re-index is greater than the public’s
										interest in knowing the information or
										the interest of any person’s right to
										express themselves freely; and
									</li>
									<li className={`${styles.li}`}>
										the re-indexation requested does not
										exceed what is necessary for preventing
										the perpetuation of the injury.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								Mobility: You have the right to request that
								computerized Personal Information collected from
								you be communicated to you in a commonly used
								technological format as well as to any person or
								body authorized by law to collect such
								information. This right does not extend to
								information that was created or inferred from
								your Personal Information and we are under no
								obligation to communicate such information if
								doing so raises serious practical difficulties.
							</li>
							<li className={`${styles.li}`}>
								Complaints: If you believe that your Personal
								Information protection rights may have been
								violated, you have the right to lodge a
								complaint with the applicable supervisory
								authority, or to seek a remedy thought the
								courts.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						You may, at any time, enquire about or exercise any of
						the above rights, by contacting the CTO/CPO.
					</Col>
					<Col sm={12}>
						We will generally respond to all access requests within
						30 days of the receipt of all necessary information. In
						circumstances where we are not able to provide access,
						or if additional time is required to fulfill a request,
						we will advise you in writing.
					</Col>
					<Col sm={12}>
						We reserve the right not to release certain types of
						information based upon exemptions specified in
						applicable laws. Where possible, we will sever the
						information that will not be disclosed and provide you
						with access to the remaining information. Should we be
						unable to provide access to or disclose Personal
						Information to you, we will provide you with an
						explanation, subject to restrictions.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">CONTACT US</h6>
					</Col>
					<Col sm={12}>
						If you have any questions about this Privacy Policy you
						can contact us at CTO/CPO{" "}
						<a href="mailto:info@macromindmedia.com" tabIndex={-1}>
							info@macromindmedia.com
						</a>
					</Col>
				</Row>
			</Container>
		);
	}

	if (i18n.language === "fr") {
		return (
			<Container fluid>
				<Row className="g-3 mt-0">
					<Col sm={12} className="my-0">
						<h4 className="fw-normal">
							Politique de confidentialité des utilisateurs
						</h4>
						<div className="fw-normal text-muted">
							{i18n.t("profile-privacy-policy-info")}
						</div>
					</Col>
					<Col sm={12}>
						Ce module de consentement du Participant Sport Sans Abus
						(« Module ») et les informations recueillies par son
						entremise sont assujettis à la Politique de protection
						des renseignements personnels du Centre de règlement des
						différends sportifs du Canada (« politique du CRDSC »)
						et à la Politique de protection de la vie privée de
						Headversity (« Politique de confidentialité des
						utilisateurs »), telles que reproduites ci-dessous. Pour
						utiliser ce Module, vous devez confirmer votre
						acceptation de la politique du CRDSC et de la politique
						de Headversity en cliquant sur le bouton "ACCEPTER"
						ci-dessous. L'acceptation de ces conditions est une
						condition préalable, mais ne constitue pas en soi un
						consentement à devenir un Participant de Sport Sans
						Abus. Vous devez compléter le Module dans son
						intégralité et donner votre consentement explicite,
						comme indiqué dans le Module, pour confirmer votre
						statut de Participant auprès de Sport Sans Abus.
					</Col>
					<Col sm={12}>
						<hr />
						<h5>
							CENTRE DE RÈGLEMENT DES DIFFÉRENDS SPORTIFS DU
							CANADA (CRDSC)
						</h5>
						<h5 className="fw-normal">
							Politique de protection des renseignements
							personnels
						</h5>
						<strong>Version finale</strong>
						<br />
						Adoptée par résolution du Conseil d’administration, le
						24 février 2017.
						<br />
						Révisée par résolution du Conseil d’administration, le
						15 juin 2022; entrant en vigueur le 20 juin 2022.
						<br />
					</Col>
					<Col sm={12}>
						<h5>Introduction</h5>
						<p>
							Le Centre s’engage à respecter le caractère privé et
							la confidentialité de tous les renseignements
							personnels recueillis au cours de l’administration
							de ses programmes. La présente Politique décrit les
							mesures mises en œuvre par le Centre pour établir et
							promouvoir des pratiques responsables de gestion des
							renseignements personnels conformes aux exigences
							des lois et règlements sur la protection des
							renseignements personnels applicables.
						</p>
						<div className="ps-4">
							<strong>A. Objet</strong>
							<p>
								La Politique établit de quelle manière le Centre
								recueille, utilise et communique les
								renseignements personnels dans l’exercice de ses
								activités, en énonçant des principes à suivre
								pour la gestion des renseignements personnels
								tout en assurant un équilibre optimal entre la
								nécessité de recueillir des renseignements
								personnels pour la conduite des activités du
								Centre, le droit au respect de la vie privée de
								ses clients, employés, administrateurs et
								parties prenantes ainsi que d'autres droits
								fondamentaux dans le respect du principe de
								proportionnalité.
							</p>
						</div>
						<div className="ps-4">
							<strong>B. Portée</strong>
							<p>
								Le Centre s’engage à se conformer, à tout moment
								pertinent et dans toutes ses activités, aux lois
								fédérales, provinciales et territoriales sur la
								protection des renseignements personnels
								applicables et leurs règlements (« lois
								applicables sur la protection des renseignements
								personnels »), avec toutes leurs modifications
								successives. Il s’engage également à se
								conformer au{" "}
								<i>
									Standard international pour la protection
									des renseignements personnels
								</i>{" "}
								publié et modifié de temps à autre par l’Agence
								mondiale antidopage dans la gestion de son
								Tribunal antidopage et de son Tribunal d’appel
								antidopage.{" "}
							</p>
						</div>
						<div className="ps-4">
							<strong>C. Champ d’application</strong>
							<p>
								La Politique s’applique à tous les employés,
								administrateurs et professionnels du règlement
								des différends du Centre, ainsi qu’aux tierces
								parties engagées par le Centre.
							</p>
						</div>
						<div className="ps-4">
							<strong>D. Modifications</strong>
							<p>
								La Politique peut être modifiée de temps à autre
								par le Conseil d’administration du Centre pour
								toute raison, notamment pour tenir compte de
								l’introduction de nouvelles technologies et
								pratiques d’affaires, et de nouveaux besoins des
								parties prenantes ou exigences des lois et
								règlements applicables.
							</p>
						</div>
						<div className="ps-4">
							<strong>E. Avertissement</strong>
							<p>
								Bien que les règles de procédure qui régissent
								la conduite de ses services de règlement des
								différends prévoient des mesures pour assurer la
								protection des renseignements personnels, comme
								le précise le{" "}
								<i>
									Code canadien de règlement des différends
									sportifs
								</i>
								, et/ou la{" "}
								<i>Politique de confidentialité du BCIS</i> (le
								cas échéant) le Centre ne saurait être tenu
								responsable du comportement des parties, des
								représentants autorisés ou des témoins qui
								participent aux procédures de règlement de
								différends (ou toute procédure de plainte du
								BCIS), qui pourraient donner lieu à la
								divulgation illégale de renseignements
								personnels faisant partie de la preuve présentée
								au Centre.
							</p>
							<p>
								La plupart de ses services étant fournis en mode
								virtuel, le Centre s’engage à prendre des
								mesures raisonnables pour empêcher l’accès non
								autorisé aux renseignements personnels sous
								forme électronique stockés sur ses propres
								serveurs, néanmoins, il ne saurait être tenu
								responsable de toute intrusion causée par les
								fournisseurs de services de courrier
								électronique ou Internet des destinataires
								attitrés des courriels.
							</p>
							<p>
								Les sites Internet du Centre, incluant ceux du
								BCIS et du Sport Sans Abus, fournissent des
								liens vers des sites Internet de tierces
								parties. Le Centre n’est pas responsable de la
								collecte, de l’utilisation ou de la
								communication de renseignements personnels
								obtenus par les sites Internet de ces tierces
								parties. Il est fortement recommandé aux
								visiteurs du site Internet du Centre de
								consulter les politiques de protection de ces
								tierces parties avant de leur communiquer des
								renseignements personnels.
							</p>
						</div>
						<div className="ps-4">
							<strong>F. Définitions</strong>
							<p>
								<i>Administrateur :</i> tout membre du Conseil
								d’administration du Centre, nommé de temps à
								autre par le ministre du Canada responsable du
								sport;
							</p>
							<p>
								<i>Agent de la protection de la vie privée :</i>{" "}
								le Directeur des opérations du Centre ou tout
								autre administrateur ou employé que le Conseil
								d’administration peut nommer de temps à autre
							</p>
							<p>
								<i>BCIS :</i> Bureau du commissaire à
								l'intégrité dans le sport, une fonction
								indépendante au sein du Centre, responsable de
								l'administration et de l'application de l'UCCMS;
							</p>
							<p>
								<i>CCUMS :</i> Code de conduite universel pour
								prévenir et contrer la maltraitance dans le
								sport (CCUMS) disponible{" "}
								<Link
									to="https://commissaireintegritesport.ca/ccums"
									target="_blank"
									rel="noopener noreferrer"
									tabIndex={-1}
								>
									ici;
								</Link>
							</p>
							<p>
								<i>Consentement explicite :</i> un consentement
								donné par la personne concernée en mode
								électronique, par écrit ou, au besoin, de vive
								voix, qui doit toujours être sans équivoque et
								qui ne nécessite pas une déduction de la part du
								Centre;{" "}
							</p>
							<p>
								<i>Consentement implicite :</i> un consentement
								qui peut raisonnablement être déduit des actions
								ou de l’inaction de la personne concernée;
							</p>
							<p>
								<i>Demande :</i> droit d’une personne d’accéder
								à des renseignements personnels la concernant et
								de demander d’y apporter des corrections, et
								demande, de toute autre personne, pour accéder à
								des renseignements autres que des renseignements
								personnels la concernant (demande d’accès);
							</p>
							<p>
								<i>Employé :</i> toute personne engagée par le
								Centre afin d’exécuter des tâches dans la
								conduite de ses activités en échange d’une
								compensation monétaire ou de crédits d’un
								programme d’enseignement coopératif. Il est
								précisé, par souci de clarté, qu’un
								professionnel du règlement des différends n’est
								pas un employé;
							</p>
							<p>
								<i>Personne concernée :</i> une personne à
								l’égard de laquelle des renseignements
								personnels sont recueillis, utilisés,
								communiqués ou conservés par le Centre;
							</p>
							<p>
								<i>Partie :</i> utilisateur des services de
								règlement des différends du Centre ou des
								services du BCIS, comprenant sans s’y limiter,
								un demandeur, un intimé, une partie affectée ou
								un intervenant au sens des définitions du Code
								canadien de règlement des différends sportifs ou
								autres politiques et procédures du BCIS;
							</p>
							<p>
								<i>Plainte :</i> un formulaire de plainte
								déposé, la réception par le BCIS d'informations
								expressément considérées par le BCIS comme
								constituant une plainte, ou une plainte initiée
								par le BCIS, dans chaque cas concernant une
								violation présumée du CCUMS;
							</p>
							<p>
								<i>Portail de gestion de dossiers :</i> la
								plateforme en ligne propriétaire dont les
								employés du Centre se servent, dans la gestion
								des procédures du Tribunal, pour partager des
								renseignements et des documents avec les
								professionnels du règlement des différends, les
								parties et leurs représentants autorisés;
							</p>
							<p>
								<i>Portail de gestion des plaintes :</i> la
								plateforme en ligne utilisée par les employés du
								Centre et les professionnels du BCIS dans la
								gestion des plaintes pour partager des
								renseignements et des documents avec le
								Directeur des sanctions et des résultats, les
								professionnels du règlement des différends, les
								parties et leurs représentants autorisés;
							</p>
							<p>
								<i>Professionnels du BCIS :</i> tout membre de
								la liste des enquêteurs indépendants du BCIS du
								Centre, agissant sur désignation par le Centre;
							</p>
							<p>
								<i>
									Professionnel du règlement des différends :
								</i>{" "}
								toute personne inscrite sur la liste des
								arbitres et médiateurs du Centre, qui agit sur
								nomination du Centre ou consentement des
								parties;
							</p>
							<p>
								<i>Renseignements personnels :</i> tout
								renseignement concernant une personne
								identifiable, à l’exclusion des renseignements
								accessibles au public de toute autre manière;{" "}
							</p>
							<p>
								<i>Renseignements personnels sensibles :</i> des
								renseignements personnels plus délicats,
								comprenant, sans s’y limiter, des renseignements
								personnels relatifs à l’origine raciale ou
								ethnique de la personne concernée, à des
								infractions commises (criminelles ou autres), à
								sa santé ou à ses informations génétiques;
							</p>
							<p>
								<i>Représentant autorisé :</i> tout avocat qui
								comparaît devant le tribunal au nom de la
								personne concernée, toute autre personne
								désignée par écrit par la personne concernée ou,
								dans le cas d’une personne mineure qui n’est pas
								émancipée, tout parent, gardien légal ou
								représentant autorisé;
							</p>
							<p>
								<i>Tierce partie :</i> toute personne,
								institution, société ou autre entité qui a
								conclu une entente contractuelle avec le Centre
								dans l’exercice de ses activités afin de fournir
								des services qui nécessitent ou pourraient
								nécessiter un accès à des renseignements
								personnels, comprenant, sans s’y limiter, des
								enquêteurs, des téléphonistes de la Ligne
								d’assistance du sport canadien, des consultants
								et d’autres fournisseurs de biens ou de
								services. Il est précisé, par souci de clarté,
								que la définition de tierce partie exclut les
								professionnels du règlement des différends;
							</p>
							<p>
								<i>Tribunal :</i> le Secrétariat de règlement
								des différends du Centre;
							</p>
							<p>
								<i>Utilisateur de service :</i> toute personne
								qui fournit des renseignements personnels au
								Centre dans le but de recevoir des services ou
								de prendre part à des programmes du Centre
								autres que les services du Tribunal, comprenant,
								sans s’y limiter, les personnes qui s’inscrivent
								à des événements organisés par le Centre, les
								candidats à la liste des professionnels du
								règlement des différends, les candidats au
								Conseil d’administration et les candidats à des
								emplois et stages.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							1 - Responsabilité et transparence
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									1.1 L’agent de la protection de la vie
									privée
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Il incombe à l’agent de la protection de la
								vie privée de s’assurer que la Politique
								respecte les lois et règlements sur la
								protection de la vie privée applicables, de
								vérifier que le Centre se conforme à la
								Politique, et de signaler les plaintes et
								violations relatives à la protection de la vie
								privée, et d’y répondre.
							</p>
							<p>
								b) L’agent de la protection de la vie privée
								supervise la mise en œuvre des autres politiques
								et procédures du Centre qui contribuent
								également à la protection des renseignements
								personnels.{" "}
							</p>
							<p>
								c) L’agent de la protection de la vie privée
								peut être contacté par courrier électronique à{" "}
								<Link
									to="mailto:priv@crsdc-sdrcc.ca"
									tabIndex={-1}
								>
									priv@crsdc-sdrcc.ca
								</Link>{" "}
								ou par téléphone en composant le numéro général
								du bureau du Centre, ou en personne à l’adresse
							</p>
						</div>
					</Col>
					<Col sm={12} className="mt-0 text-center">
						<small>
							6400, Auteuil, Suite 245
							<br />
							Brossard (Québec)
							<br />
							J4Z 3P5
							<br />
						</small>
					</Col>
					<Col sm={12}>
						<div className="ps-5">
							<p>
								d) En cas d’absence ou d’incapacité de l’agent
								de la protection de la vie privée, ou à tout
								autre moment où cela serait jugé nécessaire, le
								Conseil d’administration du Centre peut désigner
								un administrateur ou un employé pour remplacer
								l’agent de la protection de la vie privée.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<div className="ps-4">
							<p>
								<strong>1.2 Publication de la Politique</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) La Politique est mise à la disposition du
								public par le biais du(des) site(s) Internet du
								Centre et sur demande.{" "}
							</p>
							<p>
								b) b) Le Centre est tenu de donner accès, par le
								biais de la Politique ou autrement :
							</p>
							<p className="ps-4">
								i. à une description du type de renseignements
								personnels recueillis et des fins auxquelles
								ceux-ci sont recueillis;
							</p>
							<p className="ps-4">
								ii. aux méthodes à la disposition des personnes
								concernées pour avoir accès aux renseignements
								personnels à leur sujet qui se trouvent dans les
								dossiers du Centre; et
							</p>
							<p className="ps-4">
								iii. lorsque des renseignements personnels sont
								mis à la disposition de tierces parties, à une
								justification de leur communication à ces
								tierces parties.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>1.3 Modifications</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les modifications à la Politique doivent être
								mises à la disposition du public après leur
								adoption, au plus tard un (1) mois avant leur
								entrée en vigueur, par le biais du site Internet
								du Centre ou sur demande. Il est recommandé aux
								personnes qui fournissent des renseignements
								personnels au Centre de consulter régulièrement
								la Politique pour se tenir au courant des
								modifications et mises à jour.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>1.4 Divergences</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) En cas de divergence entre lois applicables
								sur la protection des renseignements personnels
								et la Politique, les lois applicables sur la
								protection des renseignements personnels auront
								préséance.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							2 - Détermination des types de renseignements
							recueillis et des fins de leur collecte
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									2.1 Types de renseignements recueillis
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le Centre recueille les renseignements
								personnels qui sont nécessaires pour ses
								activités et/ou tel que requis par la loi.
							</p>
							<p>
								b) Pour exercer ses activités, le Centre a
								besoin au minimum des noms, prénoms et
								coordonnées (adresse de courriel, numéro de
								téléphone et adresse postale) des utilisateurs
								de services, des parties et, le cas échéant, de
								leurs représentants autorisés.
							</p>
							<p>
								c) Le Centre est également saisi par les Parties
								ou leurs Représentants autorisés de certains
								renseignements personnels et/ou de
								renseignements personnels sensibles par le biais
								de la preuve documentaire et des observations et
								documents communiqués au cours des procédures de
								règlement des différends et par le biais du
								processus de gestion des plaintes ou de
								l'administration du CCUMS, tels que des
								renseignements sur la santé, les infractions
								criminelles, le nom de famille, le prénom, les
								coordonnées et les renseignements relatifs aux
								plaintes déposées contre des personnes et aux
								sanctions connexes. Les renseignements
								personnels fournis par les parties ou leurs
								représentants autorisés, y compris, mais sans
								s'y limiter, les renseignements financiers, les
								renseignements sur la santé, le nom de famille,
								le prénom et les coordonnées, les renseignements
								concernant les plaintes ou d'autres
								renseignements sur les procédures devant le
								Centre, peuvent également être recueillis afin
								de déterminer l'admissibilité à certains
								programmes offerts par le Centre (p.ex., l'aide
								juridique, les références en santé mentale) et
								afin d'offrir ces programmes aux parties
								admissibles.
							</p>
							<p>
								d) Le Centre recueille des renseignements
								personnels auprès de ses employés,
								administrateurs et professionnels du règlement
								des différends, comprenant, sans s’y limiter,
								des renseignements financiers, noms, prénoms et
								coordonnées.
							</p>
							<p>
								e) Le Portail de gestion de dossiers du Centre
								et le Portail de gestion des plaintes du BCIS
								recueillent des témoins (cookies) sur les
								comptes d’utilisateurs, tels que les adresses
								IP, les sections du portail visitées et les
								renseignements téléchargés.
							</p>
							<p>
								f) Les sites Internet du Centre recueillent
								également des renseignements non identifiables
								tels que des témoins, comprenant, sans s’y
								limiter, les adresses IP, les sections du site
								Internet visitées et les renseignements
								téléchargés.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>2.2 Fins</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les fins auxquelles les renseignements
								personnels sont recueillis par le Centre sont
								énumérées à l’Annexe A.
							</p>
							<p>
								b) Le Centre doit informer les personnes
								concernées des fins applicables, préalablement
								ou au moment de la collecte des renseignements
								personnels et de leur utilisation.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							3 – Obtention d’un consentement éclairé valide
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									3.1 Quand obtenir un consentement
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Sauf lorsqu’il est raisonnable de penser
								qu’un consentement implicite a été donné, en cas
								d’urgence ou lorsque cela n’est pas exigé par la
								loi, le Centre doit obtenir un consentement
								valide de la personne concernée, ou de son
								Représentant autorisé, préalablement ou au
								moment de la collecte des renseignements
								personnels en vue de leur utilisation et
								communication.
							</p>
							<p>
								b) Sauf lorsque cela est permis par la loi, si
								les renseignements personnels recueillis doivent
								être utilisés à des fins autres que celles
								auxquelles la personne concernée avait consenti,
								le Centre doit en informer cette dernière et
								obtenir son consentement pour toutes nouvelles
								fins auxquelles il prévoit utiliser ces
								renseignements.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									3.2 Consentement explicite ou implicite
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le consentement peut être obtenu de façon
								soit explicite soit implicite, et peut être
								fourni par la personne concernée ou par un
								représentant autorisé. Pour déterminer la forme
								de consentement requise, le Centre prend en
								considération le caractère sensible des
								renseignements personnels et les attentes
								raisonnables de la personne concernée.
								Nonobstant ce qui précède, sauf lorsque permis
								par la loi, le Centre doit demander un
								consentement explicite lorsque les
								renseignements personnels sont susceptibles
								d’être considérés comme sensibles.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>3.3 Retrait du consentement</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Une personne concernée peut retirer son
								consentement à tout moment, en donnant un
								préavis raisonnable, sous réserve de
								restrictions juridiques ou contractuelles. Le
								Centre doit informer la personne concernée des
								implications d’un tel retrait, qui pourrait
								entraîner la renonciation à certains services ou
								renseignements.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							4 – Limitation de la collecte et de l’utilisation
						</h5>
						<div className="ps-4">
							<p>
								<strong>4.1 Collecte</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le Centre ne peut recueillir que les
								renseignements personnels nécessaires aux fins
								déterminées en procédant de façon honnête et
								licite.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									4.2 Utilisation et communication
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p className="ps-3">
								<strong>4.2.1 Principe général</strong>
							</p>
							<p>
								a) Le Centre ne peut utiliser et communiquer les
								renseignements personnels qu’aux fins
								identifiées et ces fins doivent être limitées
								strictement à l’exécution des fonctions
								nécessaires du Centre.
							</p>
						</div>
						<div className="ps-5">
							<p className="ps-3">
								<strong>4.2.2 Applications du principe</strong>
							</p>
							<p>
								a) Les renseignements personnels décrits à
								l’alinéa 2.1 b) peuvent être partagés avec les
								autres parties impliquées dans le même différend
								ou la même plainte et avec leurs représentants
								autorisés durant la procédure.
							</p>
							<p>
								b) Les renseignements personnels décrits à
								l’alinéa 2.1 c) peuvent, à l’entière discrétion
								du BCIS conformément aux politiques et
								procédures du BCIS et/ou par tout professionnel
								du BCIS dans le cadre d’une enquête, ou à
								l’entière discrétion du professionnel du
								règlement des différends désigné pour statuer
								sur un dossier, être communiqués dans le rapport
								d’enquête et/ou la sentence arbitrale lorsque
								cela est nécessaire pour motiver la décision
								rendue.
							</p>
							<p>
								c) Tout renseignement personnel décrit à
								l’alinéa 2.1 c) qui est divulgué dans une
								décision du Tribunal antidopage et qui permet
								d'identifier une personne contre laquelle une
								violation des règles antidopage a été alléguée
								sera expurgé 10 ans après la date de la
								décision.
							</p>
							<p>
								d) Les renseignements personnels décrits à
								l’alinéa 2.1 d) doivent être utilisés
								strictement aux fins de la gestion des
								ressources humaines, de la gouvernance et des
								activités du Tribunal du Centre respectivement.
							</p>
							<p>
								e) Lorsque cela est possible et que cela peut
								servir aux mêmes fins, les renseignements
								personnels décrits aux alinéas 2.1 e) et 2.1 f)
								peuvent être utilisés sous forme agrégée.
							</p>
							<p>
								f) L’accès aux renseignements personnels, leur
								utilisation et leur communication doivent être
								restreints aux employés du Centre, aux
								administrateurs, aux professionnels du règlement
								des différends, aux professionnels du BCIS et
								aux tierces parties, dans les limites de ce qui
								est raisonnablement nécessaire pour s’acquitter
								de leurs tâches et responsabilités pour le
								Centre.
							</p>
							<p>
								g) Les renseignements personnels qui font
								l’objet d’une demande doivent être conservés
								aussi longtemps qu’il est nécessaire pour
								permettre à la personne concernée d’épuiser tous
								les recours à sa disposition.
							</p>
							<p>
								h) Tous les renseignements personnels recueillis
								par le Centre doivent être gérés en conformité
								avec la section 6 du présent document,{" "}
								<i>Mesures de sécurité</i>.{" "}
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<div className="ps-4">
							<p>
								<strong>4.3 Conservation</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les renseignements personnels ne doivent être
								conservés qu’aussi longtemps qu’ils demeurent
								raisonnablement nécessaires et pertinents pour
								la réalisation des fins pour lesquelles ils ont
								été recueillis.
							</p>
							<p>
								b) En règle générale, la conservation des
								renseignements personnels sensibles exige des
								raisons et des justifications plus strictes ou
								plus impérieuses que la conservation d’autres
								types de renseignements personnels.
							</p>
							<p>
								c) Les procédures de conservation, de sauvegarde
								et d’archivage des renseignements personnels
								doivent être conformes aux normes de sécurité
								énoncées à la Section 6 du présent document et à
								l’Annexe A du{" "}
								<i>
									Standard international pour la protection
									des renseignements personnels
								</i>{" "}
								publié et modifié de temps à autre par l’Agence
								mondiale antidopage.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							5 – Exactitude des renseignements
						</h5>
						<div className="ps-4">
							<p>
								a) Le Centre doit prendre des mesures
								raisonnables pour s’assurer que les
								renseignements personnels sont aussi exacts,
								complets et à jour que l’exigent les fins
								déterminées.
							</p>
							<p>
								b) Le Centre attend de chaque personne concernée
								qu’elle prenne la responsabilité de fournir des
								renseignements personnels exacts et s’assure
								qu’ils demeurent à jour en informant rapidement
								le Centre de tout changement.
							</p>
							<p>
								c) Le Centre n’est pas responsable des pertes de
								services ou avantages que pourraient subir les
								personnes concernées par leur défaut d’informer
								le Centre par écrit de tout changement dans les
								renseignements personnels versés aux dossiers à
								leur sujet.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">6 – Mesures de sécurité</h5>
						<div className="ps-4">
							<p>
								<strong>6.1 Dispositions générales </strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le Centre a mis en œuvre des mesures de
								sécurité afin de protéger les renseignements
								personnels contre la perte ou le vol, ainsi que
								contre la consultation, la communication, la
								copie, l’utilisation ou la modification non
								autorisées. Ces mesures de sécurité varient en
								fonction du degré de sensibilité des
								renseignements personnels; plus les
								renseignements sont sensibles, mieux ils doivent
								être protégés. Le Centre s’engage à maintenir
								ces mesures ou des mesures équivalentes,
								celles-ci pouvant être modifiées de temps à
								autre.
							</p>
							<p>
								b) Les méthodes de sécurité utilisées par le
								Centre comprennent, sans s’y limiter :
							</p>
							<p className="ps-4">
								i. des moyens matériels comprenant, sans s’y
								limiter, la conservation des documents contenant
								des renseignements personnels dans des coffres
								de sûreté et classeurs verrouillables, la
								restriction de l’accès aux bureaux et classeurs
								uniquement aux employés et le déchiquetage des
								dossiers papiers qui ne sont plus utilisés;
							</p>
							<p className="ps-4">
								ii. des mesures administratives comprenant, sans
								s’y limiter, la désignation d’un agent de la
								protection de la vie privée, un accès sélectif
								aux données selon les besoins, une formation
								pour sensibiliser à l’importance de protéger le
								caractère confidentiel des renseignements
								personnels et la signature d’ententes de
								confidentialité avant de donner accès à des
								renseignements personnels; et
							</p>
							<p className="ps-4">
								iii. des mesures technologiques comprenant, sans
								s’y limiter, la gestion de l’accès au serveur du
								bureau du Centre, des protocoles pour sécuriser
								la transmission des données et l’application de
								normes raisonnables de sécurité des données au
								Portail de gestion de dossiers, la suppression
								permanente des courriels après leur
								téléchargement ainsi qu’un plan de reprise après
								catastrophe.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.2 Mesures de sécurité particulières
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les renseignements personnels stockés sur le
								serveur et les ordinateurs du Centre doivent
								être protégés de la manière suivante :
							</p>
							<p className="ps-4">
								i. L’accès à des zones particulières du serveur
								est restreint au moyen de comptes d’utilisateurs
								protégés par des mots de passe individuels,
								selon le profil personnel des responsabilités et
								besoins de chaque employé; et
							</p>
							<p className="ps-4">
								ii. Des copies de sauvegarde sont stockées sur
								des appareils protégés par des mots de passe et
								conservés dans un coffre de sûreté.
							</p>
							<p>
								b) Tout transfert nécessaire de renseignements
								personnels détenus par le Centre doit être
								effectué de la manière suivante :
							</p>
							<p className="ps-4">
								i. Tous les serveurs utilisés par le Centre,
								incluant le serveur qui héberge le Portail de
								gestion de dossiers et le Portail de gestion des
								plaintes du BCIS, sont situés physiquement au
								Canada, y compris tout équipement de redondance
								des serveurs;
							</p>
							<p className="ps-4">
								ii. Le Centre ne transfère aucun renseignement
								personnel au moyen de technologies infonuagiques
								et limite dans la mesure du possible le
								transfert de renseignements personnels par
								courrier électronique;
							</p>
							<p className="ps-4">
								iii. Les courriels contenant des renseignements
								personnels doivent être marqués comme
								confidentiels et envoyés uniquement aux
								destinataires autorisés;
							</p>
							<p className="ps-4">
								iv. Lorsque des copies temporaires, sur quelque
								support que ce soit, doivent être utilisées par
								des employés qui travaillent à distance, les
								doubles sont supprimés de façon permanente dès
								qu’ils ne sont plus nécessaires.
							</p>
							<p>
								c) Les renseignements personnels stockés dans le
								Portail de gestion de dossiers et le Portail de
								gestion des plaintes du BCIS doivent être
								protégés de la manière suivante :
							</p>
							<p className="ps-4">
								i. L’accès à la zone administrative du Portail
								de gestion de dossiers et du Portail de gestion
								des plaintes du BCIS est accordé exclusivement
								aux employés et tierces parties responsables du
								soutien technique par le biais de comptes
								d’utilisateurs protégés par des mots de passe;
							</p>
							<p className="ps-4">
								ii. L’accès aux zones de dossiers particuliers
								du Portail de gestion de dossiers et du Portail
								de gestion des plaintes du BCIS est restreint
								aux employés, professionnels du règlement des
								différends, professionnels du BCIS, parties et
								leurs représentants autorisés concernés par ce
								dossier particulier, et tierces parties, par le
								biais de comptes d’utilisateurs protégés par des
								mots de passe;
							</p>
							<p className="ps-4">
								iii. La personnalisation des mots de passe du
								Portail de gestion de dossiers et du Portail de
								gestion des plaintes du BCIS est régie par des
								règles qui imposent l’utilisation de mots de
								passe robustes;
							</p>
							<p className="ps-4">
								iv. Tous les fichiers téléchargés vers le
								Portail de gestion de dossiers et le Portail de
								gestion des plaintes du BCIS sont cryptés;
							</p>
							<p className="ps-4">
								v. Les dossiers du Portail de gestion de
								dossiers sont supprimés au complet de manière
								permanente le 21e jour suivant la date à
								laquelle le Centre a déclaré le dossier réglé.
								Lorsqu’un dossier fait l’objet d’appels
								subséquents et à la demande des parties et de
								leurs représentants autorisés, la période
								ci-haut mentionnée peut être prorogée jusqu’au
								moment où la procédure subséquente est terminée.
								Pour plus de clarté, ceci ne s'applique pas aux
								dossiers et aux renseignements contenus dans le
								Portail de gestion des plaintes du BCIS, qui
								seront conservés indéfiniment.
							</p>
							<p>
								d) Le chef de la direction du Centre est la
								seule personne qui a accès aux dossiers
								personnels des employés.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.3 Éducation, formation et ententes en
									matière de protection des renseignements
									personnelss
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Tous les employés, administrateurs,
								professionnels du règlement des différends et
								professionnels du BCIS doivent prendre part à
								une séance d’orientation, qui comprend une
								formation relative à la Politique ainsi que des
								directives claires sur la collecte,
								l’utilisation et la communication des
								renseignements personnels, dans l’exécution de
								leurs tâches respectives au Centre.
							</p>
							<p>
								b) Tous les employés, administrateurs,
								professionnels du règlement des différends et
								professionnels du BCIS doivent, avant le début
								de leur emploi ou mandat pour le Centre, signer
								une forme quelconque d’entente qui les lie à la
								Politique du Centre de la manière suivante :
							</p>
							<p className="ps-4">
								i. Tous les employés sont tenus de signer un
								contrat d’emploi contenant une clause de
								confidentialité et de s’engager par écrit,
								chaque année, à se conformer aux dispositions
								pertinentes du Manuel des politiques et
								procédures relatives au milieu de travail et à
								l’emploi du Centre;
							</p>
							<p className="ps-4">
								ii. Tous les administrateurs sont tenus de
								signer, au moment de leur nomination, une
								entente de confidentialité qui précise qu’ils
								s’engagent à respecter le caractère confidentiel
								des renseignements dont ils pourraient avoir
								connaissance durant l’exercice de leurs
								fonctions, conformément aux politiques du Centre
								en matière de confidentialité; et
							</p>
							<p className="ps-4">
								iii. Tous les professionnels du règlement des
								différends et professionnels du BCIS sont tenus
								de signer, au moment de leur nomination par le
								Centre, un mémoire d’entente qui renvoie à la
								Politique et au Code de conduite des arbitres et
								médiateurs du Centre ou autres politiques et
								procédures pertinentes du BCIS (selon le cas).
							</p>
							<p>
								c) Lorsque les renseignements personnels sont
								communiqués à une tierce partie ou utilisés par
								celle-ci, le Centre doit s’assurer que cette
								tierce partie adhère aux normes de sécurité du
								Centre par des moyens contractuels.
							</p>
							<p>
								d) Les parties et leurs représentants autorisés
								sont liés par les dispositions pertinentes du{" "}
								<i>
									Code canadien de règlement des différends
									sportifs
								</i>
								, qui prévoient que ceux-ci ainsi que toutes
								autres personnes qui participent à la procédure
								en leur nom ne communiqueront à des tiers aucun
								des renseignements et documents qu’ils
								obtiennent lors de la procédure, sauf lorsque la
								loi le requiert.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									6.4 Destruction, suppression ou
									anonymisation
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les renseignements personnels doivent être
								détruits, supprimés, anonymisés de manière
								permanente ou, dans le cas de dossiers papier,
								déchiquetés, dès qu’ils ne sont plus pertinents
								ou nécessaires pour les fins de la collecte.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							7 – Accès aux renseignements personnels et
							corrections
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									7.1 Accès aux renseignements personnels et
									corrections
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								<small>Sous réserve de l’alinéa 7.4,</small>
							</p>
							<p>
								a) Toute personne a le droit d’accéder, sur
								demande, aux renseignements personnels la
								concernant;
							</p>
							<p>
								b) Le Centre doit informer la personne qui en
								fait la demande des usages qu’elle fait des
								renseignements personnels la concernant, ce qui
								inclut un relevé des tiers auxquels ils ont été
								communiqués;
							</p>
							<p>
								c) La personne concernée peut demander la
								correction de toute erreur qui peut se
								démontrer; et
							</p>
							<p>
								d) Lorsque cela est nécessaire pour la conduite
								de ses activités ou le maintien de ses services
								et avantages fournis à la personne concernée, le
								Centre doit transmettre les renseignements
								personnels modifiés aux tierces parties qui ont
								un accès autorisé.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>7.2 Identification</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Seules les demandes présentées par écrit par
								des personnes qui se sont identifiées de façon
								appropriée, ou par des représentants qui ont
								l’autorisation appropriée au nom de ces
								personnes, en vue d’obtenir les renseignements
								personnels en question peuvent être acceptées.
							</p>
							<p>
								b) Pour s’identifier de façon appropriée, le
								demandeur doit présenter deux documents
								d’identité délivrés par le gouvernement
								(passeport, permis de conduire, certificat de
								naissance, etc.), dont un au moins doit porter
								la photo du demandeur.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									7.3 Délai de réponse à la demande
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le Centre est tenu de répondre dans un délai
								maximal de 30 jours à compter de la date à
								laquelle la demande présentée par écrit par la
								personne concernée est reçue.
							</p>
							<p>
								b) Dans des circonstances raisonnables, soit
								notamment, mais pas uniquement en cas de
								demandes de renseignements volumineux, de
								demandes compliquées ou de demandes nécessitant
								de convertir les renseignements, le Centre peut
								demander une prorogation du délai au-delà du
								maximum de 30 jours. Dans de telles
								circonstances, le demandeur doit être avisé par
								écrit, avant l’expiration du délai de 30 jours,
								des raisons de la prorogation du délai et de son
								droit de présenter une plainte à l’agent de la
								protection de la vie privée au sujet de la
								prorogation.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>7.4 Refus d’une demande</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Le Centre peut refuser une demande de
								correction, en précisant pour quelles raisons,
								dans certaines circonstances limitées, soit
								notamment, mais pas uniquement, lorsque la
								personne concernée ne parvient pas à prouver que
								l’information en question est inexacte.
								Lorsqu’il est impossible de modifier un
								document, la correction doit être effectuée au
								moyen d’une note versée au dossier.
							</p>
							<p>
								b) En dépit d’un droit général d’accéder aux
								renseignements personnels sur demande, le Centre
								peut refuser une demande d’accès si les
								renseignements sont protégés par le secret
								professionnel entre l’avocat et son client, le
								privilège relatif au litige ou le privilège
								relatif au règlement à l’amiable, ou, en droit
								civil, par le secret professionnel des avocats
								et notaires;
							</p>
							<p>
								c) Le Centre peut pécher par excès de prudence
								et refuser toute demande d’accès dans certaines
								situations, soit notamment, mais pas uniquement
								lorsque :
							</p>
							<p className="ps-4">
								i. le fait de donner suite à la demande pourrait
								causer un préjudice à la personne concernée ou à
								une autre personne;
							</p>
							<p className="ps-4">
								ii. le fait de donner suite à la demande
								pourrait compromettre l’administration,
								l’enquête ou la préparation à l’arbitrage
								relativement à une plainte;
							</p>
							<p className="ps-4">
								iii. le fait de donner suite à la demande
								révélerait des renseignements personnels
								concernant une autre personne, qui ne sont pas
								séparables sans son consentement et qui ne sont
								pas nécessaires, pour éviter de causer un
								préjudice à cette autre personne; ou
							</p>
							<p className="ps-4">
								iv. il existe un doute raisonnable à propos de
								l’identification ou l’autorisation du demandeur,
								qu’il s’agisse de la personne concernée ou de la
								personne réputée être autorisée à agir au nom de
								celle-ci.
							</p>
							<p>
								d) Le Centre peut, lorsque cela est raisonnable
								et possible, donner accès aux renseignements
								personnels en version expurgée afin de ne pas
								causer de préjudice.
							</p>
							<p>
								e) Le Centre est réputé avoir refusé une demande
								d’accès lorsqu’il ne répond pas dans le délai
								prévu de 30 jours.
							</p>
						</div>
					</Col>
					<Col sm={12}>
						<h5 className="mb-3">
							8 – Possibilité de porter plainte à l’égard de la
							non-conformité
						</h5>
						<div className="ps-4">
							<p>
								<strong>
									8.1 Réception des demandes de renseignements
									et des plaintes
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Toutes les demandes de renseignements,
								craintes et plaintes doivent être transmises à
								l’agent de la protection de la vie privée dès
								leur réception.
							</p>
							<p>
								b) L’agent de la protection de la vie privée
								doit encourager, mais ne pas obliger, les
								personnes concernées à soumettre leurs demandes
								de renseignements, craintes et plaintes par
								écrit.
							</p>
							<p>
								c) Toute personne peut également déposer une
								plainte par écrit auprès de l’agent à la
								protection de la vie privée selon les lois
								applicables sur la protection des renseignements
								personnels.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									8.2 Traitement des demandes de
									renseignements et plaintes
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Lorsqu’une personne présente une demande de
								renseignements, formule une inquiétude ou dépose
								une plainte au sujet d’un possible manquement à
								l’obligation de confidentialité par :
							</p>
							<p className="ps-4">
								i. une partie, l’agent de la protection de la
								vie privée doit renvoyer la personne concernée
								aux dispositions pertinentes du{" "}
								<i>
									Code canadien de règlement des différends
									sportifs
								</i>{" "}
								sur les infractions à ce code ou aux politiques
								et procédures pertinentes du BCIS, le cas
								échéant;
							</p>
							<p className="ps-4">
								ii. un professionnel du règlement des différends
								ou un professionnel du BCIS, relativement à une
								procédure régie par le{" "}
								<i>
									Code canadien de règlement des différends
									sportifs
								</i>{" "}
								ou autrement conformément à une politique ou
								procédure du BCIS, l’agent de la protection de
								la vie privée doit renvoyer la personne
								concernée à la{" "}
								<i>Politique de traitement des plaintes</i>; et
							</p>
							<p className="ps-4">
								iii. un employé, un administrateur ou une tierce
								partie, l’agent de la protection de la vie
								privée doit renvoyer la personne concernée à la
								section 8.2 de la Politique et se conformer à
								cette section.
							</p>
							<p>
								b) À moins que l’agent de la protection de la
								vie privée ne détermine qu’il y a des raisons
								justifiant de traiter la demande de
								renseignements, la crainte ou la plainte d’une
								autre manière, le Centre se charge de faire
								enquête sur toutes les craintes et plaintes.
							</p>
							<p>
								c) L’agent de la protection de la vie privée
								effectue un premier examen de toutes craintes ou
								plaintes dans un délai raisonnable. Dans tous
								les cas, l’agent de la protection de la vie
								privée informe la personne qui a déposé la
								crainte ou la plainte de la progression de
								l’examen en indiquant la date prévue pour la fin
								de l’examen.
							</p>
							<p>
								d) Si une crainte ou une plainte n’est pas
								réglée à la satisfaction de la personne
								concernée, le Centre doit :
							</p>
							<p className="ps-4">
								i. consigner l’objet de la crainte ou plainte
								non réglée dans les dossiers pertinents au sujet
								de la personne concernée; et
							</p>
							<p className="ps-4">
								ii. lorsque cela est approprié, informer de
								l’existence de la crainte ou plainte non réglée
								toute tierce partie ayant accès aux
								renseignements personnels en question.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>
									8.3 Atteintes à la confidentialité des
									renseignements personnels
								</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Les atteintes à la confidentialité des
								renseignements personnels comprennent, sans s’y
								limiter, tout vol ou perte de renseignements
								personnels, par inadvertance ou de façon
								délibérée, toute collecte, utilisation ou
								communication non autorisée de renseignements
								personnels, toute modification ou destruction
								non autorisée de renseignements personnels, et
								tout non-respect de cette Politique.
							</p>
							<p>
								b) En cas d’atteinte à la confidentialité des
								renseignements personnels réelle ou présumée,
								l’agent de la protection de la vie privée doit
								en informer soit le président du Conseil
								d’administration soit le chef de la direction du
								Centre.
							</p>
							<p>
								c) L’agent de la protection de la vie privée a
								l’obligation de veiller, au minimum, à :
							</p>
							<p className="ps-4">
								i. empêcher d’autres préjudices, vol, perte, et
								utilisation ou communication non autorisée;
							</p>
							<p className="ps-4">
								ii. aviser rapidement toutes les personnes
								touchées ou susceptibles d’être touchées;
							</p>
							<p className="ps-4">
								iii. faire enquête sur l’atteinte à la
								confidentialité, notamment en passant en revue
								les systèmes et politiques, et les pratiques et
								procédures pertinentes; et
							</p>
							<p className="ps-4">
								iv. recommander, à sa discrétion, soit au
								président du Conseil soit au chef de la
								direction du Centre, des mesures de réparation,
								de rectification et, lorsque cela est approprié,
								de discipline.
							</p>
							<p>
								d) L’agent de la protection de la vie privée
								conserve un dossier de tous les incidents et
								avis, avec motifs à l’appui, et informe la
								personne concernée des résultats de l’enquête
								concernant la crainte ou plainte soulevée.
							</p>
						</div>
						<div className="ps-4">
							<p>
								<strong>8.4 Vérification indépendante</strong>
							</p>
						</div>
						<div className="ps-5">
							<p>
								a) Lorsque cela est jugé nécessaire, le Conseil
								d’administration du Centre peut lancer une
								vérification indépendante sur sa propre
								conformité à la Politique.
							</p>
						</div>
					</Col>
					<Col sm={12} className="mt-4 text-center">
						<h5 className="mb-3">Annexe A</h5>
					</Col>
					<Col sm={12}>
						<p>
							Le Centre recueille des renseignements personnels
							aux fins énumérées ci-après :
						</p>
						<h6>
							De et à propos de toutes les personnes concernées :
						</h6>
						<ul>
							<li>
								pour organiser des événements nécessitant leur
								participation;
							</li>
							<li>
								pour rembourser les frais admissibles engagés
								par des employés, des administrateurs ou de
								tierces parties, sous la forme de factures, de
								reçus et d’informations relatives aux
								déplacements;
							</li>
							<li>
								pour répondre aux plaintes ou demandes de
								renseignements des personnes concernées;
							</li>
							<li>
								pour recevoir, traiter, administrer, enquêter,
								faire de la médiation et statuer sur les
								plaintes liées au CCUMS, publier les décisions
								rendues conformément au CCUMS, et tenir un
								registre des renseignements pertinents
								concernant les plaintes et les décisions prises
								dans l’application du CCUMS;
							</li>
							<li>
								pour aider les personnes concernées à utiliser
								les systèmes ou services du Centre en leur
								fournissant un appui administratif ou technique;
							</li>
							<li>
								pour recueillir les opinions et commentaires des
								personnes concernées à propos des activités du
								Centre;
							</li>
							<li>
								pour assurer la sécurité physique du bureau du
								Centre, grâce à la collecte de renseignements
								personnels concernant les visiteurs et les
								clients, sous la forme d’images enregistrées par
								le système de sécurité de vidéosurveillance;
							</li>
							<li>
								pour toutes autres collectes et utilisations de
								renseignements personnels et fins pour
								lesquelles le Centre peut obtenir un
								consentement de temps à autre; et
							</li>
							<li>
								à d’autres fins lorsque la loi l’exige ou le
								permet.
							</li>
						</ul>
						<h6>
							Des personnes concernées autres que des employés :
						</h6>
						<ul>
							<li>
								dans le cadre de demandes de services de
								règlement des différends ou de prévention des
								différends du Centre présentées par ces
								personnes;
							</li>
							<li>
								dans le cadre de demandes de participation à un
								des programmes du Centre présentées par ces
								personnes;
							</li>
							<li>
								pour informer les personnes concernées des
								nouveaux programmes et services du Centre qui
								pourraient avoir un intérêt pour elles ou leur
								organisme;
							</li>
							<li>
								pour surveiller l’utilisation du Portail de
								gestion de dossiers et le Portail de gestion des
								plaintes du BCIS et détecter d’éventuelles
								tentatives d’utilisation frauduleuse;
							</li>
							<li>
								pour élaborer des rapports statistiques et les
								profils sportifs des clients.
							</li>
						</ul>
						<h6>Des employés :</h6>
						<ul>
							<li>
								pour le recrutement en vue de pourvoir des
								postes du Centre;
							</li>
							<li>
								pour l’application des politiques et procédures
								du Centre relatives à la formation, la rétention
								et l’évaluation des employés;
							</li>
							<li>
								à des fins d’encadrement, de mentorat et de
								développement professionnel;
							</li>
							<li>
								à des fins de gestion de la productivité, y
								compris pour la mise en place de mesures
								d’accommodement ou d’adaptation;
							</li>
							<li>
								et de tiers fournisseurs d’avantages sociaux, de
								régimes de retraite et d’assurance, et autres
								services aux employés, afin d’assurer
								l’indemnisation et d’autres services semblables,
								et satisfaire aux exigences en matière d’impôts
								à leur égard;
							</li>
							<li>
								afin de satisfaire aux exigences des lois
								applicables en matière d’assurance et de
								sécurité au travail incluant, sans s’y limiter,
								et aux lois en matière de santé et sécurité au
								travail; et
							</li>
							<li>
								pour aider à administrer le programme
								d’indemnisation des accidentés du travail après
								une maladie ou blessure liée au travail.
							</li>
						</ul>
					</Col>

					<Col sm={12} className="font-italic fw-bold">
						<hr />
						<h5>
							Politique de confidentialité de l’utilisateur
							Headversity
						</h5>
						<em>Dernière édition: novembre 24, 2023</em>
					</Col>
					<Col sm={12}>
						<div>
							<strong>
								Headversity.com, et ses affiliés ou sites web
								affiliés, y compris macromindmedia.com/ Respect
								Group Inc et ses sites web affiliés{" "}
							</strong>
							(“<strong>Headversity</strong>” / “
							<strong>Respect Group</strong>”, “
							<strong>nous</strong>” ou “<strong>notre</strong>”),
							respectent la vie privée des personnes (“
							<strong>vous</strong>” ou “
							<strong>Utilisateur</strong>”) qui interagissent
							avec notre site web (le “<strong>Site</strong>”) et
							utilisent les outils de formation et les programmes
							que nous vous fournissons par l'intermédiaire du
							Site (les “<strong>Services</strong>”). La présente
							politique de confidentialité s'applique aux
							informations qui permettent de vous identifier
							directement ou indirectement (“
							<strong>informations personnelles</strong>”) et que
							vous nous fournissez lorsque vous utilisez notre
							site et nos services, et lorsque vous accédez,
							téléchargez, achetez ou utilisez des informations,
							des services, des produits ou du contenu figurant
							sur le site.
						</div>
					</Col>
					<Col sm={12}>
						Notre directeur de la technologie (“<strong>CTO</strong>
						”) est responsable du respect de la législation
						applicable en matière de protection des données
						personnelles.
					</Col>
					<Col sm={12}>
						Notre CTO est joignable à partir des coordonnées
						suivantes :
					</Col>
					<Col sm={12}>
						<a href="mailto:Russ@headversity.com" tabIndex={-1}>
							Russ@headversity.com
						</a>
					</Col>
					<Col sm={12}>
						Notre responsable de la protection de la vie privée (“
						<strong>CPO</strong>”) est chargé de veiller au respect
						de la législation applicable en matière de protection
						des données personnelles.
					</Col>
					<Col sm={12}>
						Notre CPO est accessible à partir des coordonnées
						suivantes :
					</Col>
					<Col sm={12}>Par courrier électronique :</Col>
					<Col sm={12}>
						<a
							href="mailto:PrivacyOfficer@headversity.com"
							tabIndex={-1}
						>
							PrivacyOfficer@headversity.com
						</a>
					</Col>
					<Col sm={12}>Par courier :</Col>
					<Col sm={12}>
						<em>
							Respect Group Inc.
							<br />
							340, 12th Ave SW,
							<br />
							Calgary, AB <br />
							T2R 1L5
							<br />
						</em>
					</Col>
					<Col sm={12}>
						La présente politique de confidentialité peut être mise
						à jour de temps à autre. Nous vous informerons de toute
						modification importante en publiant la nouvelle
						politique de confidentialité sur le site. Votre
						interaction avec notre site et l'utilisation de ses
						services constituent votre consentement et votre
						acceptation des termes et conditions de la présente
						politique de confidentialité.
					</Col>
					<Col sm={12}>
						Dans la présente politique, les termes "utilisation" et
						"traitement" des informations comprennent l'utilisation
						de témoins sur un ordinateur, la soumission des
						informations à des analyses statistiques ou autres et
						l'utilisation ou le traitement des informations de
						quelque manière que ce soit, y compris, mais sans s'y
						limiter, la collecte, le stockage, l'évaluation, la
						modification, la suppression, la combinaison, la
						divulgation et le transfert d'informations au sein de
						notre organisation ou parmi nos affiliés au Canada ou à
						l'échelle internationale.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							COLLECTE ET UTILISATION D'INFORMATIONS PERSONNELLES
						</h6>
					</Col>
					<Col sm={12}>
						Informations personnelles que vous partagez avec nous
					</Col>
					<Col sm={12}>
						Lorsque vous vous inscrivez pour devenir membre du site
						ou l'utiliser, vous nous fournissez des informations
						personnelles, notamment :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								<u>Coordonnées </u>: votre nom, votre numéro de
								téléphone et votre adresse électronique.
							</li>
							<li className={`${styles.li}`}>
								<u>Informations sur le profil </u>: votre nom et
								votre photo.
							</li>
							<li className={`${styles.li}`}>
								<u>Informations sur le compte d'utilisateur </u>
								: votre mot de passe, votre nom d'utilisateur,
								la question de sécurité et la réponse pour la
								récupération du nom d'utilisateur, du mot de
								passe et du certificat.
							</li>
							<li className={`${styles.li}`}>
								<u>
									Informations d'enregistrement
									supplémentaires demandées par le client{" "}
								</u>
								: date de naissance, numéro de téléphone ou
								autres informations personnelles pour faciliter
								la mise en correspondance de votre profil dans
								notre base de données avec celui de la base de
								données de votre organisation.
							</li>
							<li className={`${styles.li}`}>
								<u>Autres </u>: toute information que vous
								partagez en ligne en réponse aux questions
								ouvertes auxquelles vous répondez lorsque vous
								participez à notre programme.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Vos données personnelles sont utilisées aux fins
						suivantes :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								pour établir et maintenir notre relation
								commerciale avec vous. Il s'agit notamment de
								gérer les comptes d'utilisateurs, de traiter les
								nouveaux comptes et de remplir des obligations
								contractuelles et légales telles que
								l'enregistrement des utilisateurs,
								l'identification des utilisateurs avec leurs
								organisations respectives ou la vérification du
								statut de l'utilisateur;
							</li>
							<li className={`${styles.li}`}>
								développer et gérer nos activités et nos
								opérations. Cela peut inclure le partage
								d'informations personnelles par et entre notre
								personnel;
							</li>
							<li className={`${styles.li}`}>
								détecter les erreurs et nous protéger, ainsi que
								d'autres tiers, contre la fraude, le vol et
								d'autres activités illégales, et vérifier le
								respect de nos politiques et de nos obligations
								contractuelles et légales;
							</li>
							<li className={`${styles.li}`}>
								pour vous fournir d'autres informations et
								offres de notre part ou de la part de tiers que
								nous pensons pouvoir vous être utiles ou
								intéressantes, y compris des bulletins
								d'information, du matériel marketing ou
								promotionnel et d'autres informations sur des
								services connexes. Si vous décidez à tout moment
								de ne plus recevoir ces communications, veuillez
								suivre les instructions de désabonnement
								fournies dans les communications. En outre, vous
								pouvez à tout moment modifier facilement vos
								paramètres de confidentialité et de courrier
								électronique dans les paramètres de votre
								compte;
							</li>
							<li className={`${styles.li}`}>
								de s'engager dans une transaction commerciale, y
								compris l'achat, la vente, la location, la
								fusion ou tout autre type d'acquisition, de
								cession, de titrisation ou de financement nous
								concernant;
							</li>
							<li className={`${styles.li}`}>
								pour comprendre et répondre aux besoins et aux
								préférences des utilisateurs, des fournisseurs
								et d'autres tiers, y compris pour contacter ces
								parties et communiquer avec elles et pour mener
								des enquêtes, des recherches et des évaluations;
							</li>
							<li className={`${styles.li}`}>
								développer, améliorer, commercialiser, vendre ou
								fournir nos produits et services;
							</li>
							<li className={`${styles.li}`}>
								pour se conformer à toute exigence ou
								disposition légale ou réglementaire; et
							</li>
							<li className={`${styles.li}`}>
								à toute autre fin à laquelle vous consentez.
							</li>
						</ol>
					</Col>
					<Col sm={12}>Données du registre</Col>
					<Col sm={12}>
						Lorsque vous interagissez avec le site ou que vous
						l'utilisez pour accéder à nos services, nous pouvons
						également collecter les informations personnelles
						suivantes car nos serveurs enregistrent automatiquement
						les informations que le navigateur envoie chaque fois
						qu'il pointe vers un site ou un service en ligne
						("données de registre"). Les données du registre peuvent
						inclure, mais ne sont pas limitées à :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								l'adresse du protocole Internet de votre
								ordinateur,
							</li>
							<li className={`${styles.li}`}>
								votre type de navigateur,
							</li>
							<li className={`${styles.li}`}>
								le localisateur uniforme de ressource (“
								<strong>URL</strong>”) du site web que vous avez
								visité avant et/ou après avoir interagi avec
								notre site et les informations que vous
								recherchez sur un site.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Vos données de connexion sont utilisées aux fins
						suivantes :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								contrôler et analyser l'utilisation du site et
								des services;
							</li>
							<li className={`${styles.li}`}>
								administrer techniquement le site et les
								services afin d'en améliorer la fonctionnalité
								et la convivialité et de mieux les adapter aux
								besoins de nos utilisateurs. Par exemple,
								certaines de ces informations sont collectées
								pour que, lorsque vous visitez à nouveau le
								site, celui-ci vous reconnaisse et vous propose
								des informations adaptées à vos centres
								d'intérêt;
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Comme de nombreux sites web, nous utilisons des
						"témoins" pour collecter vos données de connexion. Un
						témoin est un petit fichier de données que nous
						transférons sur le disque dur de votre ordinateur à des
						fins d'archivage. Nous utilisons des "témoins
						persistants" pour enregistrer votre nom d'utilisateur et
						votre mot de passe de connexion pour les connexions
						ultérieures au site. Nous utilisons des "témoins
						d'identification de session" pour activer certaines
						fonctions de notre service, pour mieux comprendre
						comment vous interagissez avec le site et pour
						surveiller l'acheminement du trafic web sur le site et
						l'utilisation globale du site. Vous pouvez demander à
						votre navigateur, en modifiant ses options, de ne plus
						accepter les témoins ou de vous demander d'accepter un
						témoin provenant des sites web que vous visitez.
						Toutefois, si vous n'acceptez pas les témoins, il se
						peut que vous ne puissiez pas utiliser toutes les
						parties du site ou toutes les fonctionnalités des
						services.
					</Col>
					<Col sm={12}>Inviter un ami</Col>
					<Col sm={12}>
						Si vous choisissez d'inviter vos amis à utiliser nos
						services, en utilisant nos options d'invitation, nous
						pouvons vous demander des informations telles qu'une
						adresse électronique. Nous enverrons automatiquement à
						votre/vos ami(s) une invitation unique par courrier
						électronique à visiter le site. Nous stockons ces
						informations pour envoyer cette invitation unique et
						pour suivre le succès de cette fonction de
						recommandation.
					</Col>
					<Col sm={12}>
						<strong>
							Veuillez noter qu'en fonction des services que nous
							fournissons, votre organisation peut nous demander
							de collecter d'autres informations personnelles que
							celles énumérées ci-dessus. Si vous pensez que c'est
							le cas et que vous souhaitez savoir quelles sont ces
							informations personnelles et comment elles seront
							utilisées, nous vous invitons à contacter notre
							CTO/CPO aux coordonnées indiquées ci-dessus.
						</strong>
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							PARTAGE ET DIVULGATION D'INFORMATIONS
						</h6>
					</Col>
					<Col sm={12}>
						Nous ne divulguerons à aucun tiers non autorisé le nom
						ou les coordonnées d'un utilisateur. Nous ne
						surveillerons pas, ne modifierons pas et ne divulguerons
						pas le contenu des informations d'un utilisateur, sauf
						si la loi nous y oblige ou si nous pensons de bonne foi
						qu'une telle action est nécessaire. Dans ce cas, les
						personnes à qui nous pourrions divulguer vos
						informations personnelles sont les suivantes :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								une personne qui, selon notre jugement
								raisonnable, fournit ou recherche l'information
								en tant que votre agent;
							</li>
							<li className={`${styles.li}`}>
								l'organisation qui vous a demandé de suivre l'un
								de nos programmes, l'organe directeur de
								l'organisation ou ses parents;
							</li>
							<li className={`${styles.li}`}>
								toute société ou entité commerciale faisant
								partie de notre organisation;
							</li>
							<li className={`${styles.li}`}>
								une organisation ou une personne dont nous avons
								retenu les services pour exécuter des fonctions
								en notre nom, telles que des sous-traitants, des
								consultants, des auditeurs, des développeurs de
								logiciels (y compris des développeurs et des
								hébergeurs de sites web), des services de
								traitement de données, de gestion de documents
								et de bureau;
							</li>
							<li className={`${styles.li}`}>
								nos auditeurs et conseillers professionnels;
							</li>
							<li className={`${styles.li}`}>
								une autre personne ou société dans le cadre
								d'une transaction commerciale telle que définie
								dans la législation applicable en matière de
								protection des données à caractère personnel;
							</li>
							<li className={`${styles.li}`}>
								une autre société ou personne pour le
								développement, l'amélioration, la
								commercialisation ou la fourniture de l'un de
								nos produits ou services;
							</li>
							<li className={`${styles.li}`}>
								un agent ou un tiers engagé par nous dans le
								cadre de notre administration ou de la
								fourniture de nos produits ou services;
							</li>
							<li className={`${styles.li}`}>
								les autorités publiques compétentes lorsqu'une
								telle divulgation est requise en vertu des lois
								applicables;
							</li>
							<li className={`${styles.li}`}>
								une autorité publique ou un agent d'une autorité
								publique, si, selon notre jugement raisonnable,
								il apparaît qu'il existe un danger imminent pour
								la vie ou la propriété qui pourrait être évité
								ou minimisé par la divulgation de ces
								informations;
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						<strong>Veuillez noter</strong> que certaines
						informations de profil limitées que vous soumettez au
						site peuvent également être accessibles à d'autres
						utilisateurs qui effectuent des recherches sur notre
						site à l'aide d'outils spécifiques créés par nous pour
						montrer l'accréditation ou la certification dans l'un de
						nos programmes. Ces outils ne fourniront une
						confirmation de l'accréditation ou de la certification
						des programmes que si certaines informations limitées
						sont fournies : prénom, nom de famille, courriel.
					</Col>
					<Col sm={12}>
						Informations globales et informations non identifiantes.
						Nous pouvons partager des informations agrégées qui
						comprennent des informations non identifiantes et des
						données de registre avec des tiers à des fins d'analyse
						industrielle, de reconnaissance démographique et pour
						fournir des programmes ciblés sur d'autres produits et
						services.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS?
						</h6>
					</Col>
					<Col sm={12}>
						Nous ne conserverons pas vos informations personnelles
						plus longtemps que nécessaire pour atteindre l'objectif
						pour lequel elles ont été collectées, y compris pour
						satisfaire à toute exigence légale, comptable ou de
						rapport et, lorsque cela est nécessaire pour que nous
						puissions faire valoir ou nous défendre contre des
						réclamations légales, jusqu'à la fin de la période de
						conservation pertinente ou jusqu'à ce que les
						réclamations en question aient été réglées, après quoi
						nous ferons de notre mieux pour les détruire ou les
						rendre anonymes. Étant donné que cette finalité varie en
						fonction des informations personnelles concernées, nous
						vous invitons à contacter notre CTO pour toute question
						vous concernant spécifiquement.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							L'EXACTITUDE DE VOS INFORMATIONS
						</h6>
					</Col>
					<Col sm={12}>
						Nous déployons des efforts raisonnables pour veiller à
						ce que vos informations personnelles soient aussi
						exactes, complètes et à jour que possible. Nous ne
						mettons pas systématiquement à jour vos informations
						personnelles, à moins qu'une telle mise à jour ne soit
						nécessaire. Pour nous aider à maintenir et à garantir
						l'exactitude et la mise à jour de vos informations
						personnelles, vous devez nous informer sans délai de
						tout changement dans les informations personnelles que
						vous nous fournissez.
					</Col>
					<Col sm={12}>
						En outre, tous les membres peuvent consulter, mettre à
						jour, corriger ou supprimer les informations
						personnelles figurant dans leur profil d'inscription en
						se connectant à leur compte et en modifiant les
						"préférences de l'utilisateur" associées à leur compte.
						Si vous supprimez complètement ces informations
						personnelles, votre compte peut être désactivé. Nous
						pouvons conserver une copie archivée de vos dossiers si
						la loi l'exige ou à des fins commerciales légitimes.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">SÉCURITÉ</h6>
					</Col>
					<Col sm={12}>
						Nous employons des mesures administratives, physiques et
						électroniques conçues pour sauvegarder et protéger vos
						informations personnelles contre tout accès et toute
						divulgation non autorisée, y compris le cryptage de vos
						communications à l'aide du logiciel Secure Sockets Layer
						(“<strong>SSL</strong>”).
					</Col>
					<Col sm={12}>
						Nous vous informerons de toute violation de la sécurité,
						de la confidentialité ou de l'intégrité de vos
						informations personnelles non cryptées stockées
						électroniquement, par courrier électronique ou par
						affichage visible sur ce site, le plus rapidement
						possible et sans retard déraisonnable, dans la mesure où
						cela est compatible avec (i) les besoins légitimes des
						forces de l'ordre ou (ii) toute mesure nécessaire pour
						déterminer l'ampleur de la violation et restaurer
						l'intégrité raisonnable du système de données.
					</Col>
					<Col sm={12}>
						Vous publiez et partagez vos informations personnelles
						et votre contenu sur le site à vos propres risques. Bien
						que nous nous efforcions de créer une expérience sûre et
						agréable sur le site, nous ne pouvons pas contrôler les
						actions des autres utilisateurs avec lesquels vous
						pouvez choisir de partager votre contenu et/ou vos
						informations de profil. En outre, nous ne pouvons pas
						anticiper la capacité des autres utilisateurs à
						contourner les paramètres de confidentialité. Vous
						comprenez que, même après la suppression de vos
						informations personnelles et de votre contenu du site,
						des copies de vos informations peuvent rester visibles
						dans les pages Web mises en cache ou archivées ou sur
						les ordinateurs locaux des utilisateurs qui peuvent
						avoir téléchargé ou stocké vos informations.
					</Col>
					<Col sm={12}>
						Malgré toutes les précautions que nous prenons pour
						protéger vos informations, nous vous recommandons de ne
						pas envoyer d'informations privées ou d'informations
						personnelles par courrier électronique, par chat ou par
						d'autres services de messagerie.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							TRANSFERT INTERNATIONAL
						</h6>
					</Col>
					<Col sm={12}>
						Vos informations personnelles peuvent être transférées
						et conservées sur des ordinateurs situés en dehors de
						votre État, province, pays ou autre juridiction
						gouvernementale où les lois sur la protection de la vie
						privée peuvent ne pas être aussi protectrices que celles
						de votre juridiction. Si vous êtes situé en dehors du
						Canada et que vous choisissez de nous fournir des
						informations, sachez que nous transférons les
						informations personnelles au Canada et que nous les
						traitons dans ce pays. En acceptant la présente
						politique de protection de la vie privée et en nous
						communiquant ces informations, vous acceptez ce
						transfert.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">
							LIENS VERS D'AUTRES SITES
						</h6>
					</Col>
					<Col sm={12}>
						Le site et les services peuvent contenir des liens vers
						d'autres sites web, tels que le PAE d'une organisation
						ou d'autres ressources de soutien fournies par un
						employeur ou une association membre. Si vous choisissez
						de cliquer sur le lien d'un tiers, vous serez dirigé
						vers le site web de ce tiers. Le fait que nous
						établissions un lien vers un site web ne signifie pas
						que nous sommes affiliés à ce tiers, ni que nous
						approuvons ses politiques ou pratiques en matière de
						protection de la vie privée ou de sécurité des
						informations. Nous n'exerçons aucun contrôle sur les
						sites web de tiers. Ces autres sites web peuvent placer
						leurs propres témoins ou autres fichiers sur votre
						ordinateur, collecter des données ou vous demander des
						informations personnelles. D'autres sites web et
						services suivent des règles différentes concernant
						l'utilisation ou la divulgation des informations
						personnelles que vous leur soumettez. Nous vous
						encourageons à lire les politiques ou déclarations de
						confidentialité des autres sites web que vous visitez.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">VOS DROITS</h6>
					</Col>
					<Col sm={12}>
						En fonction de la juridiction dans laquelle vous vivez,
						vous pouvez disposer de tout ou partie des droits
						suivants en ce qui concerne vos informations
						personnelles :
					</Col>
					<Col sm={12}>
						<ol type="i" className="ms-5 mb-0">
							<li className={`${styles.li}`}>
								L'accès : Vous avez le droit de demander si nous
								détenons des informations personnelles vous
								concernant et d'en demander une copie.
							</li>
							<li className={`${styles.li}`}>
								Exactitude : Nous nous efforçons de maintenir
								vos informations personnelles exactes, à jour et
								complètes. Nous vous encourageons à nous
								contacter pour nous faire savoir si des
								informations personnelles ne sont pas exactes ou
								si elles changent, afin que nous puissions les
								mettre à jour. Vous pouvez également apporter
								des modifications à vos informations
								personnelles dans la section "préférences de
								l'utilisateur" du site.
							</li>
							<li className={`${styles.li}`}>
								Retirer son consentement : Si vous avez donné
								votre consentement au traitement de vos
								informations personnelles, vous avez le droit de
								retirer totalement ou partiellement votre
								consentement. Une fois que nous aurons reçu la
								notification du retrait de votre consentement,
								nous ne traiterons plus vos informations aux
								fins auxquelles vous avez initialement consenti,
								à moins qu'il n'existe un autre motif légal pour
								le traitement. Le retrait de votre consentement
								à recevoir des communications marketing
								n'affectera pas le traitement des informations
								personnelles pour la fourniture de nos services.
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									Cessation de la diffusion : Vous avez le
									droit de demander que nous cessions de
									diffuser vos informations personnelles si
									cette diffusion est contraire à la loi ou à
									une décision de justice.
								</p>
								<p className={`${styles.p}`}>
									Vous avez également le droit de nous
									demander de cesser de diffuser vos
									informations personnelles si les conditions
									suivantes sont remplies :
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										la diffusion de l'information vous cause
										un préjudice grave par rapport à votre
										droit au respect de votre réputation ou
										de votre vie privée;
									</li>
									<li className={`${styles.li}`}>
										le préjudice est manifestement supérieur
										à l'intérêt du public à connaître
										l'information ou à l'intérêt du droit de
										toute personne à s'exprimer librement;
										et
									</li>
									<li className={`${styles.li}`}>
										la cessation de la diffusion demandée
										n'excède pas ce qui est nécessaire pour
										éviter la perpétuation du préjudice.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									Désindexation : Vous avez le droit de nous
									demander de désindexer tout lien hypertexte
									attaché à votre nom qui permet d'accéder à
									des informations par un moyen technologique
									si la diffusion est contraire à la loi ou à
									une décision de justice.
								</p>
								<p className={`${styles.p}`}>
									Vous avez également le droit de demander la
									désindexation d'un lien donnant accès à des
									informations lorsque les conditions
									suivantes sont remplies :
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										la diffusion de l'information vous cause
										un préjudice grave par rapport à votre
										droit au respect de votre réputation ou
										de votre vie privée;
									</li>
									<li className={`${styles.li}`}>
										le préjudice est manifestement supérieur
										à l'intérêt du public à connaître
										l'information ou à l'intérêt du droit de
										toute personne à s'exprimer librement;
										et{" "}
									</li>
									<li className={`${styles.li}`}>
										la cessation de la diffusion demandée
										n'excède pas ce qui est nécessaire pour
										éviter la perpétuation du préjudice.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								<p className={`${styles.p}`}>
									Réindexation : Vous avez également le droit
									de demander la réindexation d'un lien
									donnant accès à des informations lorsque les
									conditions suivantes sont remplies :{" "}
								</p>
								<ol type="A" className="ms-3 mb-0">
									<li className={`${styles.li}`}>
										un manquement à cette obligation vous
										cause un préjudice grave en ce qui
										concerne votre droit au respect de votre
										réputation ou de votre vie privée;
									</li>
									<li className={`${styles.li}`}>
										le préjudice causé par le défaut de
										réindexation est plus important que
										l'intérêt du public à connaître
										l'information ou que l'intérêt du droit
										de toute personne à s'exprimer
										librement; et
									</li>
									<li className={`${styles.li}`}>
										la réindexation demandée n'excède pas ce
										qui est nécessaire pour éviter la
										perpétuation du préjudice.
									</li>
								</ol>
							</li>
							<li className={`${styles.li}`}>
								Mobilité : Vous avez le droit de demander que
								les informations personnelles informatisées
								recueillies auprès de vous vous soient
								communiquées dans un format technologique
								communément utilisé ainsi qu'à toute personne ou
								organisme autorisé par la loi à recueillir ces
								informations. Ce droit ne s'étend pas aux
								informations créées ou déduites de vos
								informations personnelles et nous ne sommes pas
								obligés de communiquer ces informations si cela
								soulève de sérieuses difficultés pratiques.
							</li>
							<li className={`${styles.li}`}>
								Réclamations : Si vous estimez que vos droits en
								matière de protection des informations
								personnelles ont été violés, vous avez le droit
								de déposer une plainte auprès de l'autorité de
								contrôle compétente ou d'introduire un recours
								devant les tribunaux.
							</li>
						</ol>
					</Col>
					<Col sm={12}>
						Vous pouvez, à tout moment, vous renseigner sur les
						droits susmentionnés ou les exercer en contactant le
						CTO/CPO.
					</Col>
					<Col sm={12}>
						Nous répondrons généralement à toutes les demandes
						d'accès dans les 30 jours suivant la réception de toutes
						les informations nécessaires. Si nous ne sommes pas en
						mesure de fournir l'accès ou si un délai supplémentaire
						est nécessaire pour répondre à une demande, nous vous en
						informerons par écrit.
					</Col>
					<Col sm={12}>
						Nous nous réservons le droit de ne pas divulguer
						certains types d'informations sur la base d'exemptions
						spécifiées dans les lois applicables. Dans la mesure du
						possible, nous prélèverons les informations qui ne
						seront pas divulguées et vous donnerons accès aux
						informations restantes. Si nous ne sommes pas en mesure
						de vous donner accès à des informations personnelles ou
						de vous les divulguer, nous vous fournirons une
						explication, sous réserve de restrictions.
					</Col>
					<Col sm={12}>
						<h6 className="fw-bold mb-0">CONTACTEZ-NOUS</h6>
					</Col>
					<Col sm={12}>
						Si vous avez des questions concernant cette politique de
						confidentialité, vous pouvez nous contacter à l'adresse
						suivante : CTO/CPO{" "}
						<a href="mailto:info@macromindmedia.com" tabIndex={-1}>
							info@macromindmedia.com
						</a>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default PrivacyPolicy;
