
import MailedInConsentModal from "components/MailedInConsentModal";
import { saveMailedIn, updateById } from "features/user/userAPI";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";

function MailedInConsentReceived({ label, fieldLabel, onBlur, checked, disabled=false, formik, userID, submitSuccessCount, setSubmitSuccessCount }) {

    const [showMailedInConsentModal, setShowMailedInConsentModal] = useState(false);
    const [isDisabled, setIsDisabled] = useState(disabled)

    const handleMailedInConsentModalClose = () => setShowMailedInConsentModal(false);
    const handleMailedInConsentModalShow = () => setShowMailedInConsentModal(true);

    const acceptMailedInConsent = async () => {
        setShowMailedInConsentModal(false);
        setIsDisabled(true);

        const fullName = formik.values.guardianEmail ? (formik.values.guardianFirstName  + " " + formik.values.guardianLastName) : (formik.values.participantFirstName + " " + formik.values.participantLastName);
        const lastProgress = "mailed_in";
        await updateById({ last_progress: lastProgress, submitted_manually: 1 }, userID);
        await saveMailedIn(userID, fullName, lastProgress);

        setSubmitSuccessCount(submitSuccessCount + 1);
    }

    const declineMailedInConsent = () => {
        setShowMailedInConsentModal(false);
        formik.setFieldValue('mailedInConsentRadio', false)
    }

    return (
		<>
            <MailedInConsentModal
				show={showMailedInConsentModal}
				onHide={handleMailedInConsentModalClose}
				backdrop="static"
				keyboard={false}
                onDecline={declineMailedInConsent}
				onAccept={acceptMailedInConsent}
			/>

            <label className="">
                {label}
            </label>
			<InputGroup key="inline-radio" className="mt-2 d-flex d-block bg-white border rounded mt-1 p-2">
                <Form.Check
                    inline
                    id="mailedInConsentRadio"
                    name="mailedInConsentRadio"
                    type="switch"
                    onChange={e => {
                        formik.setFieldValue('mailedInConsentRadio', e.target.checked)
                        if (e.target.checked == true) {
                            handleMailedInConsentModalShow();
                        }
                    }}
                    onBlur={onBlur}
                    checked={checked}
                    disabled={isDisabled}
                    label={fieldLabel}
                />
            </InputGroup>
            {formik.touched.mailedInConsentRadio && formik.errors.mailedInConsentRadio && (
                <div type="invalid" className="invalid-feedback-select">{formik.errors.mailedInConsentRadio}</div>
            )}
		</>
	);
}

export default MailedInConsentReceived;