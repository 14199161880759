import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	user: null,
	profile: null,
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUser: (state, action) => {
			if (action.payload === null) {
				state.user = null;
			} else {
				state.user = {
					username: action.payload.username,
					firstName: action.payload.first_name || action.payload.firstName,
					language: action.payload.language,
					adminRoles: action.payload.admin_roles || action.payload.adminRoles,
					lastLogin: action.payload.last_login || action.payload.lastLogin,
					lastProgress: action.payload.last_progress || action.payload.lastProgress,
				};
			}
		},
		setProfile: (state, action) => {
			if (action.payload === null) {
				state.profile = null;
			} else {
				state.profile = action.payload;
			}
		},
	},
});

export const { setUser, setProfile } = userSlice.actions;

export const selectUser = (state) => state.user.user;

export const selectProfile = (state) => state.user.profile;

export default userSlice.reducer;
