import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { AdminLevelOptions } from "features/user/userOptions";
import styles from "./ReportAdminLevel.module.css";

function ReportAdminLevel({ onAdminLevelSelect }) {
	const { i18n } = useTranslation();
	const [state, setState] = useState({
		selectedAdminLevel: null,
		selectedAdminLevelIndex: -1,
		adminLevelOptions: [],
	});

	useEffect(() => {
		const options = AdminLevelOptions.map(({ optionKey, translationKey }) => ({
			value: optionKey,
			label: i18n.t(translationKey),
		}));

		setState((prevState) => ({
			...prevState,
			adminLevelOptions: options,
			selectedAdminLevel:
				prevState.selectedAdminLevelIndex !== -1 ? options[prevState.selectedAdminLevelIndex] : null,
		}));
	}, [i18n.language]);

	const handleSelectAdminLevel = (selectedOption) => {
		const index = selectedOption
			? state.adminLevelOptions.findIndex((option) => option.value === selectedOption.value)
			: -1;

		setState({
			...state,
			selectedAdminLevelIndex: index,
			selectedAdminLevel: selectedOption,
		});

		onAdminLevelSelect(selectedOption ? selectedOption.value : null);
	};

	return (
		<Select
			value={state.selectedAdminLevel}
			onChange={handleSelectAdminLevel}
			options={state.adminLevelOptions}
			placeholder={i18n.t("admin-enter-admin-level")}
			isClearable={true}
			className={`${styles.reportAdminLevel}`}
			classNamePrefix="select"
			name="admin_level"
			id="admin_level"
		/>
	);
}

export default ReportAdminLevel;
