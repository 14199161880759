import moment from "moment";

// Check if user has any consent forms signed
export function userHasSigned(consents) {
	if (consents.length == 0) return false;
	else return true;
}

// Check if user is age 19 or older
export function isUserAgeOfConsent(dateOfBirth) {
	let ageInYears = moment().diff(dateOfBirth, "years");
	if (ageInYears < 19) {
		return false;
	}

	return true;
}

// Check if user has impediments
export function userHasImpediments(hasImpedimentsRadio) {
	const value = hasImpedimentsRadio;
	if (value == 0) return false;
	if (value == 1) return true;
}

// Announce to screen reader function
export function srAnnounce(message, assertive) {
	if (typeof assertive !== "boolean") assertive = false;
	if (message !== "") {
		// strip i18n replacement tags from message
		message = message.replace(/<\d+>/g, "").replace(/<\/\d+>/g, "");
		// strip HTML tags from message
		const doc = new DOMParser().parseFromString(message, "text/html");
		message = doc.body.textContent;
	}

	// Clear any previous timeout if it exists
	if (window.srAnnounceTimeout) {
		clearTimeout(window.srAnnounceTimeout);
	}

	// Set a timeout to update the announcement (fix for JAWS not announcing with initial page load)
	window.srAnnounceTimeout = setTimeout(() => {
		// get hidden aria-live element (defined in index.html)
		const elem = document.getElementById("srAnnounce");
		// fix for firefox not respect aria-atomic="true" property with JAWS
		if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1)
			elem.removeAttribute("aria-atomic");
		// if called with "assertive", update aria-live attrib
		if (assertive) {
			elem.setAttribute("role", "status");
			elem.setAttribute("aria-live", "assertive");
		}
		// update hidden aria-live element with message to annouce to screen reader
		elem.innerText = message;
		// Clear message after a brief delay
		setTimeout(() => {
			// if called with "assertive", update aria-live attrib
			if (assertive) {
				elem.setAttribute("aria-live", "polite");
			}
			elem.innerText = "";
		}, 200);
	}, 200);
}
