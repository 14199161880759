import React from "react";
import { searchSignatoriesReport } from "features/sports/sportsAPI";
import { useDataTable } from "hooks/useDataTable";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReportTable from "../ReportTable/ReportTable";
import styles from "./SignatoriesReport.module.css";

function SignatoriesReport() {
	const { i18n } = useTranslation();

	const initialFilters = {
		uuid: "",
		name: "",
		parent_name: "",
		sorting: [{ field: "name", order: "asc" }],
		page: 1,
		per_page: 50,
	};

	const {
		filters,
		setFilters,
		reportData,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
		handleSearch,
		hasFetched,
		isLoading,
		handleExport,
	} = useDataTable(initialFilters, searchSignatoriesReport);

	const columns = [
		{
			name: <div>{i18n.t("report-type")}</div>,
			exportKey: "report-type",
			selector: (row) => row.report_type,
			sortable: true,
			sortField: "report_type",
			cell: (row) => <div>{i18n.t(row.report_type)}</div>,
		},
		{
			name: <div>{i18n.t("uuid")}</div>,
			exportKey: "uuid",
			selector: (row) => row.uuid,
			sortable: true,
			sortField: "uuid",
			cell: (row) => <div>{row.uuid}</div>,
		},
		{
			name: <div>{i18n.t("sport-organization-name")}</div>,
			exportKey: "sport-organization-name",
			selector: (row) => row.name,
			sortable: true,
			sortField: "name",
			cell: (row) => <div>{row.name}</div>,
		},
		{
			name: <div>{i18n.t("parent-sport-organization-name")}</div>,
			exportKey: "parent-sport-organization-name",
			selector: (row) => row.parent_name,
			sortable: true,
			sortField: "parent_name",
			cell: (row) => <div>{row.parent_name}</div>,
		},
		{
			name: <div>{i18n.t("webhook-url")}</div>,
			exportKey: "webhook-url",
			selector: (row) => row.webhook_url,
			sortable: true,
			sortField: "webhook_url",
			cell: (row) => <div>{row.webhook_url}</div>,
		},
		{
			name: <div>{i18n.t("webhook-secrete")}</div>,
			exportKey: "webhook-secrete",
			selector: (row) => row.webhook_secrete,
			sortable: true,
			sortField: "webhook_secrete",
			cell: (row) => <div>{row.webhook_secrete}</div>,
		},
	];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container fluid>
			<h5 className="fw-normal">{i18n.t("signatories-report")}</h5>
			<div className="fw-normal text-muted mb-4">{i18n.t("admin-signatories-report-description")}</div>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					handleSearch();
				}}
			>
				<Row className="form-group bg-light border rounded mb-4 p-3">
					<h6 className="fw-normal mb-4">{i18n.t("report-filters")}</h6>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="uuid">{i18n.t("uuid")}</label>
						<Form.Control
							type="text"
							name="uuid"
							id="uuid"
							placeholder={i18n.t("admin-enter-uuid")}
							value={filters.uuid}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="sport-org-name">{i18n.t("sport-organization-name")}</label>
						<Form.Control
							type="text"
							name="name"
							id="sport-org-name"
							placeholder={i18n.t("admin-enter-sport-org")}
							value={filters.name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="parent-sport-org-name">{i18n.t("parent-sport-organization-name")}</label>
						<Form.Control
							type="text"
							name="parent_name"
							id="parent-sport-org-name"
							placeholder={i18n.t("admin-enter-parent-sport-org")}
							value={filters.parent_name}
							onChange={handleInputChange}
						/>
					</Col>
					<ReportTable
						columns={columns}
						handleSearch={handleSearch}
						handleExport={handleExport}
						reportData={reportData}
						handleSort={handleSort}
						handlePageChange={handlePageChange}
						handleRowsPerPageChange={handleRowsPerPageChange}
						isLoading={isLoading}
						hasFetched={hasFetched}
					/>
				</Row>
			</Form>
		</Container>
	);
}

export default SignatoriesReport;
