import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "features/user/userSlice.js";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import SearchUsers from "./components/SearchUsers/SearchUsers";
import CreateUser from "./components/CreateUser/CreateUser";
import EditUser from "./components/EditUser/EditUser";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "features/language/languageSlice";
import { UserRole } from "features/user/userRole";
import { getProfileById } from "features/user/userAPI";
import { setEditUser } from "features/admin/adminSlice";

function Users() {
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const { i18n } = useTranslation();
	const language = useSelector(selectLanguage)

	const [selectedTab, setSelectedTab] = useState("searchUsers");
	const [selectedUserID, setSelecteduserID] = useState(null);
	const [showEditUserTab, setShowEditUserTab] = useState(false);
	const [submitSuccessCount, setSubmitSuccessCount] = useState(0);

	const handleTabChange = (value) => {
		setSelectedTab(value);
	};
	
	const fetchData = async (userID) => {
		const response = await getProfileById(userID);

		if (response.status === 200) {
			dispatch(setEditUser(response.data));
		}
	}

	const openEditUserTab = async (userID) => {
		try {
			await fetchData(userID);
			setSelecteduserID(userID);
			setShowEditUserTab(true);
			setSelectedTab("editUser");
		} catch (e) {
			alert(i18n.t("invalid-generic"));
		}
	};

	useEffect(() => {
		if (selectedUserID) {
			fetchData(selectedUserID);
		}
	}, [submitSuccessCount])

	return (
		<Container fluid>
			<h4 className="fw-normal">{i18n.t("user-management")}</h4>
			<span className="fw-normal text-muted">{i18n.t("admin-user-management-info")}</span>
			<Row className="g-3 mt-2">
				<Col sm={12}>
					<Tabs id="user-management-tabs" activeKey={selectedTab} onSelect={handleTabChange}>
						<Tab eventKey="searchUsers" title={i18n.t("search-users")} className="border border-top-0 p-3">
							<SearchUsers onEditUser={openEditUserTab} />
						</Tab>
						{showEditUserTab && (
							<Tab eventKey="editUser" title={user && user.adminRoles.includes(UserRole.LEVEL_2) ? i18n.t("edit-user") : i18n.t("view-user")} className="border border-top-0 p-3">
								<EditUser 
									userID={selectedUserID} 
									language={language}
									submitSuccessCount={submitSuccessCount}
									setSubmitSuccessCount={setSubmitSuccessCount}
								/>
							</Tab>
						)}
						{/* {user && user.adminRoles.includes(UserRole.LEVEL_2) && (
							<Tab
								eventKey="createUser"
								title={i18n.t("create-user")}
								className="border border-top-0 p-3"
							>
								<CreateUser />
							</Tab>
						)} */}
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
}

export default Users;
