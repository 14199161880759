import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

/* Import components */
import { Tabs, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import PostLogin from "../../../components/PostLogin/PostLogin";
import AccountInformationTab from "./components/AccountInformationTab";
import SportSelectionTab from "./components/SportSelectionTab";
import ResetPasswordTab from "./components/ResetPasswordTab";
import PrivacyPolicyTab from "./components/PrivacyPolicyTab";
import "react-datepicker/dist/react-datepicker.css";
import UserStatistics from "./components/UserStatistics";
import { getProfile } from "features/user/userAPI";
import { setProfile } from "features/user/userSlice";
import { useNavigate } from "react-router-dom";
import GenericModal from "components/GenericModal";

function ProfilePage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { i18n } = useTranslation();

	const [showErrorModal, setShowErrorModal] = useState(false);
	const [error, setError] = useState();
	const [isLoaded, setIsLoaded] = useState(false);
	const [submitSuccessCount, setSubmitSuccessCount] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getProfile();

				if (response.status === 200) {
					dispatch(setProfile(response.data));
					setIsLoaded(true);
				} else if (response.status === 401) {
					navigate("/login");
				} else if (response.status === 429) {
					throw { message: i18n.t("error-too-many-requests") };
				} else {
					throw { message: i18n.t("invalid-generic") };
				}
			} catch (e) {
				setError(e.message);
				setShowErrorModal(true);
			}
		};

		fetchData();
	}, [submitSuccessCount]);

	const handleGenericModalClose = () => setShowErrorModal(false);

	// Add aria-description with keyboard nav info to first (account information) tab list item
	useLayoutEffect(() => {
		const updateAccountInfoTab = setInterval(() => {
			const tabTarget = document.querySelector(
				"#profile-tabs-tab-account-information-tab"
			);

			if (tabTarget) {
				clearInterval(updateAccountInfoTab);
				tabTarget.setAttribute(
					"aria-roledescription",
					i18n.t("tab-aria-roledescription") +
						"; " +
						i18n.t("profile-account-information-info")
				);
				tabTarget.setAttribute(
					"aria-describedby",
					"fields-disabled-label keyboard-nav-instructions-tabs"
				);
			}
		}, 100);

		const updateSportSelectionTab = setInterval(() => {
			const tabTarget = document.querySelector(
				"#profile-tabs-tab-sport-selection-tab"
			);

			if (tabTarget) {
				clearInterval(updateSportSelectionTab);
				tabTarget.setAttribute(
					"aria-roledescription",
					i18n.t("tab-aria-roledescription") +
						" (" +
						i18n.t("profile-sport-selection-info") +
						")"
				);
			}
		}, 100);

		const updateResetPasswordTab = setInterval(() => {
			const tabTarget = document.querySelector(
				"#profile-tabs-tab-reset-password-tab"
			);

			if (tabTarget) {
				clearInterval(updateResetPasswordTab);
				tabTarget.setAttribute(
					"aria-roledescription",
					i18n.t("tab-aria-roledescription") +
						" (" +
						i18n.t("profile-reset-password-info") +
						")"
				);
			}
		}, 100);

		const updatePrivacyPolicyTab = setInterval(() => {
			const tabTarget = document.querySelector(
				"#profile-tabs-tab-privacy-policy-tab"
			);

			if (tabTarget) {
				clearInterval(updatePrivacyPolicyTab);
				tabTarget.setAttribute(
					"aria-roledescription",
					i18n.t("tab-aria-roledescription") +
						" (" +
						i18n.t("profile-privacy-policy-info") +
						")"
				);
			}
		}, 100);

		// Cleanup function to clear intervals and prevent memory leaks
		return () => {
			clearInterval(updateAccountInfoTab);
			clearInterval(updateSportSelectionTab);
			clearInterval(updateResetPasswordTab);
			clearInterval(updatePrivacyPolicyTab);
		};
	}, []);

	return (
		<>
			<div id="keyboard-nav-instructions-tabs" className="sr-only">
				{i18n.t("keyboard-nav-instructions-tabs")}
			</div>

			<GenericModal
				show={showErrorModal}
				onHide={handleGenericModalClose}
				backdrop="static"
				keyboard={false}
				titleText={i18n.t("error")}
				bodyText={error}
				footerText={i18n.t("close")}
			/>

			<PostLogin
				maxWidth={"1400px"}
				alignCenter={true}
				ariaLabel={i18n.t("profile-header")}
				ariaDescription={i18n.t(
					"account-information-tab-selected-by-default"
				)}
			>
				<div className="page-header mb-3 mt-2 badge">
					<h1>{i18n.t("profile-header")}</h1>
				</div>
				{isLoaded && (
					<>
						<UserStatistics />
						<Tabs
							defaultActiveKey="account-information-tab"
							id="profile-tabs"
						>
							<Tab
								eventKey="account-information-tab"
								title={i18n.t("account-information-header")}
								className="border border-top-0 p-3"
							>
								<AccountInformationTab
									language={i18n.language}
									submitSuccessCount={submitSuccessCount}
									setSubmitSuccessCount={
										setSubmitSuccessCount
									}
								/>
							</Tab>
							<Tab
								eventKey="sport-selection-tab"
								title={i18n.t("sport-selection-header")}
								className="border border-top-0 p-3"
							>
								<SportSelectionTab
									language={i18n.language}
									submitSuccessCount={submitSuccessCount}
									setSubmitSuccessCount={
										setSubmitSuccessCount
									}
								/>
							</Tab>
							<Tab
								eventKey="reset-password-tab"
								title={i18n.t("reset-password-header")}
								className="border border-top-0 p-3"
							>
								<ResetPasswordTab />
							</Tab>
							<Tab
								eventKey="privacy-policy-tab"
								title={i18n.t("privacy-policy-header")}
								className="border border-top-0 p-3"
							>
								<PrivacyPolicyTab />
							</Tab>
						</Tabs>
					</>
				)}
				<ToastContainer position="top-center" />
			</PostLogin>
		</>
	);
}

export default ProfilePage;
