export const ProgressOptions = [
	{ optionKey: "checkmark 1", translationKey: "checkpoint-1" },
	{ optionKey: "checkmark 2", translationKey: "checkpoint-2" },
	{ optionKey: "checkmark 3", translationKey: "checkpoint-3" },
	{ optionKey: "checkmark 4", translationKey: "checkpoint-4" },
	{ optionKey: "checkmark 5", translationKey: "checkpoint-5" },
	{ optionKey: "checkmark 6", translationKey: "checkpoint-6" },
	{ optionKey: "checkmark 7", translationKey: "checkpoint-7" },
	{ optionKey: "checkmark 8", translationKey: "checkpoint-8" },
	{ optionKey: "checkmark 9", translationKey: "checkpoint-9" },
	{ optionKey: "checkmark 10", translationKey: "checkpoint-10" },
	{ optionKey: "sign", translationKey: "pending-signature" },
	{ optionKey: "download", translationKey: "downloaded-and-pending-signature" },
	{ optionKey: "completed", translationKey: "progress-completed" },
	{ optionKey: "progress-not-started", translationKey: "progress-not-started" },
];

Object.freeze(ProgressOptions);

export const AdminLevelOptions = [
	{ optionKey: "none", translationKey: "level-0"},
	{ optionKey: "level 1", translationKey: "level-1" },
	{ optionKey: "level 2", translationKey: "level-2" },
];

Object.freeze(AdminLevelOptions);
