import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function Username({ value, id="username", name="username", placeholder, label, ariaLabel, showLabel=true, onChange, onBlur, isInvalid, disabled=false, errors, inputRef, invalidFeedbackID="invalid-feedback-username" }) {
	return (
		<>
            {showLabel &&
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            }
			<InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
                <Form.Control
                    value={value}
                    type="text"
                    id={id}
                    name={name}
                    placeholder={placeholder}
                    aria-label={ariaLabel}
					title={ariaLabel}
                    onChange={onChange}
                    onBlur={onBlur}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    aria-labelledby={[id,invalidFeedbackID].join(' ')}
                    required
                    ref={inputRef}
                />
                <Form.Control.Feedback type="invalid" id={invalidFeedbackID}>{errors}</Form.Control.Feedback>
            </InputGroup>
		</>
	);
}

export default Username;