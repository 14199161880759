import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/user/userSlice.js";
import { UserRole } from "features/user/userRole";
import ConsentFormSignedReport from "./components/ConsentFormSignedReport/ConsentFormSignedReport";
import UserReport from "./components/UserReport/UserReport";
import ContentStatusReport from "./components/ContentStatusReport/ContentStatusReport";
import SignatoriesReport from "./components/SignatoriesReport/SignatoriesReport";
import AdministratorReport from "./components/AdministratorReport/AdministratorReport";
import AdminActivityReport from "./components/AdminActivityReport/AdminActivityReport";
import UserSportOrganizationTrackingReport from "./components/UserSportOrganizationTrackingReport/UserSportOrganizationTrackingReport";

import styles from "./Reports.module.css";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Reports() {
	const user = useSelector(selectUser);
	const [selectedReport, setSelectedReport] = useState("userReport");
	const { i18n } = useTranslation();

	const handleReportChange = (reportValue) => {
		setSelectedReport(reportValue);
	};

	return (
		<Container fluid>
			<Row className={styles.reportSelector}>
				<Col xs={12}>
					<h4 className="fw-normal">{i18n.t("reports")}</h4>
					<div className="fw-normal text-muted mb-4">{i18n.t("admin-search-user-info")}</div>
					<Tabs id="report-tabs" activeKey={selectedReport} onSelect={handleReportChange}>
						<Tab eventKey="userReport" title={i18n.t("user-report")} className="border border-top-0 p-3">
							<UserReport />
						</Tab>
						<Tab eventKey="contentStatusReport" title={i18n.t("content-status-report")} className="border border-top-0 p-3">
							<ContentStatusReport />
						</Tab>
						{user && user.adminRoles.includes(UserRole.LEVEL_2) && (
							<Tab eventKey="userSportOrganizationTrackingReport" title={i18n.t("user-sport-organization-tracking-report")} className="border border-top-0 p-3">
								<UserSportOrganizationTrackingReport />
							</Tab>
						)}
						<Tab eventKey="consentFormSignedReport" title={i18n.t("consent-form-signed-report")} className="border border-top-0 p-3">
							<ConsentFormSignedReport />
						</Tab>
						{user && user.adminRoles.includes(UserRole.LEVEL_2) && (
							<Tab eventKey="signatoriesReport" title={i18n.t("signatories-report")} className="border border-top-0 p-3">
								<SignatoriesReport />
							</Tab>
						)}
						{user && user.adminRoles.includes(UserRole.LEVEL_2) && (
							<Tab eventKey="administratorReport" title={i18n.t("administrator-report")} className="border border-top-0 p-3">
								<AdministratorReport />
							</Tab>
						)}
						{user && user.adminRoles.includes(UserRole.LEVEL_2) && (
							<Tab eventKey="adminActivityReport" title={i18n.t("administrator-activity-report")} className="border border-top-0 p-3">
								<AdminActivityReport />
							</Tab>
						)}
					</Tabs>
				</Col>
			</Row>
		</Container>
	);
}

export default Reports;
