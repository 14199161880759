import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { setUser } from "../../../features/user/userSlice";
import { setSports, selectSports } from "../../../features/sports/sportsSlice";
import { getSportOrganizations } from "../../../features/sports/sportsAPI";
import { checkUsername, register } from "../../../features/user/userAPI";
import {
	validUsername,
	validPassword,
	validFirstLastName,
	validEmail,
} from "../../../app/regex";

/* Import components */
import { Alert, Form, Col, Row, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PreLogin from "../../../components/PreLogin/PreLogin";
import styles from "./Registration.module.css";
import Username from "../../../components/Fields/Username";
import Password from "../../../components/Fields/Password";
import ParticipantFirstName from "../../../components/Fields/ParticipantFirstName";
import ParticipantLastName from "../../../components/Fields/ParticipantLastName";
import ParticipantEmail from "../../../components/Fields/ParticipantEmail";
import Province from "../../../components/Fields/Province";
import HasImpediments from "../../../components/Fields/HasImpediments";
import GuardianFirstName from "../../../components/Fields/GuardianFirstName";
import GuardianLastName from "../../../components/Fields/GuardianLastName";
import GuardianEmail from "../../../components/Fields/GuardianEmail";
import ParticipantDateOfBirth from "../../../components/Fields/ParticipantDateOfBirth";
import GuardianYearOfBirth from "../../../components/Fields/GuardianYearOfBirth";
import ParticipantViewingWithGuardian from "../../../components/Fields/ParticipantViewingWithGuardian";
import PrivacyPolicyModal from "./components/PrivacyPolicyModal";
import GenericModal from "../../../components/GenericModal";
import LoadingSpinner from "components/LoadingSpinner";
import "react-datepicker/dist/react-datepicker.css";
import { FocusError } from "focus-formik-error";
import SportSelection from "components/Fields/SportSelection";
import { srAnnounce } from "features/global/functions";

function RegistrationPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const sportOrganizations = useSelector(selectSports);
	const { t, i18n } = useTranslation();

	const [formConditionals, setFormConditionals] = useState([
		{ showForm: false, showGuardianSection: false },
	]);
	const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
	const [showGenericModal, setShowGenericModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState();

	const usernameInputRef = useRef(null);
	const formikValidateOnBlur = useRef(false);
	const privacyPolicyAccepted = useRef(false);
	const genericModalProps = useRef({
		titleText: "",
		bodyText: "",
		footerText: "",
	});
	const sportCounter = useRef(0);

	// additional form validation if guardian section used
	var guardianValidationSchema = {};
	if (formConditionals.showGuardianSection) {
		guardianValidationSchema = {
			hasImpedimentsRadio: Yup.number().required(
				i18n.t("has-impediments-required")
			),
			guardianFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			guardianLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			guardianEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			guardianYearOfBirth: Yup.date()
				.required(i18n.t("year-of-birth-required"))
				.test({
					name: "guardian-yob-validation",
					message: i18n.t("invalid-guardian-yob"),
					test: (value) => {
						var ageInYears = moment().diff(value, "years");
						if (ageInYears < 19) {
							toast.dismiss();
							toast.info(i18n.t("invalid-guardian-yob"));
							formik.setFieldValue(
								"guardianYearOfBirth",
								null,
								false
							);
							return false;
						}

						return true;
					},
				}),
			participantViewingWithGuardianRadio: Yup.number().required(
				i18n.t("participant-viewing-with-guardian-required")
			),
		};
	} else {
		guardianValidationSchema = null;
	}

	// form validation using formik library
	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
		initialValues: {
			username: null,
			password: null,
			confirmPassword: null,
			participantFirstName: null,
			participantLastName: null,
			participantEmail: null,
			participantDateOfBirth: null,
			participantProvince: null,
			userSports: [
				{
					id: "prefix" + sportCounter.current++,
					name: "",
					sport_organization_membership_number: "",
				},
			],
			hasImpedimentsRadio: null,
			guardianFirstName: null,
			guardianLastName: null,
			guardianEmail: null,
			guardianYearOfBirth: null,
			participantViewingWithGuardianRadio: null,
		},
		validationSchema: Yup.object({
			username: Yup.string()
				.trim(i18n.t("username-required"))
				.required(i18n.t("username-required"))
				.matches(validUsername, i18n.t("invalid-username-regex"))
				.test({
					name: "username-validation",
					message: i18n.t("duplicate-username"),
					test: async function (value) {
						return await checkUsername(value);
					},
				}),
			password: Yup.string()
				.required(i18n.t("password-required"))
				.min(8, i18n.t("invalid-password"))
				.matches(validPassword, i18n.t("invalid-password-regex")),
			confirmPassword: Yup.string()
				.required(i18n.t("password-required"))
				.min(8, i18n.t("invalid-password"))
				.oneOf([Yup.ref("password")], "Passwords must match")
				.matches(validPassword, i18n.t("invalid-password-regex")),
			participantFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			participantLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			participantEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			participantDateOfBirth: Yup.date().required(
				i18n.t("date-of-birth-required")
			),
			participantProvince: Yup.string().required(
				i18n.t("participant-province-required")
			),
			userSports: Yup.array().of(
				Yup.object({
					name: Yup.string().required(
						i18n.t("participant-sport-required")
					),
					sport_organization_membership_number:
						Yup.string().nullable(),
				})
			),
			...guardianValidationSchema,
		}),
		onSubmit: async (values) => {
			let response;
			toast.dismiss(); // Hide any existing toasts

			const userIsOfAge = isUserAgeOfConsent();

			// Check user's age on form submit and display guardian section if under 19
			if (!userIsOfAge && formConditionals.showGuardianSection == false) {
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: true,
				});
				toast.info(i18n.t("under-19-show-guardian-section"));
				if (isLoading) setIsLoading(false);
				return;
			}
			// Hide guardian section if user is over 19 years of age and has no impediments
			else if (
				userIsOfAge &&
				formik.values.hasImpedimentsRadio == 0 &&
				formConditionals.showGuardianSection == true
			) {
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: false,
				});
				toast.info(
					i18n.t("over-19-no-impediments-hide-guardian-section")
				);
				if (isLoading) setIsLoading(false);
				return;
			}

			// Show privacy policy if not yet accepted
			if (!privacyPolicyAccepted.current) {
				setIsLoading(true);
				setTimeout(() => {
					setIsLoading(false);
					setShowPrivacyPolicyModal(true);
				}, 200);
				return;
			}

			// Create formatted variables for all form fields (except sport selections)
			var usernameFormatted = values.username;
			var passwordFormatted = values.password;
			var participantFirstNameFormatted = values.participantFirstName;
			var participantLastNameFormatted = values.participantLastName;
			var participantEmailFormatted = values.participantEmail;
			var participantDateOfBirthFormatted = values.participantDateOfBirth;
			var participantProvinceFormatted = values.participantProvince;
			var hasImpedimentsRadioFormatted = values.hasImpedimentsRadio;
			var guardianFirstNameFormatted = values.guardianFirstName;
			var guardianLastNameFormatted = values.guardianLastName;
			var guardianEmailFormatted = values.guardianEmail;
			var guardianYearOfBirthFormatted = values.guardianYearOfBirth;
			var participantViewingWithGuardianRadioFormatted =
				values.participantViewingWithGuardianRadio;

			// Create formatted variables for Sport Selections
			var sportOrganizationIdArrayFormatted = [];
			var sportOrganizationMembershipNumberArrayFormatted = [];
			const userSportsUpdated = values.userSports;
			for (const index in userSportsUpdated) {
				sportOrganizationIdArrayFormatted.push(
					userSportsUpdated[index].id
				);
				sportOrganizationMembershipNumberArrayFormatted.push(
					userSportsUpdated[index]
						.sport_organization_membership_number
				);
			}

			// Trim white spaces
			usernameFormatted = usernameFormatted.trim();
			participantFirstNameFormatted =
				participantFirstNameFormatted.trim();
			participantLastNameFormatted = participantLastNameFormatted.trim();
			participantEmailFormatted = participantEmailFormatted.trim();
			if (guardianFirstNameFormatted)
				guardianFirstNameFormatted = guardianFirstNameFormatted.trim();
			if (guardianLastNameFormatted)
				guardianLastNameFormatted = guardianLastNameFormatted.trim();
			if (guardianEmailFormatted)
				guardianEmailFormatted = guardianEmailFormatted.trim();

			// Convert Participant Date to YYYY-MM-DD format
			participantDateOfBirthFormatted = moment(
				participantDateOfBirthFormatted
			).format("YYYY-MM-DD");

			// Convert Guardian Date to YYYY format (if exists)
			if (guardianYearOfBirthFormatted)
				guardianYearOfBirthFormatted = moment(
					guardianYearOfBirthFormatted
				).format("YYYY");

			// Set default values for guardian section
			if (!formConditionals.showGuardianSection) {
				hasImpedimentsRadioFormatted = 0; // Set Has Impediments value of 0 (false/no) if user is 19 years of age or older and registering without a guardian
				guardianFirstNameFormatted = "";
				guardianLastNameFormatted = "";
				guardianEmailFormatted = "";
				guardianYearOfBirthFormatted = "";
				participantViewingWithGuardianRadioFormatted = "";
			}

			// Send request to register user
			try {
				setIsLoading(true);

				response = await register(
					participantFirstNameFormatted,
					participantLastNameFormatted,
					participantEmailFormatted,
					passwordFormatted,
					i18n.language,
					usernameFormatted,
					participantDateOfBirthFormatted,
					hasImpedimentsRadioFormatted,
					participantProvinceFormatted,
					sportOrganizationIdArrayFormatted,
					sportOrganizationMembershipNumberArrayFormatted,
					participantViewingWithGuardianRadioFormatted,
					guardianEmailFormatted,
					guardianFirstNameFormatted,
					guardianLastNameFormatted,
					guardianYearOfBirthFormatted
				);

				switch (response.status) {
					case 201: // Request successful
						dispatch(setUser(response.data.user));
						navigate("/");
						break;
					case 409: // Duplicate username -- this error is handled by formik
						console.log("Duplicate username");
						break;
					default: // Generic error message
						setError("Something went wrong! Please try again.");
						privacyPolicyAccepted.current = false;
						break;
				}
			} catch (e) {
				setError(e);
				privacyPolicyAccepted.current = false;
			} finally {
				setIsLoading(false);
			}
		},
	});

	// Check if user is age 19 or older
	function isUserAgeOfConsent() {
		var ageInYears = moment().diff(
			formik.values.participantDateOfBirth,
			"years"
		);
		if (ageInYears < 19) {
			return false;
		}

		return true;
	}

	const handlePrivacyPolicyModalClose = () =>
		setShowPrivacyPolicyModal(false);
	const handlePrivacyPolicyModalShow = () => setShowPrivacyPolicyModal(true);

	const acceptPrivacyPolicy = () => {
		privacyPolicyAccepted.current = true;
		setShowPrivacyPolicyModal(false);
		formik.submitForm();
	};
	const declinePrivacyPolicy = () => {
		setShowPrivacyPolicyModal(false);
		setIsLoading(true);

		setTimeout(() => {
			setIsLoading(false);

			genericModalProps.current = {
				titleText: i18n.t("privacy-policy-declined-modal-title"),
				bodyText: i18n.t("privacy-policy-declined-modal-body"),
				footerText: i18n.t(
					"privacy-policy-declined-modal-footer-button"
				),
			};
			setShowGenericModal(true);
		}, 200);
	};

	const handleDeclinedPrivacyPolicyModalClose = () =>
		setShowGenericModal(false);

	async function setFormikFieldsTouched() {
		formik.setTouched(
			{
				username: true,
				password: true,
				confirmPassword: true,
				participantFirstName: true,
				participantLastName: true,
				participantEmail: true,
				participantDateOfBirth: true,
				userSports: true,
				sportMembershipNumber: true,
				participantProvince: true,
				hasImpedimentsRadio: true,
				guardianFirstName: true,
				guardianLastName: true,
				guardianEmail: true,
				guardianYearOfBirth: true,
				participantViewingWithGuardianRadio: true,
			},
			true
		);
	}

	function focusUsername() {
		setTimeout(() => {
			if (usernameInputRef && usernameInputRef.current) {
				usernameInputRef.current.focus();
			}
		}, 100);
	}

	// Get sport organizations
	useEffect(() => {
		async function fetchSports() {
			toast.dismiss();
			const response = await getSportOrganizations(i18n.language);
			if (response && !response.error) {
				dispatch(setSports(response));
			} else {
				toast.error(t(response.error));
			}
		}
		fetchSports();
	}, [i18n.language, dispatch]);

	// Show/hide guardian section based on criteria
	useEffect(() => {
		const userIsOfAge = isUserAgeOfConsent();

		// Show toast and reveal guardian section if user is under 19 years of age
		if (!userIsOfAge && formConditionals.showGuardianSection == false) {
			toast.dismiss();
			setFormConditionals({
				showForm: formConditionals.showForm,
				showGuardianSection: true,
			});
			toast.info(i18n.t("under-19-show-guardian-section"));
		}
		// Show toast and hide guardian section if user is over 19 years of age and has no impediments
		else if (
			userIsOfAge &&
			formik.values.hasImpedimentsRadio == 0 &&
			formConditionals.showGuardianSection == true
		) {
			toast.dismiss();
			setFormConditionals({
				showForm: formConditionals.showForm,
				showGuardianSection: false,
			});
			toast.info(i18n.t("over-19-no-impediments-hide-guardian-section"));
		}
	}, [
		formik.values.participantDateOfBirth,
		formik.values.hasImpedimentsRadio,
	]);

	// Hide loading spinner after set by submit function and announce num of validation errors to screen reader and include userSports errors to count
	useEffect(() => {
		if (!formik.isValid && formik.isSubmitting) {
			//console.log(Object.keys(formik.errors));

			// Get number of formik validation errors
			let numberOfErrors = Object.keys(formik.errors).length;
			// Check if userSports exists in errors object
			if (
				Object.keys(formik.errors).some((field) =>
					field.startsWith("userSports")
				)
			) {
				// Determine how many userSports fields have validation error (empty name value == no org selected)
				const emptyNameCount = formik.values.userSports.filter(
					(sport) => !sport.name
				).length;
				// Add this value to the numberOfErrors
				numberOfErrors = numberOfErrors + emptyNameCount - 1; // subtract 1 since userSports exists in formik.errors object
			}
			// Announce number of validation errors to screenreader
			srAnnounce(
				i18n
					.t("sr-announce-formik-errors")
					.replace("{errors}", numberOfErrors),
				true
			);
			// renmove loading spinner after submission
			setTimeout(() => {
				setIsLoading(false);
			}, 500); // 100ms less than focusDelay timeout for FocusError component, works best with screen reader and srAnnounce
		}
	}, [
		formik.errors,
		formik.isValid,
		formik.isSubmitting,
		formik.values,
		i18n,
	]);

	if (error) {
		return <div>{i18n.t("something-went-wrong")}</div>;
	}

	return (
		<>
			<PrivacyPolicyModal
				show={showPrivacyPolicyModal}
				onHide={handlePrivacyPolicyModalClose}
				onDecline={declinePrivacyPolicy}
				onAccept={acceptPrivacyPolicy}
				backdrop="static"
				keyboard={false}
			/>

			<GenericModal
				show={showGenericModal}
				onHide={handleDeclinedPrivacyPolicyModalClose}
				backdrop="static"
				keyboard={false}
				titleText={genericModalProps.current.titleText}
				bodyText={genericModalProps.current.bodyText}
				footerText={genericModalProps.current.footerText}
			></GenericModal>

			{isLoading && <LoadingSpinner isLoading={isLoading} />}

			<PreLogin
				maxWidth={"960px"}
				alignCenter={true}
				ariaLabel={i18n.t("participant-consent-form-registration")}
			>
				<h1 className="h3 mb-3 fw-normal text-center">
					{i18n.t("participant-consent-form-registration")}
				</h1>
				<div className="mb-3 text-center">
					<Trans i18nKey="program-length-note" />
				</div>
				<Alert variant="warning" className="p-2" role="">
					<small>
						<i>
							<Trans
								i18nKey="important-note-registration"
								components={[<strong></strong>, <u></u>]}
							/>
						</i>
					</small>
				</Alert>
				<h5 role="heading" aria-level={2} className="text-center my-4">
					<Trans
						i18nKey="registration-header-question"
						components={[<strong></strong>]}
					/>
				</h5>

				<div
					className={`${styles["consent-buttons"]} justify-content-evenly mt-5 mb-4`}
				>
					<Button
						type="button"
						variant="primary"
						className="btn btn-primary btn-xl btn-block w-50 mx-3"
						aria-label={i18n.t(
							"registration-yes-button-aria-label"
						)}
						onClick={() => {
							setFormConditionals({
								showForm: true,
								showGuardianSection: false,
							});
							focusUsername();
						}}
					>
						<strong>{i18n.t("registration-yes")}</strong>
						<br />
						{i18n.t("i-can-sign-on-my-own")}
					</Button>
					<Button
						type="button"
						variant="primary"
						className="btn btn-primary btn-xl btn-block w-50 mx-3"
						aria-label={i18n.t("registration-no-button-aria-label")}
						onClick={() => {
							setFormConditionals({
								showForm: true,
								showGuardianSection: true,
							});
							focusUsername();
						}}
					>
						<strong>{i18n.t("registration-no")}</strong>
						<br />
						{i18n.t("parent-or-guardian-will-sign")}
					</Button>
				</div>

				{formConditionals.showForm && (
					<Form id="registrationForm" noValidate className="pb-2">
						{
							// Exclude FocusError component if userSports field is next validation error (not compatible)
							// Custom focus method exists specifically for this type of field in the SportSelection.js component
							Object.keys(formik.errors)[0] !== "userSports" && (
								<FocusError formik={formik} focusDelay={600} />
							)
						}
						<h4 role="heading" aria-level={3}>
							{i18n.t("account-information-label")}
						</h4>
						<div className="form-text">
							{i18n.t("account-information-info")}
						</div>

						<Row className="g-3 mt-2">
							<Col sm={12} className="mb-3">
								<Username
									placeholder={i18n.t("username-placeholder")}
									label={i18n.t("account-username-label")}
									aria-label={i18n.t("username-placeholder")}
									onChange={formik.handleChange}
									onBlur={async () => {
										formik.handleBlur("username");
										formik.validateField("username");
									}}
									isInvalid={
										formik.touched.username &&
										formik.errors.username
									}
									disabled={isLoading}
									errors={formik.errors.username}
									inputRef={usernameInputRef}
								></Username>
							</Col>
							<Col md={6} className="mb-3">
								<Password
									placeholder={i18n.t("password-placeholder")}
									label={i18n.t("password-label")}
									ariaLabel={i18n.t("password-aria-label")}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.password &&
										formik.errors.password
									}
									disabled={isLoading}
									errors={formik.errors.password}
									invalidFeedbackID="invalid-feedback-password"
								></Password>
							</Col>
							<Col md={6} className="mb-3">
								<Password
									id="confirmPassword"
									name="confirmPassword"
									placeholder={i18n.t(
										"confirm-password-placeholder"
									)}
									label={i18n.t("confirm-password-label")}
									ariaLabel={i18n.t(
										"confirm-password-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.confirmPassword &&
										formik.errors.confirmPassword
									}
									disabled={isLoading}
									errors={formik.errors.confirmPassword}
									invalidFeedbackID="invalid-feedback-confirm-password"
								></Password>
							</Col>
						</Row>

						<hr className="my-4" />
						<h4 role="heading" aria-level={3}>
							{i18n.t("sport-participant-information-label")}
						</h4>
						<div className="form-text">
							{i18n.t("sport-participant-information-info")}
						</div>

						<Row className="g-3 mt-2">
							<Col md={6} className="mb-3">
								<ParticipantFirstName
									placeholder={i18n.t(
										"participant-first-name-placeholder"
									)}
									label={i18n.t(
										"participant-first-name-label"
									)}
									aria-label={i18n.t(
										"participant-first-name-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.participantFirstName &&
										formik.errors.participantFirstName
									}
									disabled={isLoading}
									errors={formik.errors.participantFirstName}
								></ParticipantFirstName>
							</Col>
							<Col md={6} className="mb-3">
								<ParticipantLastName
									placeholder={i18n.t(
										"participant-last-name-placeholder"
									)}
									label={i18n.t(
										"participant-last-name-label"
									)}
									aria-label={i18n.t(
										"participant-last-name-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.participantLastName &&
										formik.errors.participantLastName
									}
									disabled={isLoading}
									errors={formik.errors.participantLastName}
								></ParticipantLastName>
							</Col>
							<Col md={6} className="mb-3">
								<ParticipantEmail
									placeholder={i18n.t(
										"participant-email-placeholder"
									)}
									label={i18n.t("participant-email-label")}
									aria-label={i18n.t(
										"participant-email-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.participantEmail &&
										formik.errors.participantEmail
									}
									disabled={isLoading}
									errors={formik.errors.participantEmail}
									showGuardianSection={
										formConditionals.showGuardianSection
									}
									twoFactorLabel={i18n.t(
										"two-factor-email-label"
									)}
								></ParticipantEmail>
							</Col>
							<Col md={6} className="mb-3">
								<ParticipantDateOfBirth
									placeholder={i18n.t(
										"participant-date-of-birth-placeholder"
									)}
									label={i18n.t("participant-dob-label")}
									selected={
										formik.values.participantDateOfBirth
									}
									onChange={(date) => {
										formik.setFieldValue(
											"participantDateOfBirth",
											date
										);
									}}
									onBlur={() => {
										formik.handleBlur(
											"participantDateOfBirth"
										);
										formik.validateField(
											"participantDateOfBirth"
										);
									}}
									isInvalid={
										formik.touched.participantDateOfBirth &&
										formik.errors.participantDateOfBirth
									}
									disabled={isLoading}
									locale={i18n.language}
									errors={
										formik.errors.participantDateOfBirth
									}
									formik={formik}
								></ParticipantDateOfBirth>
							</Col>
							<Col sm={12} className="mb-3">
								<Province
									provinceSelectedString={
										formik.values.participantProvince
									}
									id="participantProvince"
									name="participantProvince"
									label={i18n.t("province-label")}
									placeholder={i18n.t("province-placeholder")}
									onChange={(e) => {
										formik.setFieldValue(
											"participantProvince",
											e.value
										);
									}}
									onBlur={() => {
										formik.setFieldTouched(
											"participantProvince"
										);
									}}
									isInvalid={
										formik.touched.participantProvince &&
										formik.errors.participantProvince
									}
									disabled={isLoading}
									errors={formik.errors.participantProvince}
									required
									formik={formik}
								/>
							</Col>
						</Row>

						<hr className="my-4" />
						<h4 role="heading" aria-level={3}>
							{i18n.t("sport-selection-header")}
						</h4>
						<div className="form-text">
							{i18n.t("profile-sport-selection-info")}
						</div>

						<SportSelection
							sportCounter={sportCounter}
							isLoading={isLoading}
							formik={formik}
							sportOrganizations={sportOrganizations}
						/>

						{formConditionals.showGuardianSection && (
							<div>
								<hr className="my-4" />
								<Col sm={12} className="text-center">
									<HasImpediments
										label={i18n.t("has-impediments-label")}
										labelNo={i18n.t("has-impediments-no")}
										labelYes={i18n.t("has-impediments-yes")}
										autoComplete="off"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										yesChecked={
											formik.values.hasImpedimentsRadio ==
											1
												? true
												: false
										}
										noChecked={
											formik.values.hasImpedimentsRadio ==
											0
												? true
												: false
										}
										disabled={isLoading}
										formik={formik}
									/>
								</Col>
							</div>
						)}

						{formConditionals.showGuardianSection && (
							<div>
								<hr className="my-4" />
								<h4 role="heading" aria-level={3}>
									{i18n.t(
										"parent-guardian-information-label"
									)}
								</h4>
								<div className="form-text">
									{i18n.t("parent-guardian-information-info")}
								</div>
								<Row className="g-3 mt-2">
									<Col md={6} className="mb-3">
										<GuardianFirstName
											value={
												formik.values
													.guardianFirstName == null
													? ""
													: formik.values
															.guardianFirstName
											}
											placeholder={i18n.t(
												"guardian-first-name-placeholder"
											)}
											label={i18n.t(
												"guardian-first-name-label"
											)}
											aria-label={i18n.t(
												"guardian-first-name-placeholder"
											)}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											isInvalid={
												formik.touched
													.guardianFirstName &&
												formik.errors.guardianFirstName
											}
											disabled={isLoading}
											errors={
												formik.errors.guardianFirstName
											}
										></GuardianFirstName>
									</Col>
									<Col md={6} className="mb-3">
										<GuardianLastName
											value={
												formik.values
													.guardianLastName == null
													? ""
													: formik.values
															.guardianLastName
											}
											placeholder={i18n.t(
												"guardian-last-name-placeholder"
											)}
											label={i18n.t(
												"guardian-last-name-label"
											)}
											aria-label={i18n.t(
												"guardian-last-name-placeholder"
											)}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											isInvalid={
												formik.touched
													.guardianLastName &&
												formik.errors.guardianLastName
											}
											disabled={isLoading}
											errors={
												formik.errors.guardianLastName
											}
										></GuardianLastName>
									</Col>
									<Col md={6} className="mb-3">
										<GuardianEmail
											value={
												formik.values.guardianEmail ==
												null
													? ""
													: formik.values
															.guardianEmail
											}
											placeholder={i18n.t(
												"guardian-email-placeholder"
											)}
											label={i18n.t(
												"guardian-email-label"
											)}
											aria-label={i18n.t(
												"guardian-email-placeholder"
											)}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											isInvalid={
												formik.touched.guardianEmail &&
												formik.errors.guardianEmail
											}
											disabled={isLoading}
											errors={formik.errors.guardianEmail}
											twoFactorLabel={i18n.t(
												"two-factor-email-label"
											)}
										></GuardianEmail>
									</Col>
									<Col md={6} className="mb-3">
										<GuardianYearOfBirth
											label={i18n.t("guardian-yob-label")}
											placeholder={i18n.t(
												"guardian-yob-placeholder"
											)}
											selected={
												formik.values
													.guardianYearOfBirth
											}
											startDate={
												formik.values
													.guardianYearOfBirth
											}
											onChange={(date) => {
												formik.setFieldValue(
													"guardianYearOfBirth",
													date
												);
											}}
											onBlur={() => {
												formik.handleBlur(
													"guardianYearOfBirth"
												);
												formik.validateField(
													"guardianYearOfBirth"
												);
											}}
											isInvalid={
												formik.touched
													.guardianYearOfBirth &&
												formik.errors
													.guardianYearOfBirth
											}
											disabled={isLoading}
											locale={i18n.language}
											errors={
												formik.errors
													.guardianYearOfBirth
											}
											yearOfBirthLabel={i18n.t(
												"year-of-birth-label"
											)}
											formik={formik}
										></GuardianYearOfBirth>
									</Col>
									<hr className="mt-4 mb-1" />
									<Col sm={12} className="text-center">
										<ParticipantViewingWithGuardian
											label={i18n.t(
												"participant-viewing-with-guardian-label"
											)}
											labelNo={i18n.t(
												"participant-viewing-with-guardian-no"
											)}
											labelYes={i18n.t(
												"participant-viewing-with-guardian-yes"
											)}
											autoComplete="off"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											yesChecked={
												formik.values
													.participantViewingWithGuardianRadio ==
												1
													? true
													: false
											}
											noChecked={
												formik.values
													.participantViewingWithGuardianRadio ==
												0
													? true
													: false
											}
											disabled={isLoading}
											formik={formik}
										/>
									</Col>
									<Col sm={{ span: 10, offset: 1 }}>
										<div
											id="participant-not-viewing-with-guardian-notice"
											className="alert alert-danger p-2 mt-2 mb-0"
										>
											<small>
												<i>
													<strong>
														{i18n.t(
															"important-note-label"
														)}
													</strong>{" "}
													{i18n.t(
														"important-note-info"
													)}
												</i>
											</small>
										</div>
									</Col>
								</Row>
							</div>
						)}
						<hr className="my-4" />
						<Button
							type="button"
							variant="primary"
							size="lg"
							className="w-75 mx-auto d-block"
							onClick={async () => {
								formikValidateOnBlur.current = true;
								setIsLoading(true);
								await setFormikFieldsTouched();
								formik.handleSubmit();
							}}
							disabled={isLoading}
						>
							{!isLoading && i18n.t("submit-registration")}
							{isLoading && i18n.t("loading")}
						</Button>
					</Form>
				)}
				<Col sm={12} className="mt-1 text-center">
					<Link
						to="/login"
						relative="path"
						className="text-decoration-none"
						disabled={isLoading}
					>
						{i18n.t("back-to-login-page")}
					</Link>
				</Col>
				<ToastContainer position="bottom-right" />
			</PreLogin>
		</>
	);
}

export default RegistrationPage;
