import { useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useTranslation } from "react-i18next";

export const useDataTable = (initialFilters, searchAPI) => {
	const [filters, setFilters] = useState(initialFilters);
	const [reportData, setReportData] = useState({
		data: [],
		total: 0,
		currentPage: 1,
		perPage: 15,
	});
	const [hasFetched, setHasFetched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { i18n } = useTranslation();

	const handleSort = (column, sortDirection) => {
		setFilters((prev) => {
			const updatedFilters = {
				...prev,
				sorting: { field: column.sortField, order: sortDirection },
			};
			handleSearch(updatedFilters);
			return updatedFilters;
		});
	};

	const handlePageChange = (page) => {
		setFilters((prev) => {
			const updatedFilters = {
				...prev,
				page,
			};
			handleSearch(updatedFilters);
			return updatedFilters;
		});
	};

	const handleRowsPerPageChange = (newRowsPerPage) => {
		setFilters((prev) => {
			const updatedFilters = {
				...prev,
				per_page: newRowsPerPage,
				page: 1, // Reset currentPage to 1
			};
			handleSearch(updatedFilters);
			return updatedFilters;
		});
	};

	const handleSearch = async (overrideFilters) => {
		setIsLoading(true);
		const searchFilters = {
			...(overrideFilters || filters),
		};
		const result = await searchAPI(searchFilters);
		if (result && !result.error) {
			setReportData({
				data: result.data,
				total: result.total,
				currentPage: !result.data.length ? 0 : result.current_page,
				perPage: result.per_page,
			});
			setHasFetched(true);
		}
		setIsLoading(false);
	};

	const handleExport = (format, columns, filename) => {
		switch (format) {
			case "csv":
				exportToCSV(reportData.data, columns, filename);
				break;
			case "excel":
				exportToExcel(reportData.data, columns, filename);
				break;
			case "pdf":
				exportToPDF(reportData.data, columns, filename);
				break;
			default:
				break;
		}
	};

	const exportToCSV = (data, columns, filename) => {
		let csv = "";
		data.forEach((row) => {
			const rowData = columns.map((column) => row[column.sortField]);
			csv += rowData.join(",");
			csv += "\n";
		});
		const blob = new Blob([csv], { type: "text/csv" });
		saveAs(blob, `${filename}.csv`);
	};

	const exportToExcel = (data, columns, filename) => {
		const filteredData = data.map((row) => {
			let obj = {};
			columns.forEach((column) => {
				obj[i18n.t(column.exportKey)] = row[column.sortField];
			});
			return obj;
		});

		const ws = XLSX.utils.json_to_sheet(filteredData);
		const wb = XLSX.utils.book_new();

		let sheetName = filename.substring(0, 31);
		sheetName = sheetName.replace(/[\\*?:/\[\]]/g, "");
		sheetName = sheetName || "Sheet1";

		XLSX.utils.book_append_sheet(wb, ws, sheetName);
		XLSX.writeFile(wb, `${filename}.xlsx`);
	};

	const exportToPDF = (data, columns, filename) => {
		const unit = "pt";
		const size = "A4";
		const orientation = "portrait";

		const doc = new jsPDF(orientation, unit, size);
		doc.setFontSize(10);

		const content = {
			startY: 50,
			head: [columns.map((column) => i18n.t(column.exportKey))],
			body: data.map((row) => columns.map((column) => String(row[column.sortField] || ""))),
		};

		doc.autoTable(content);
		doc.save(`${filename}.pdf`);
	};

	return {
		filters,
		setFilters,
		reportData,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
		handleSearch,
		hasFetched,
		handleExport,
		isLoading,
	};
};
