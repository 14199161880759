import { Modal, Button } from "react-bootstrap";

function GenericModal({
	show,
	onHide,
	backdrop = "static",
	keyboard,
	titleText,
	bodyText,
	footerText,
}) {
	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				backdrop={backdrop}
				keyboard={keyboard}
				aria-labelledby="generic-modal-title"
				aria-describedby="generic-modal-body"
			>
				<Modal.Header closeButton>
					<Modal.Title className="h5" id="generic-modal-title">
						{titleText}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body id="generic-modal-body">{bodyText}</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={onHide} autoFocus>
						{footerText}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default GenericModal;
