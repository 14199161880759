import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PreLogin from "components/PreLogin/PreLogin";
import { setLanguage } from "features/language/languageSlice";

function LanguagePage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleLanguageSelection = (language) => {
		dispatch(setLanguage(language));
		navigate("/login");
	};

	return (
		<PreLogin
			maxWidth={"28rem"}
			alignCenter={true}
			ariaLabel="Language selection"
		>
			<div className="card-header text-center mb-4">
				<h1 className="h3 mb-3 fw-normal text-center">
					Select your preferred language
					<br />
					Sélectionnez votre langage désiré
				</h1>
			</div>
			<div className="row">
				<div className="col-6 offset-3 offset-sm-0 mb-3 mb-sm-0">
					<button
						type="button"
						className="btn btn-primary btn-block w-100"
						style={{ height: "5rem", fontSize: "20px" }}
						onClick={() => handleLanguageSelection("en")}
					>
						English
					</button>
				</div>
				<div className="col-6 offset-3 offset-sm-0">
					<button
						type="button"
						className="btn btn-primary btn-block w-100"
						style={{ height: "5rem", fontSize: "20px" }}
						onClick={() => handleLanguageSelection("fr")}
					>
						Français
					</button>
				</div>
			</div>
		</PreLogin>
	);
}

export default LanguagePage;
