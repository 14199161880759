let BASE_URL;
let IFRAME_PARTICIPANT_URL_EN;
let IFRAME_GUARDIAN_URL_EN;
let IFRAME_PARTICIPANT_URL_FR;
let IFRAME_GUARDIAN_URL_FR;

BASE_URL = process.env.REACT_APP_API_URL;
IFRAME_PARTICIPANT_URL_EN = process.env.REACT_APP_IFRAME_PARTICIPANT_URL_EN;
IFRAME_GUARDIAN_URL_EN = process.env.REACT_APP_IFRAME_GUARDIAN_URL_EN;
IFRAME_PARTICIPANT_URL_FR = process.env.REACT_APP_IFRAME_PARTICIPANT_URL_FR;
IFRAME_GUARDIAN_URL_FR = process.env.REACT_APP_IFRAME_GUARDIAN_URL_FR;

const config = {
	BASE_URL,
	IFRAME_PARTICIPANT_URL_EN,
	IFRAME_GUARDIAN_URL_EN,
	IFRAME_PARTICIPANT_URL_FR,
	IFRAME_GUARDIAN_URL_FR,
};

export default config;
