import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminLevel from "components/AdminLevel/AdminLevel";
import GlobalStatistics from "./components/GlobalStatistics/GlobalStatistics";
import Reports from "./components/Reports/Reports";
import Users from "./components/Users/Users";
import { UserRole } from "features/user/userRole";
import { useSelector } from "react-redux";
import { selectUser } from "features/user/userSlice";

function AdminPage() {
	const [selectedTab, setSelectedTab] = useState("userManagement");
	const { i18n } = useTranslation();
	const user = useSelector(selectUser);

	const handleTabChange = (value) => {
		setSelectedTab(value);
	};

	return (
		<AdminLevel levels={[UserRole.LEVEL_1, UserRole.LEVEL_2]}>
			<div className="page-header mb-3 mt-2 badge">
				<h1>{i18n.t("administration")}</h1>
			</div>
			{user && user.adminRoles.includes(UserRole.LEVEL_2) && (
				<GlobalStatistics />
			)}
			<Tabs
				id="admin-tabs"
				activeKey={selectedTab}
				onSelect={handleTabChange}
			>
				<Tab
					eventKey="userManagement"
					title={i18n.t("user-management")}
					className="border border-top-0 p-3"
				>
					<Users />
				</Tab>
				<Tab
					eventKey="reports"
					title={i18n.t("reports")}
					className="border border-top-0 p-3"
				>
					<Reports />
				</Tab>
			</Tabs>
			<ToastContainer position="top-center" />
		</AdminLevel>
	);
}

export default AdminPage;
