import PrivacyPolicy from "components/PrivacyPolicy";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function PrivacyPolicyModal({
	show,
	backdrop = "static",
	keyboard,
	size = "xl",
	onAccept,
	onDecline,
}) {
	const { i18n } = useTranslation();

	return (
		<>
			<Modal
				show={show}
				backdrop={backdrop}
				keyboard={keyboard}
				size={size}
				aria-labelledby="privacy-policy-modal-title"
				aria-describedby="privacy-policy-modal-body"
				fullscreen={true}
			>
				<Modal.Header>
					<Modal.Title className="h5" id="privacy-policy-modal-title">
						{i18n.t("privacy-policy-modal-title")}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body id="privacy-policy-modal-body">
					<PrivacyPolicy />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={onDecline}
						aria-label={i18n.t(
							"privacy-policy-modal-footer-decline-aria-label"
						)}
						autoFocus
					>
						{i18n.t("privacy-policy-modal-footer-decline")}
					</Button>
					<Button
						variant="primary"
						onClick={onAccept}
						aria-label={i18n.t(
							"privacy-policy-modal-footer-accept-aria-label"
						)}
					>
						{i18n.t("privacy-policy-modal-footer-accept")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default PrivacyPolicyModal;
