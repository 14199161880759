import React from "react";

function Header() {
	return (
		<>
			<header className="container text-center mt-4 mb-4" style={{ maxWidth: "20rem" }}>
				<img src="/images/logo.svg" className="img-fluid mb-4" alt="" style={{ height: "141px"}} />
			</header>
		</>
	);
}

export default Header;
