import React, { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

/* Import components */
import { Row, Col, Accordion } from "react-bootstrap";
import PostLogin from "../../../components/PostLogin/PostLogin";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Help.module.css";
import { Link } from "react-router-dom";
import { srAnnounce } from "features/global/functions";

function HelpPage() {
	const { i18n } = useTranslation();
	const bodyRefs = useRef({});

	// State to keep track of currently opened items
	const [openedItems, setOpenedItems] = useState(["0"]);

	// Handler to announce content when an Accordion.Body is opened or closed
	const handleToggle = (eventKey) => {
		// Determine the newly opened items
		const newlyOpened = Array.isArray(eventKey) ? eventKey : [eventKey];
		const newlyOpenedSet = new Set(newlyOpened);

		// Detect newly closed items
		const newlyClosed = openedItems.filter(
			(key) => !newlyOpenedSet.has(key)
		);

		// Announce newly opened items
		newlyOpened.forEach((key) => {
			if (!openedItems.includes(key)) {
				// If the item was not previously opened, it is newly opened
				if (bodyRefs.current[key]) {
					// Read out the text content of the Accordion.Body
					srAnnounce(bodyRefs.current[key].textContent);
				}
			}
		});

		// Announce newly closed items (disabled)
		// newlyClosed.forEach((key) => {
		// 	if (bodyRefs.current[key]) {
		// 		// Read out the text content of the Accordion.Body when it is closed
		// 		srAnnounce(`Collapsed: ${bodyRefs.current[key].textContent}`);
		// 	}
		// });

		// Update the state with the newly opened items
		setOpenedItems(newlyOpened);
	};

	// onFocus handler to determine if the accordion item is expanded
	const handleFocus = (eventKey) => {
		if (openedItems.includes(eventKey)) {
			if (bodyRefs.current[eventKey]) {
				srAnnounce(bodyRefs.current[eventKey].textContent);
			}
		} else {
			if (bodyRefs.current[eventKey]) {
				srAnnounce(`${bodyRefs.current[eventKey].textContent}`);
			}
		}
	};

	// Define the FAQ items
	const faqItems = [
		{ question: "faq-question-1", answer: "faq-answer-1" },
		{ question: "faq-question-2", answer: "faq-answer-2" },
		{ question: "faq-question-3", answer: "faq-answer-3" },
		{ question: "faq-question-4", answer: "faq-answer-4" },
		{
			question: "faq-question-5",
			answer: "faq-answer-5",
			links: [
				{ href: "https://abuse-free-sport.ca/", text: "Link 1" },
				{ href: "https://sport-sans-abus.ca/", text: "Link 2" },
			],
		},
		{
			question: "faq-question-6",
			answer: "faq-answer-6",
			links: [
				{
					href: "https://sportintegritycommissioner.ca/report",
					text: "Link 1",
				},
				{
					href: "https://commissaireintegritesport.ca/signaler",
					text: "Link 2",
				},
			],
		},
		{
			question: "faq-question-7",
			answer: "faq-answer-7",
			links: [
				{ href: "mailto:support@respectgroupinc.com", text: "Link" },
			],
		},
		{
			question: "faq-question-8",
			answer: "faq-answer-8",
			links: [{ href: "https://sportconsent.ca/profile", text: "Link" }],
		},
	];

	return (
		<PostLogin
			maxWidth={"1400px"}
			alignCenter={true}
			ariaLabel={i18n.t("help-faq-header")}
			ariaDescription={i18n.t("help-info")}
		>
			<div className="page-header mb-3 mt-2 badge">
				<h1>{i18n.t("help-faq-header")}</h1>
			</div>
			<Row className={`${styles["help-container"]} g-0`}>
				<Col md={6} className="mt-2 mb-4">
					<h4 role="heading" aria-level={2} className="fw-normal">
						{i18n.t("about-this-page")}
					</h4>
					<span className="fw-normal text-muted">
						{i18n.t("help-info")}
					</span>
				</Col>
				<Col md={12}>
					<Accordion
						alwaysOpen
						defaultActiveKey={["0"]}
						onSelect={handleToggle}
					>
						{faqItems.map((item, index) => (
							<Accordion.Item
								className="rounded mb-4"
								eventKey={`${index}`}
								aria-label={i18n
									.t("faq-question-aria-label")
									.replace("{currentQuestion}", index + 1)
									.replace(
										"{totalQuestions}",
										faqItems.length
									)}
							>
								<Accordion.Header
									role="none"
									onFocus={() => handleFocus(`${index}`)}
								>
									{i18n.t(item.question)}
								</Accordion.Header>
								<Accordion.Body
									ref={(el) =>
										(bodyRefs.current[`${index}`] = el)
									}
									tabIndex="-1"
								>
									{item.links ? (
										<Trans
											i18nKey={item.answer}
											components={item.links.map(
												(link) => (
													<Link
														to={link.href}
														target="_blank"
														rel="noopener noreferrer"
													/>
												)
											)}
										/>
									) : (
										i18n.t(item.answer)
									)}
								</Accordion.Body>
							</Accordion.Item>
						))}
					</Accordion>
				</Col>
			</Row>
		</PostLogin>
	);
}

export default HelpPage;
