import { Form, InputGroup } from "react-bootstrap";

function HasImpediments({
	label,
	labelNo,
	labelYes,
	autoComplete,
	onChange,
	onBlur,
	noChecked,
	yesChecked,
	disabled = false,
	formik,
}) {
	return (
		<>
			<label className="fw-bold" id="hasImpedimentsLabel">
				{label}
			</label>
			<InputGroup
				role="radiogroup"
				key="inline-radio"
				className="mt-2 d-block mx-auto"
			>
				<Form.Check
					inline
					label={labelYes}
					id="hasImpedimentsTrue"
					name="hasImpedimentsRadio"
					type="radio"
					defaultValue={1}
					autoComplete={autoComplete}
					onChange={onChange}
					onBlur={onBlur}
					checked={yesChecked}
					disabled={disabled}
					aria-labelledby="hasImpedimentsLabel invalid-feedback-hasImpediments hasImpedimentsTrue"
				/>
				<Form.Check
					inline
					label={labelNo}
					id="hasImpedimentsFalse"
					name="hasImpedimentsRadio"
					type="radio"
					defaultValue={0}
					autoComplete={autoComplete}
					onChange={onChange}
					onBlur={onBlur}
					checked={noChecked}
					disabled={disabled}
					aria-labelledby="hasImpedimentsLabel invalid-feedback-hasImpediments hasImpedimentsFalse"
				/>
			</InputGroup>
			{formik.touched.hasImpedimentsRadio &&
				formik.errors.hasImpedimentsRadio && (
					<div
						id="invalid-feedback-hasImpediments"
						type="invalid"
						className="invalid-feedback-select"
					>
						{formik.errors.hasImpedimentsRadio}
					</div>
				)}
		</>
	);
}

export default HasImpediments;
