import React from "react";
import { searchAdminActivityReport } from "features/user/userAPI";
import { useSportsOrganizations } from "hooks/useSportsOrganizations";
import { useDataTable } from "hooks/useDataTable";
import { useCheckboxTree } from "hooks/useCheckboxTree";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReportTable from "../ReportTable/ReportTable";
import ReportCheckboxTree from "../ReportCheckboxTree/ReportCheckboxTree";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import styles from "./AdminActivityReport.module.css";

function AdminActivityReport() {
	const { i18n } = useTranslation();
	const sportOrganizations = useSportsOrganizations();

	const initialFilters = {
		admin_first_name: "",
		admin_last_name: "",
		admin_email: "",
		user_first_name: "",
		user_last_name: "",
		user_email: "",
		action: "",
		date_start: "",
		date_end: "",
		sorting: [{ field: "admin_first_name", order: "asc" }],
		page: 1,
		per_page: 50,
		sport_organization_id: [],
	};

	const {
		filters,
		setFilters,
		reportData,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
		handleSearch,
		hasFetched,
		isLoading,
		handleExport,
	} = useDataTable(initialFilters, searchAdminActivityReport);

	const { checked, expanded, setExpanded, treeNodes, handleTreeChange, filterText, setFilterText } = useCheckboxTree(
		sportOrganizations,
		setFilters
	);

	const columns = [
		{
			name: <div>{i18n.t("report-type")}</div>,
			exportKey: "report-type",
			selector: (row) => row.report_type,
			sortable: true,
			sortField: "report_type",
			cell: (row) => <div>{i18n.t(row.report_type)}</div>,
		},
		{
			name: <div>{i18n.t("admin-first-name")}</div>,
			exportKey: "admin-first-name",
			selector: (row) => row.admin_first_name,
			sortable: true,
			sortField: "admin_first_name",
			cell: (row) => <div>{row.admin_first_name}</div>,
		},
		{
			name: <div>{i18n.t("admin-last-name")}</div>,
			exportKey: "admin-last-name",
			selector: (row) => row.admin_last_name,
			sortable: true,
			sortField: "admin_last_name",
			cell: (row) => <div>{row.admin_last_name}</div>,
		},
		{
			name: <div>{i18n.t("admin-email")}</div>,
			exportKey: "admin-email",
			selector: (row) => row.admin_email,
			sortable: true,
			sortField: "admin_email",
			cell: (row) => <div>{row.admin_email}</div>,
			minWidth: "250px",
		},
		{
			name: <div>{i18n.t("admin-username")}</div>,
			exportKey: "admin-username",
			selector: (row) => row.admin_username,
			sortable: true,
			sortField: "admin_username",
			cell: (row) => <div>{row.admin_username}</div>,
		},
		{
			name: <div>{i18n.t("first-name")}</div>,
			exportKey: "first-name",
			selector: (row) => row.user_first_name,
			sortable: true,
			sortField: "user_first_name",
			cell: (row) => <div>{row.user_first_name}</div>,
		},
		{
			name: <div>{i18n.t("last-name")}</div>,
			exportKey: "last-name",
			selector: (row) => row.user_last_name,
			sortable: true,
			sortField: "user_last_name",
			cell: (row) => <div>{row.user_last_name}</div>,
		},
		{
			name: <div>{i18n.t("email")}</div>,
			exportKey: "email",
			selector: (row) => row.user_email,
			sortable: true,
			sortField: "user_email",
			cell: (row) => <div>{row.user_email}</div>,
			minWidth: "250px",
		},
		{
			name: <div>{i18n.t("username")}</div>,
			exportKey: "username",
			selector: (row) => row.user_username,
			sortable: true,
			sortField: "user_username",
			cell: (row) => <div>{row.user_username}</div>,
		},
		{
			name: <div>{i18n.t("action")}</div>,
			exportKey: "action",
			selector: (row) => row.action,
			sortable: true,
			sortField: "action",
			cell: (row) => <div>{row.action}</div>,
		},
	];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container fluid>
			<h5 className="fw-normal">{i18n.t("admin-activity-report")}</h5>
			<div className="fw-normal text-muted mb-4">{i18n.t("admin-administrators-activity-report-description")}</div>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					handleSearch();
				}}
			>
				<Row className="form-group bg-light border rounded mb-4 p-3">
					<h6 className="fw-normal mb-4">{i18n.t("report-filters")}</h6>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_first_name">{i18n.t("participant-first-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_first_name"
							id="participant_first_name"
							placeholder={i18n.t("admin-enter-participant-first-name")}
							value={filters.participant_first_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_last_name">{i18n.t("participant-last-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_last_name"
							id="participant_last_name"
							placeholder={i18n.t("admin-enter-participant-last-name")}
							value={filters.participant_last_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_email">{i18n.t("participant-email-label")}</label>
						<Form.Control
							type="text"
							name="participant_email"
							id="participant_email"
							placeholder={i18n.t("admin-enter-participant-email")}
							value={filters.participant_email}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="activity_date_start">{i18n.t("activity-date-start")}</label>
						<DatePicker
							selected={filters.date_start}
							onChange={(date) =>
								setFilters((prev) => ({
									...prev,
									date_start: date,
								}))
							}
							dateFormat="yyyy-MM-dd"
							placeholderText={i18n.t("admin-enter-admin-activity-date-start")}
							className="form-control"
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							peekNextMonth
							locale={i18n.language === "fr" ? fr : undefined}
							name="activity_date_start"
							id="activity_date_start"
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="activity_date_end">{i18n.t("activity-date-end")}</label>
						<DatePicker
							selected={filters.date_end}
							onChange={(date) =>
								setFilters((prev) => ({
									...prev,
									date_end: date,
								}))
							}
							dateFormat="yyyy-MM-dd"
							placeholderText={i18n.t("admin-enter-admin-activity-date-end")}
							className="form-control"
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							peekNextMonth
							locale={i18n.language === "fr" ? fr : undefined}
							name="activity_date_end"
							id="activity_date_end"
						/>
					</Col>
					<ReportCheckboxTree
						nodes={treeNodes}
						checked={checked}
						expanded={expanded}
						onCheck={handleTreeChange}
						onExpand={setExpanded}
						filterText={filterText}
						onFilterChange={setFilterText}
					/>
				</Row>
				<ReportTable
					columns={columns}
					handleSearch={handleSearch}
					handleExport={handleExport}
					reportData={reportData}
					handleSort={handleSort}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
					isLoading={isLoading}
					hasFetched={hasFetched}
				/>
			</Form>
		</Container>
	);
}

export default AdminActivityReport;
