import React from "react";
import CheckboxTree from "react-checkbox-tree";
import { Row, Col, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare, faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import styles from "./AdminAccessSportOrganizationTree.module.css";

function AdminAccessSportOrganizationTree({ id, name, label, nodes, checked, expanded, onCheck, onExpand, filterText, onFilterChange }) {
	const { i18n } = useTranslation();
	const icons = {
		check: <FontAwesomeIcon icon={faCheckSquare} />,
		uncheck: <FontAwesomeIcon icon={faSquare} />,
		halfCheck: <FontAwesomeIcon icon={faMinusSquare} />,
		expandClose: <FontAwesomeIcon icon={faPlusSquare} />,
		expandOpen: <FontAwesomeIcon icon={faMinusSquare} />,
		expandAll: <></>,
		collapseAll: <></>,
		parentClose: <></>,
		parentOpen: <></>,
		leaf: <></>,
	};

	return (
		<>
			<Row>
				<Col md={8}>
					<label htmlFor={name} className={`${styles.formLabel}`}>
						{label}
					</label>
				</Col>
				<Col md={4} className="mb-0">
					<FormControl
						className={`${styles.formControl}`}
						type="text"
						value={filterText}
						onChange={(e) => onFilterChange(e.target.value)}
						placeholder={i18n.t("filter-organizations")}
					/>
				</Col>
			</Row>
			<Row>
				<CheckboxTree
					nodes={nodes}
					checked={checked}
					expanded={expanded}
					onCheck={onCheck}
					onExpand={onExpand}
					icons={icons}
				/>
			</Row>
		</>
	);
}

export default AdminAccessSportOrganizationTree;
