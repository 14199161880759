import React from "react";
import { searchAdminReport } from "features/user/userAPI";
import { useSportsOrganizations } from "hooks/useSportsOrganizations";
import { useDataTable } from "hooks/useDataTable";
import { useCheckboxTree } from "hooks/useCheckboxTree";
import { Form, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AdminLevelOptions } from "features/user/userOptions";
import ReportTable from "../ReportTable/ReportTable";
import ReportCheckboxTree from "../ReportCheckboxTree/ReportCheckboxTree";
import ReportAdminLevel from "../ReportAdminLevel/ReportAdminLevel";
import styles from "./AdministratorReport.module.css";
import Province from "../Province/Province";
function AdministratorReport() {
	const { i18n } = useTranslation();
	const sportOrganizations = useSportsOrganizations();

	const initialFilters = {
		participant_first_name: "",
		participant_last_name: "",
		participant_email: "",
		province: "",
		sorting: [{ field: "participant_first_name", order: "asc" }],
		page: 1,
		per_page: 50,
		sport_organization_id: [],
		admin_level: "",
	};

	const {
		filters,
		setFilters,
		reportData,
		handleSort,
		handlePageChange,
		handleRowsPerPageChange,
		handleSearch,
		hasFetched,
		isLoading,
		handleExport,
	} = useDataTable(initialFilters, searchAdminReport);

	const { checked, expanded, setExpanded, treeNodes, handleTreeChange, filterText, setFilterText } = useCheckboxTree(
		sportOrganizations,
		setFilters
	);

	const translateAdminLevel = (adminLevel) => {
		const adminLevelOption = AdminLevelOptions.find((option) => option.optionKey === adminLevel);
		return adminLevelOption ? i18n.t(adminLevelOption.translationKey) : "";
	};

	const handleAdminLevelSelect = (adminLevel) => {
		setFilters((prev) => ({ ...prev, admin_level: adminLevel }));
	};

	const columns = [
		{
			name: <div>{i18n.t("report-type")}</div>,
			exportKey: "report-type",
			selector: (row) => row.report_type,
			sortable: true,
			sortField: "report_type",
			cell: (row) => <div>{i18n.t(row.report_type)}</div>,
		},
		{
			name: <div>{i18n.t("first-name")}</div>,
			exportKey: "first-name",
			selector: (row) => row.participant_first_name,
			sortable: true,
			sortField: "participant_first_name",
			cell: (row) => <div>{row.participant_first_name}</div>,
		},
		{
			name: <div>{i18n.t("last-name")}</div>,
			exportKey: "last-name",
			selector: (row) => row.participant_last_name,
			sortable: true,
			sortField: "participant_last_name",
			cell: (row) => <div>{row.participant_last_name}</div>,
		},
		{
			name: <div>{i18n.t("email")}</div>,
			exportKey: "email",
			selector: (row) => row.participant_email,
			sortable: true,
			sortField: "participant_email",
			cell: (row) => <div>{row.participant_email}</div>,
			minWidth: "250px",
		},
		{
			name: <div>{i18n.t("username")}</div>,
			exportKey: "username",
			selector: (row) => row.username,
			sortable: true,
			sortField: "username",
			cell: (row) => <div>{row.username}</div>,
		},
		{
			name: <div>{i18n.t("date-of-birth")}</div>,
			exportKey: "date-of-birth",
			selector: (row) => row.date_of_birth,
			sortable: true,
			sortField: "date_of_birth",
			cell: (row) => <div>{row.date_of_birth}</div>,
		},
		{
			name: <div>{i18n.t("province")}</div>,
			exportKey: "province",
			selector: (row) => row.province,
			sortable: true,
			sortField: "province",
			cell: (row) => <div>{row.province}</div>,
		},
		{
			name: <div>{i18n.t("sport-organization-name")}</div>,
			exportKey: "sport-organization-name",
			selector: (row) => row.sport_organization_name,
			sortable: true,
			sortField: "sport_organization_name",
			cell: (row) => <div>{row.sport_organization_name}</div>,
		},
		{
			name: <div>{i18n.t("language")}</div>,
			exportKey: "language",
			selector: (row) => row.language,
			sortable: true,
			sortField: "language",
			cell: (row) => <div>{row.language}</div>,
		},
		{
			name: <div>{i18n.t("admin-level")}</div>,
			exportKey: "admin-level",
			selector: (row) => row.admin_level,
			sortable: true,
			sortField: "admin_level",
			cell: (row) => <div>{translateAdminLevel(row.admin_level)}</div>,
		},
	];

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	return (
		<Container fluid>
			<h5 className="fw-normal">{i18n.t("administrator-report")}</h5>
			<div className="fw-normal text-muted mb-4">{i18n.t("admin-administrators-report-description")}</div>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					handleSearch();
				}}
			>
				<Row className="form-group bg-light border rounded mb-4 p-3">
					<h6 className="fw-normal mb-4">{i18n.t("report-filters")}</h6>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_first_name">{i18n.t("participant-first-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_first_name"
							id="participant_first_name"
							placeholder={i18n.t("admin-enter-participant-first-name")}
							value={filters.participant_first_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_last_name">{i18n.t("participant-last-name-label")}</label>
						<Form.Control
							type="text"
							name="participant_last_name"
							id="participant_last_name"
							placeholder={i18n.t("admin-enter-participant-last-name")}
							value={filters.participant_last_name}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="participant_email">{i18n.t("participant-email-label")}</label>
						<Form.Control
							type="text"
							name="participant_email"
							id="participant_email"
							placeholder={i18n.t("admin-enter-participant-email")}
							value={filters.participant_email}
							onChange={handleInputChange}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="province">{i18n.t("province-label")}</label>
						<Province
							province={filters.province}
							setProvince={(value) => setFilters(prev => ({ ...prev, province: value }))}
						/>
					</Col>
					<Col md={6} lg={4} className="mb-3">
						<label className="form-label" htmlFor="admin_level">{i18n.t("admin-level")}</label>
						<ReportAdminLevel onAdminLevelSelect={handleAdminLevelSelect} />
					</Col>
					<ReportCheckboxTree
						nodes={treeNodes}
						checked={checked}
						expanded={expanded}
						onCheck={handleTreeChange}
						onExpand={setExpanded}
						filterText={filterText}
						onFilterChange={setFilterText}
					/>
				</Row>
				<ReportTable
					columns={columns}
					handleSearch={handleSearch}
					handleExport={handleExport}
					reportData={reportData}
					handleSort={handleSort}
					handlePageChange={handlePageChange}
					handleRowsPerPageChange={handleRowsPerPageChange}
					isLoading={isLoading}
					hasFetched={hasFetched}
				/>
			</Form>
		</Container>
	);
}

export default AdministratorReport;
