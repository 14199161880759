import { forwardRef } from "react";
import Select, { components } from "react-select";
import { useTranslation } from "react-i18next";
import { srAnnounce } from "features/global/functions";

function SportOrganization(
	{
		sportSelectedID,
		sportOrganizations,
		id = "userSports",
		name = "userSports",
		label,
		index,
		totalCount,
		placeholder,
		onChange,
		onBlur,
		isInvalid,
		disabled = false,
		isOptionDisabled,
		formik,
	},
	ref
) {
	const { i18n } = useTranslation();

	const handleChange = (e) => {
		srAnnounce(i18n.t("option-selected").replace("{label}", e.label), true);
		if (ref.current) {
			setTimeout(() => {
				ref.current.focus();
			}, 100);
		}
		onChange(e);
	};

	var sportSelected = null;
	if (sportSelectedID !== undefined && sportSelectedID !== "") {
		for (const org of sportOrganizations) {
			if (sportSelectedID == org.id) {
				sportSelected = { value: org.id, label: org.name };
			}
		}
	}

	// Recursively create option groups for the Sport Selection select list
	let optGroups = [];
	const getGroupNodes = (orgs, parentID = 0, isRoot = false) => {
		let nodes = orgs.filter((org) => org.parent_id === parentID);
		nodes = nodes.map((org) => {
			const childNodes = getGroupNodes(orgs, org.id);
			return {
				value: org.id,
				label: org.name,
				...(childNodes.length > 0 && { children: childNodes }),
			};
		});

		// Find parent node and assign their name as the group label
		let groupLabel = i18n.t("remaining-organizations");
		if (nodes.length > 0 && !isRoot) {
			const org = orgs.find((org) => org.id == parentID);
			groupLabel = org.name;
		}

		// Filter out nodes in the root group that have children -- only return nodes that have no children
		if (isRoot) nodes = nodes.filter((node) => !node.children);

		// Add each option group to the final array
		optGroups.push({
			label: groupLabel,
			options: nodes,
		});

		return nodes;
	};
	getGroupNodes(sportOrganizations, undefined, true);

	// Custom Styles for react-select dropdown and vailation errors
	const customStyles = {
		control: (base, state) => ({
			...base,
			borderColor:
				formik.errors &&
				formik.errors.userSports &&
				formik.touched &&
				formik.touched.userSports &&
				formik.values.userSports[index].name.trim() === ""
					? "var(--bs-form-invalid-border-color)"
					: state.isFocused
					? "#80afb0"
					: base.borderColor,
			"&:hover": {
				borderColor:
					formik.errors &&
					formik.errors.userSports &&
					formik.touched &&
					formik.touched.userSports &&
					formik.values.userSports[index].name.trim() === ""
						? "var(--bs-form-invalid-border-color)"
						: base.borderColor,
			},
			boxShadow:
				state.isFocused &&
				formik.errors &&
				formik.errors.userSports &&
				formik.touched &&
				formik.touched.userSports &&
				formik.values.userSports[index].name.trim() === ""
					? "0 0 0 0.25rem rgba(220, 53, 69, 0.25)" // Bootstrap danger color with 25% opacity
					: state.isFocused
					? "0 0 0 0.25rem rgba(0, 95, 97, 0.25)"
					: base.boxShadow,
		}),
		indicatorSeparator: (base, state) => ({
			...base,
			backgroundColor:
				formik.errors &&
				formik.errors.userSports &&
				formik.touched &&
				formik.touched.userSports &&
				formik.values.userSports[index].name.trim() === ""
					? "var(--bs-form-invalid-border-color)"
					: base.backgroundColor,
		}),
		dropdownIndicator: (base, state) => ({
			...base,
			color:
				formik.errors &&
				formik.errors.userSports &&
				formik.touched &&
				formik.touched.userSports &&
				formik.values.userSports[index].name.trim() === ""
					? "var(--bs-form-invalid-border-color)"
					: base.color,
			"&:hover": {
				color:
					formik.errors &&
					formik.errors.userSports &&
					formik.touched &&
					formik.touched.userSports &&
					formik.values.userSports[index].name.trim() === ""
						? "var(--bs-form-invalid-border-color)"
						: base.color,
			},
		}),
	};

	return (
		<>
			<label
				htmlFor={name}
				id={`${id}Label`}
				className="form-label"
				aria-label={`${label} (${index + 1} of ${totalCount})`}
			>
				{label}
			</label>
			<Select
				styles={customStyles}
				className={
					disabled ? "react-select-disabled" : "react-select-enabled"
				}
				value={sportSelected}
				id={id}
				name={name}
				options={optGroups}
				placeholder={
					<div className="sport-organization-placeholder-text">
						{placeholder}
					</div>
				}
				onChange={handleChange}
				onBlur={onBlur}
				isInvalid={isInvalid}
				isDisabled={disabled}
				isOptionDisabled={isOptionDisabled}
				required
				aria-labelledby={`${id}-invalid-feedback ${id}Label`}
				ref={ref} // Forwarding ref to Select component
			/>
		</>
	);
}

export default forwardRef(SportOrganization);
