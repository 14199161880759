import { Modal, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

function MailedInConsentModal({
	show,
	onHide,
	backdrop = "static",
	keyboard,
	onDecline,
	onAccept,
}) {
	const { i18n } = useTranslation();

	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				backdrop={backdrop}
				keyboard={keyboard}
				aria-labelledby="mailedin-consent-modal-title"
				aria-describedby="mailedin-consent-modal-body"
			>
				<Modal.Header>
					<Modal.Title
						className="h5"
						id="mailedin-consent-modal-title"
					>
						Notice
					</Modal.Title>
				</Modal.Header>
				<Modal.Body id="mailedin-consent-modal-body">
					<Trans
						i18nKey="mailed-in-consent-modal-body"
						components={[<p />, <p className="mb-0"></p>]}
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onDecline} autofocus>
						{i18n.t("mailed-in-consent-modal-footer-decline")}
					</Button>
					<Button variant="primary" onClick={onAccept}>
						{i18n.t("mailed-in-consent-modal-footer-accept")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default MailedInConsentModal;
