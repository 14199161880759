import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectProfile, setProfile } from "features/user/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { getProfile } from "features/user/userAPI";
import config from "app/config";
import styles from "./Player.module.css";
import { srAnnounce } from "features/global/functions";

function Player({ updateProgress }) {
	const { i18n } = useTranslation();
	const dispatch = useDispatch();
	const profile = useSelector(selectProfile);
	const [iframeUrl, setIframeUrl] = useState("");

	useEffect(() => {
		const fetchProfile = async () => {
			try {
				const result = await getProfile();
				if (result && !result.error) {
					dispatch(setProfile(result.data));
				} else {
					alert("Error fetching user data.");
				}
			} catch (error) {
				alert("An error occurred while fetching user data.");
			}
		};
		fetchProfile();
	}, [dispatch, i18n.language]);

	useEffect(() => {
		if (profile) {
			setIframeUrl(getIframeUrl());
		}
	}, [profile]);

	const getDomainFromURL = (url) => {
		const anchorElement = document.createElement("a");
		anchorElement.href = url;
		return anchorElement.hostname;
	};

	const getIframeUrl = () => {
		if (!profile) {
			return "";
		}
		const language = i18n.language;
		let url = profile.guardian_email
			? language === "fr"
				? config.IFRAME_GUARDIAN_URL_FR
				: config.IFRAME_GUARDIAN_URL_EN
			: language === "fr"
			? config.IFRAME_PARTICIPANT_URL_FR
			: config.IFRAME_PARTICIPANT_URL_EN;
		const trackingKey = encodeURIComponent(profile.tracking_key);
		const trackingSig = encodeURIComponent(profile.tracking_sig);
		url += `?trackingKey=${trackingKey}&trackingSig=${trackingSig}`;
		return url;
	};

	useEffect(() => {
		const handleMessage = (event) => {
			if (profile) {
				const eventDomain = getDomainFromURL(event.origin);
				const iframeDomain = getDomainFromURL(
					getIframeUrl(profile.guardian_email)
				);
				if (eventDomain !== iframeDomain) {
					return;
				}
				updateProgress(event.data);
			}
		};
		window.addEventListener("message", handleMessage);
		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, [profile]);

	useEffect(() => {
		srAnnounce(i18n.t("program-player-loading"));
		// Hide the footer element
		const footer = document.querySelector("footer");
		if (footer) {
			footer.style.display = "none";
		}
	}, []); // This effect runs only once when the component mounts

	return (
		<div>
			{profile && (
				<div
					className={styles.iframeContainer}
					style={{
						backgroundImage: "url(/images/spinner.gif)",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "auto clamp(10px, 100%, 228px)",
					}}
				>
					<iframe
						src={iframeUrl}
						onLoad={() =>
							srAnnounce(
								i18n.t("program-player-loading-finished")
							)
						}
						className={styles.iframeStyle}
						allow="autoplay; fullscreen"
						frameBorder="0"
						scrolling="no"
						title=""
					></iframe>
				</div>
			)}
		</div>
	);
}

export default Player;
