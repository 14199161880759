import React, { useState, useEffect, useRef } from "react";
import SportOrganization from "./SportOrganization";
import SportMembershipNumber from "./SportMembershipNumber";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FieldArray, FormikProvider } from "formik";
import { useTranslation } from "react-i18next";
import styles from "../../pages/PreLogin/Registration/Registration.module.css";
import { srAnnounce } from "features/global/functions";

function SportSelection({
	sportCounter,
	isLoading,
	formik,
	sportOrganizations,
	allowZeroItems = false,
	disabled,
}) {
	const { i18n } = useTranslation();
	const sportSelectionRefs = useRef([]);

	const addSport = (arrayHelpers) => {
		arrayHelpers.push({
			id: "prefix" + sportCounter.current++,
			name: "",
			sport_organization_membership_number: "",
		});
		let totalSportOrgs = formik.values.userSports.length;
		totalSportOrgs++;
		srAnnounce(
			i18n.t("sr-announce-sport-organization-added") +
				"; " +
				i18n
					.t("sr-announce-sport-organization-total")
					.replace("{total}", totalSportOrgs),
			true
		); // call with "assertive"
		setTimeout(() => {
			sportSelectionRefs?.current?.[
				formik.values.userSports.length
			]?.current?.focus();
		}, 300);
	};

	// Custom useEffect to focus react-select elements and announce errors to screen reader
	useEffect(() => {
		if (!formik.isValid && formik.isSubmitting) {
			// Focus react-select elements if they are first item in errors object
			if (Object.keys(formik.errors)[0] == "userSports") {
				// Find the first field with an empty name value
				const firstErrorIndex = formik.values.userSports.findIndex(
					(sport) => !sport.name
				);
				if (firstErrorIndex !== -1) {
					setTimeout(() => {
						setTimeout(() => {
							sportSelectionRefs?.current?.[
								firstErrorIndex
							]?.current?.focus();
						}, 600); // match value of focusDelay timeout for FocusError component
					});
				}
			}
		}
	}, [
		formik.errors,
		formik.isValid,
		formik.isSubmitting,
		formik.values,
		sportSelectionRefs,
		i18n,
	]);

	return (
		<FormikProvider value={formik}>
			<FieldArray
				name="userSports"
				render={(arrayHelpers) => {
					// Update refs based on the number of fields
					formik.values.userSports.forEach((_, index) => {
						if (!sportSelectionRefs.current[index]) {
							sportSelectionRefs.current[index] =
								React.createRef();
						}
					});

					return (
						<div>
							{formik.values.userSports.map((sport, index) => {
								const isNoSelection = !sport.name;
								return (
									<Container
										className="mt-3 p-3 mw-100 bg-light border rounded position-relative"
										key={sport.id}
									>
										<Row className="g-3 align-items-center">
											<Col
												lg={6}
												id={`sport-selection-col-${index}`}
											>
												<SportOrganization
													index={index}
													totalCount={
														formik.values.userSports
															.length
													}
													sportSelectedID={sport.id}
													id={`userSports.${index}.id`}
													name={`userSports.${index}.id`}
													sportOrganizations={
														sportOrganizations
													}
													placeholder={i18n.t(
														"sport-selection-placeholder"
													)}
													ariaLabel="1 of 2"
													label={i18n.t(
														"sport-selection-label"
													)}
													onChange={async (e) => {
														await formik.setFieldValue(
															`userSports.${index}.id`,
															e.value
														);
														await formik.setFieldValue(
															`userSports.${index}.name`,
															e.label
														);
														formik.validateField(
															`userSports.${index}.name`
														);
													}}
													onBlur={() => {
														formik.setFieldTouched(
															"participantSport"
														);
													}}
													isInvalid={
														formik.touched
															.participantSport &&
														formik.errors
															.participantSport
													}
													disabled={
														isLoading || disabled
													}
													isOptionDisabled={(
														option
													) => {
														const exists = (
															element
														) =>
															element.id ==
															option.value;
														return formik.values.userSports.some(
															exists
														);
													}}
													required
													ref={
														sportSelectionRefs
															.current[index]
													}
													formik={formik}
												/>
											</Col>
											<Col lg={6}>
												<SportMembershipNumber
													value={
														formik.values
															.userSports[index]
															.sport_organization_membership_number
													}
													placeholder={i18n.t(
														"sport-membership-number-placeholder"
													)}
													label={i18n.t(
														"sport-membership-number"
													)}
													ariaDescribedBy={
														isNoSelection
															? "no-sport-org-selected"
															: `userSports.${index}.id`
													}
													id={`userSports.${index}.sport_organization_membership_number`}
													name={`userSports.${index}.sport_organization_membership_number`}
													optionalText={i18n.t(
														"optional-label"
													)}
													aria-label={i18n.t(
														"sport-membership-number-placeholder"
													)}
													onChange={
														formik.handleChange
													}
													onBlur={formik.handleBlur}
													isInvalid={
														formik.touched
															.sportMembershipNumber &&
														formik.errors
															.sportMembershipNumber
													}
													disabled={
														isLoading || disabled
													}
												></SportMembershipNumber>
											</Col>
											{!disabled &&
												(formik.values.userSports
													.length > 1 ||
													allowZeroItems) && (
													<Button
														className="btn btn-sm m-0 p-0 rounded-circle"
														variant="danger"
														disabled={isLoading}
														aria-label={i18n.t(
															"sport-selection-remove-title"
														)}
														aria-describedby={
															isNoSelection
																? "no-sport-org-selected"
																: `userSports.${index}.id`
														}
														onClick={() => {
															if (
																formik.values
																	.userSports
																	.length >
																	1 ||
																allowZeroItems
															)
																arrayHelpers.remove(
																	index
																);
															let totalSportOrgs =
																formik.values
																	.userSports
																	.length;
															totalSportOrgs--;
															srAnnounce(
																(!isNoSelection
																	? sport.name +
																	  " "
																	: " ") +
																	i18n.t(
																		"sr-announce-sport-organization-removed"
																	) +
																	"; " +
																	i18n
																		.t(
																			"sr-announce-sport-organization-total"
																		)
																		.replace(
																			"{total}",
																			totalSportOrgs
																		),
																true // call with assertive
															);
															setTimeout(() => {
																setTimeout(
																	() => {
																		sportSelectionRefs?.current?.[0]?.current?.focus();
																	},
																	300
																);
															});
														}}
														style={{
															position:
																"absolute",
															top: "-7px",
															right: "-7px",
															width: "24px",
														}}
													>
														<span
															style={{
																verticalAlign:
																	"text-bottom",
																top: "-1px",
																position:
																	"relative",
															}}
														>
															x
														</span>
													</Button>
												)}
										</Row>
										<Row>
											<Col>
												{formik.errors &&
													formik.errors.userSports &&
													formik.errors.userSports[
														index
													] &&
													formik.errors.userSports[
														index
													].name &&
													formik.touched.userSports &&
													formik.touched.userSports[
														index
													] && (
														<div
															type="invalid"
															className="invalid-feedback-select col-6"
															id={`userSports.${index}.id-invalid-feedback`}
														>
															{
																formik.errors
																	.userSports[
																	index
																].name
															}
														</div>
													)}
											</Col>
											<Col>
												{formik.errors &&
													formik.errors.userSports &&
													formik.errors.userSports[
														index
													] &&
													formik.errors.userSports[
														index
													]
														.sport_organization_membership_number &&
													formik.touched.userSports &&
													formik.touched.userSports[
														index
													] && (
														<div
															type="invalid"
															className="invalid-feedback-select col-6"
														>
															{
																formik.errors
																	.userSports[
																	index
																]
																	.sport_organization_membership_number
															}
														</div>
													)}
											</Col>
										</Row>
										{isNoSelection && (
											<span
												id="no-sport-org-selected"
												className="sr-only"
											>
												{i18n.t(
													"no-sport-org-selected"
												)}
											</span>
										)}
									</Container>
								);
							})}
							{!disabled && (
								<Row className="mt-4 justify-content-center">
									<Button
										variant="pantone-5763c"
										className={`${styles["registration-add-sport-button"]} mt-2`}
										aria-label={i18n.t(
											"add-sport-button-aria-label"
										)}
										onClick={() => addSport(arrayHelpers)}
										disabled={isLoading}
									>
										{i18n.t("add-sport-button")}
									</Button>
								</Row>
							)}
						</div>
					);
				}}
			/>
		</FormikProvider>
	);
}

export default SportSelection;
