import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { ProgressOptions } from "features/user/userOptions";
import styles from "./ReportUserProgress.module.css";

function ReportUserProgress({ onProgressSelect }) {
	const { i18n } = useTranslation();
	const [state, setState] = useState({
		selectedLastProgress: null,
		selectedLastProgressIndex: -1,
		lastProgressOptions: [],
	});

	useEffect(() => {
		const options = ProgressOptions.map(({ optionKey, translationKey }) => ({
			value: optionKey,
			label: i18n.t(translationKey),
		}));

		setState((prevState) => ({
			...prevState,
			lastProgressOptions: options,
			selectedLastProgress:
				prevState.selectedLastProgressIndex !== -1 ? options[prevState.selectedLastProgressIndex] : null,
		}));
	}, [i18n.language]);

	const handleSelectLastProgress = (selectedOption) => {
		const index = selectedOption
			? state.lastProgressOptions.findIndex((option) => option.value === selectedOption.value)
			: -1;

		setState({
			...state,
			selectedLastProgressIndex: index,
			selectedLastProgress: selectedOption,
		});

		onProgressSelect(selectedOption ? selectedOption.value : null);
	};

	return (
		<Select
			value={state.selectedLastProgress}
			onChange={handleSelectLastProgress}
			options={state.lastProgressOptions}
			placeholder={i18n.t("admin-select-last-progress")}
			isClearable={true}
			className={`${styles.reportLastProgress}`}
			classNamePrefix="select"
			name="last_progress"
			id="last_progress"
		/>
	);
}

export default ReportUserProgress;
