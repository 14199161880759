import { useEffect, useState } from "react";
import moment from "moment";
import "moment/min/locales.min";
import { getSignature, getSignatureForUser } from "features/user/userAPI";
import { UserRole } from "features/user/userRole";

/* Import components */
import { Row, Col, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectProfile } from "features/user/userSlice";

function UserStatistics({ editUserProfile, adminAccessLevel = [], userID }) {
	let profile = useSelector(selectProfile);
	if (editUserProfile) {
		profile = editUserProfile;
	}

	const { i18n } = useTranslation();
	moment.locale(i18n.language);

	const [stats, setStats] = useState({
		lastLogin: "",
		lastProgress: "",
		isSigned: false,
		signedStatus: "",
		signedDate: "",
		signedBy: "",
		submissionType: "",
	});

	const [signature, setSignature] = useState();

	useEffect(() => {
		profile !== "undefined" &&
			setStats({
				// last login statistic: format with local based off current language
				lastLogin:
					i18n.language == "fr"
						? moment(profile.last_login).format(
								"Do MMMM YYYY @ h:mm a (UTC)"
						  )
						: moment(profile.last_login).format(
								"MMMM Do, YYYY @ h:mm a (UTC)"
						  ),
				// last progress statistic: return translation based off stored value
				lastProgress: (() => {
					switch (profile.last_progress) {
						case "checkmark 1":
							return i18n.t("checkpoint-1");
						case "checkmark 2":
							return i18n.t("checkpoint-2");
						case "checkmark 3":
							return i18n.t("checkpoint-3");
						case "checkmark 4":
							return i18n.t("checkpoint-4");
						case "checkmark 5":
							return i18n.t("checkpoint-5");
						case "checkmark 6":
							return i18n.t("checkpoint-6");
						case "checkmark 7":
							return i18n.t("checkpoint-7");
						case "checkmark 8":
							return i18n.t("checkpoint-8");
						case "checkmark 9":
							return i18n.t("checkpoint-9");
						case "checkmark 10":
							return i18n.t("checkpoint-10");
						case "sign":
							return i18n.t("pending-signature");
						case "download":
							return i18n.t("downloaded-and-pending-signature");
						case "completed":
						case "completion":
							return i18n.t("progress-completed");
						case "mailed_in":
							return i18n.t("progress-mailed-in");
						default:
							return i18n.t("progress-not-started");
					}
				})(),
				// isSigned boolean: set true/false if user has signed consent or not
				isSigned: profile.consents.length ? true : false,
				// signed statistic: return language translation based off user has signed consent or not
				signedStatus: !profile.consents.length
					? i18n.t("signed-no")
					: i18n.t("signed-yes"),
				// signed date statistic: return datetime value when user had last consented
				signedDate: !profile.consents.length
					? ""
					: i18n.language == "fr"
					? moment(
							profile.consents[profile.consents.length - 1]
								.created_at
					  ).format("Do MMMM YYYY @ h:mm a (UTC)")
					: moment(
							profile.consents[profile.consents.length - 1]
								.created_at
					  ).format("MMMM Do, YYYY @ h:mm a (UTC)"),
				// signed by statistic
				signedBy: profile.guardian_email
					? i18n.t("guardian-signed")
					: i18n.t("participant-signed"),
				// submission type statistic
				submissionType: profile.submitted_manually
					? i18n.t("mailed-in-submission")
					: i18n.t("signed-online-submission"),
			});
	}, [i18n.language, editUserProfile]);

	const handleDownload = async (event) => {
		if (event.key && event.key !== "Enter") return;

		const url = window.URL.createObjectURL(new Blob([signature]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "signature.pdf");
		document.body.appendChild(link);
		link.click();
	};

	useEffect(() => {
		if (userID && !adminAccessLevel.includes(UserRole.LEVEL_2)) return; // prevent 500 error when level 1 admin accesses edit user page

		const downloadSignature = async () => {
			if (stats.isSigned && profile.last_progress != "mailed_in") {
				let signature;
				if (userID) {
					signature = await getSignatureForUser(userID);
				} else {
					signature = await getSignature();
				}
				setSignature(signature);
			}
		};

		downloadSignature();
	}, [stats.isSigned, i18n.language, userID]);

	return (
		<Row className="profile-stats bg-sdrcc border rounded gx-0 mb-3 p-3 p-sm-1">
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("stats-last-login")}</div>
				<div>
					<strong>{stats.lastLogin}</strong>
				</div>
			</Col>
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("stats-last-progress")}</div>
				<div>
					<strong>{stats.lastProgress}</strong>
				</div>
			</Col>
			<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
				<div>{i18n.t("stats-signed")}</div>
				<div>
					<strong>{stats.signedStatus}</strong>
				</div>
			</Col>
			{stats.isSigned && (
				<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
					<div>{i18n.t("stats-signed-date")}</div>
					<div>
						<strong>{stats.signedDate}</strong>
					</div>
				</Col>
			)}
			{stats.isSigned && (
				<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
					<div>{i18n.t("stats-submission-type")}</div>
					<div>
						<strong>{stats.submissionType}</strong>
					</div>
				</Col>
			)}
			{stats.isSigned && (
				<Col md={3} sm={6} xs={12} className="p-1 p-sm-2">
					<div>{i18n.t("stats-signed-by")}</div>
					<div>
						<strong>{stats.signedBy}</strong>
					</div>
				</Col>
			)}
			{stats.isSigned && (
				<Col md={3} sm={6} xs={12} className="p-1 p-sm-2"></Col>
			)}

			{/* Download button for profile page */}
			{!editUserProfile &&
				stats.isSigned &&
				profile.last_progress != "mailed_in" && (
					<Col
						md={3}
						sm={6}
						xs={12}
						className="p-1 p-sm-2 d-flex align-items-start"
					>
						<div
							onClick={(event) => handleDownload(event)}
							onKeyDown={(event) => handleDownload(event)}
							tabIndex={0}
							role="button"
							aria-label={i18n.t(
								"download-signed-form-description"
							)}
						>
							<Badge
								bg="pantone-390c"
								className="btn"
								style={{
									whiteSpace: "normal",
									lineHeight: "12px",
								}}
							>
								<FontAwesomeIcon icon={faDownload} />{" "}
								{i18n.t("download-signed-form")}
							</Badge>
						</div>
					</Col>
				)}

			{/* Download button for level 2 admins */}
			{editUserProfile &&
				adminAccessLevel &&
				adminAccessLevel.includes(UserRole.LEVEL_2) &&
				stats.isSigned &&
				profile.last_progress != "mailed_in" && (
					<Col
						md={3}
						sm={6}
						xs={12}
						className="p-1 p-sm-2 d-flex align-items-start"
					>
						<div
							onClick={(event) => handleDownload(event)}
							onKeyDown={(event) => handleDownload(event)}
							tabIndex={0}
							role="button"
							aria-label={i18n.t(
								"download-signed-form-description"
							)}
						>
							<Badge
								bg="pantone-390c"
								className="btn"
								style={{
									whiteSpace: "normal",
									lineHeight: "12px",
								}}
							>
								<FontAwesomeIcon icon={faDownload} />{" "}
								{i18n.t("download-signed-form")}
							</Badge>
						</div>
					</Col>
				)}
		</Row>
	);
}

export default UserStatistics;
