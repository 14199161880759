import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
	const { i18n } = useTranslation();
	const location = useLocation();

	return (
		<>
			<footer className="mt-4  text-muted text-center text-small container">
				<p>
					<Link
						to="https://www.headversity.com"
						target="_blank"
						rel="noopener noreferrer"
						className="text-decoration-none text-muted"
						aria-label={i18n.t("go-to-headversity")}
					>
						{location.pathname.startsWith("/language")
							? i18n.t("powered-by-multilingual")
							: i18n.t("powered-by")}
						<img
							src="/images/headversity.svg"
							className="img-fluid"
							style={{ height: 20, verticalAlign: "sub" }}
							alt={i18n.t("go-to-headversity")}
						/>
					</Link>
				</p>
			</footer>
		</>
	);
};

export default Footer;
