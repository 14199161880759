import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectProfile } from "features/user/userSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { srAnnounce } from "features/global/functions";

import { checkUsername, update } from "../../../../features/user/userAPI";
import {
	validUsername,
	validFirstLastName,
	validEmail,
} from "../../../../app/regex";
import {
	userHasSigned,
	isUserAgeOfConsent,
	userHasImpediments,
} from "features/global/functions";

/* Import components */
import { Container, Form, Col, Row, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Username from "../../../../components/Fields/Username";
import ParticipantFirstName from "../../../../components/Fields/ParticipantFirstName";
import ParticipantLastName from "../../../../components/Fields/ParticipantLastName";
import ParticipantEmail from "../../../../components/Fields/ParticipantEmail";
import Province from "../../../../components/Fields/Province";
import HasImpediments from "../../../../components/Fields/HasImpediments";
import GuardianFirstName from "../../../../components/Fields/GuardianFirstName";
import GuardianLastName from "../../../../components/Fields/GuardianLastName";
import GuardianEmail from "../../../../components/Fields/GuardianEmail";
import ParticipantDateOfBirth from "../../../../components/Fields/ParticipantDateOfBirth";
import GuardianYearOfBirth from "../../../../components/Fields/GuardianYearOfBirth";
import ParticipantViewingWithGuardian from "../../../../components/Fields/ParticipantViewingWithGuardian";
import GenericModal from "../../../../components/GenericModal";
import { FocusError } from "focus-formik-error";
import LoadingSpinner from "components/LoadingSpinner";
import styles from "../Profile.module.css";

function AccountInformation({
	language,
	submitSuccessCount,
	setSubmitSuccessCount,
}) {
	const { i18n } = useTranslation();
	const navigate = useNavigate();
	let profile = useSelector(selectProfile);

	const [formConditionals, setFormConditionals] = useState([
		{ showForm: false, showGuardianSection: false },
	]);
	const [isLoading, setIsLoading] = useState(false);
	const [showGenericModal, setShowGenericModal] = useState(false);

	const usernameInputRef = useRef(null);
	const formikValidateOnBlur = useRef(false);
	const genericModalProps = useRef({
		titleText: "",
		bodyText: "",
		footerText: "",
	});
	const signed = useRef(null);
	const userIsOfAge = useRef(null);
	const hasImpediments = useRef(null);

	// additional form validation if guardian section used
	let guardianValidationSchema = {};
	if (formConditionals.showGuardianSection) {
		guardianValidationSchema = {
			hasImpedimentsRadio: Yup.number().required(
				i18n.t("has-impediments-required")
			),
			guardianFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			guardianLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			guardianEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			guardianYearOfBirth: Yup.date()
				.required(i18n.t("year-of-birth-required"))
				.test({
					name: "guardian-yob-validation",
					message: i18n.t("invalid-guardian-yob"),
					test: (value) => {
						var ageInYears = moment().diff(value, "years");
						if (ageInYears < 19) {
							toast.dismiss();
							toast.info(i18n.t("invalid-guardian-yob"));
							formik.setFieldValue(
								"guardianYearOfBirth",
								null,
								false
							);
							return false;
						}

						return true;
					},
				}),
			participantViewingWithGuardianRadio: Yup.number().required(
				i18n.t("participant-viewing-with-guardian-required")
			),
		};
	} else {
		guardianValidationSchema = null;
	}

	let initialValues = {
		username: "",
		participantFirstName: "",
		participantLastName: "",
		participantEmail: "",
		participantDateOfBirth: "",
		participantSport: "",
		sportMembershipNumber: "",
		participantProvince: "",
		hasImpedimentsRadio: "",
		guardianFirstName: "",
		guardianLastName: "",
		guardianEmail: "",
		guardianYearOfBirth: "",
		participantViewingWithGuardianRadio: "",
	};

	// form validation using formik library
	const formik = useFormik({
		validateOnChange: false,
		validateOnBlur: formikValidateOnBlur.current,
		initialValues,
		validationSchema: Yup.object({
			username: Yup.string()
				.trim(i18n.t("username-required"))
				.required(i18n.t("username-required"))
				.matches(validUsername, i18n.t("invalid-username-regex"))
				.test({
					name: "username-validation",
					message: i18n.t("duplicate-username"),
					test: async function (value) {
						if (value.trim() === profile.username.trim()) {
							return true; // Username hasn't changed, pass validation
						}
						return await checkUsername(value);
					},
				}),
			participantFirstName: Yup.string()
				.required(i18n.t("first-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			participantLastName: Yup.string()
				.required(i18n.t("last-name-required"))
				.matches(
					validFirstLastName,
					i18n.t("invalid-first-last-name-regex")
				),
			participantEmail: Yup.string()
				.required(i18n.t("email-required"))
				.matches(validEmail, i18n.t("invalid-email-regex")),
			participantDateOfBirth: Yup.date().required(
				i18n.t("date-of-birth-required")
			),
			participantProvince: Yup.string().required(
				i18n.t("participant-province-required")
			),
			...guardianValidationSchema,
		}),
		onSubmit: async (values) => {
			let response;
			toast.dismiss(); // Hide any existing toasts

			userIsOfAge.current = isUserAgeOfConsent(
				formik.values.participantDateOfBirth
			);

			// Check user's age on form submit and display guardian section if under 19
			if (
				!userIsOfAge.current &&
				formConditionals.showGuardianSection == false
			) {
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: true,
				});
				toast.info(i18n.t("under-19-show-guardian-section"));
				return;
			}
			// Hide guardian section if user is over 19 years of age and has no impediments
			else if (
				userIsOfAge.current &&
				formik.values.hasImpedimentsRadio == 0 &&
				formConditionals.showGuardianSection == true
			) {
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: false,
				});
				toast.info(
					i18n.t("over-19-no-impediments-hide-guardian-section")
				);
				return;
			}

			// Create formatted variables for all form fields
			let usernameFormatted = values.username;
			let participantFirstNameFormatted = values.participantFirstName;
			let participantLastNameFormatted = values.participantLastName;
			let participantEmailFormatted = values.participantEmail;
			let participantDateOfBirthFormatted = values.participantDateOfBirth;
			let participantProvinceFormatted = values.participantProvince;
			let hasImpedimentsRadioFormatted = values.hasImpedimentsRadio;
			let guardianFirstNameFormatted = values.guardianFirstName;
			let guardianLastNameFormatted = values.guardianLastName;
			let guardianEmailFormatted = values.guardianEmail;
			let guardianYearOfBirthFormatted = values.guardianYearOfBirth;
			let participantViewingWithGuardianRadioFormatted =
				values.participantViewingWithGuardianRadio;

			// Trim white spaces
			usernameFormatted = usernameFormatted.trim();
			participantFirstNameFormatted =
				participantFirstNameFormatted.trim();
			participantLastNameFormatted = participantLastNameFormatted.trim();
			participantEmailFormatted = participantEmailFormatted.trim();
			if (guardianFirstNameFormatted)
				guardianFirstNameFormatted = guardianFirstNameFormatted.trim();
			if (guardianLastNameFormatted)
				guardianLastNameFormatted = guardianLastNameFormatted.trim();
			if (guardianEmailFormatted)
				guardianEmailFormatted = guardianEmailFormatted.trim();

			// Convert Participant Date to YYYY-MM-DD format
			participantDateOfBirthFormatted = moment(
				participantDateOfBirthFormatted
			).format("YYYY-MM-DD");

			// Convert Guardian Date to YYYY format (if exists)
			if (guardianYearOfBirthFormatted)
				guardianYearOfBirthFormatted = parseInt(
					moment(guardianYearOfBirthFormatted).format("YYYY")
				);

			// Set default values for guardian section
			if (!formConditionals.showGuardianSection) {
				guardianFirstNameFormatted = "";
				guardianLastNameFormatted = "";
				guardianEmailFormatted = "";
				guardianYearOfBirthFormatted = "";
				participantViewingWithGuardianRadioFormatted = "";
			}

			// Check if fields changed and add to submit object
			let updatedFields = {};
			if (profile.username !== usernameFormatted)
				updatedFields.username = usernameFormatted;
			if (
				profile.first_name !== participantFirstNameFormatted &&
				!signed.current
			)
				updatedFields.participant_first_name =
					participantFirstNameFormatted;
			if (
				profile.last_name !== participantLastNameFormatted &&
				!signed.current
			)
				updatedFields.participant_last_name =
					participantLastNameFormatted;
			if (profile.participant_email !== participantEmailFormatted)
				updatedFields.participant_email = participantEmailFormatted;
			if (
				profile.date_of_birth !== participantDateOfBirthFormatted &&
				!signed.current
			)
				updatedFields.date_of_birth = participantDateOfBirthFormatted;
			if (profile.province !== participantProvinceFormatted)
				updatedFields.province = participantProvinceFormatted;
			if (
				profile.has_impediments !== hasImpedimentsRadioFormatted &&
				!signed.current
			)
				updatedFields.has_impediments = hasImpedimentsRadioFormatted;
			if (
				profile.guardian_first_name !== guardianFirstNameFormatted &&
				!signed.current
			)
				updatedFields.guardian_first_name = guardianFirstNameFormatted;
			if (
				profile.guardian_last_name !== guardianLastNameFormatted &&
				!signed.current
			)
				updatedFields.guardian_last_name = guardianLastNameFormatted;
			if (profile.guardian_email !== guardianEmailFormatted)
				updatedFields.guardian_email = guardianEmailFormatted;
			if (
				profile.guardian_year_of_birth !==
					guardianYearOfBirthFormatted &&
				!signed.current
			)
				updatedFields.guardian_year_of_birth =
					guardianYearOfBirthFormatted;
			if (
				profile.is_participant_viewing_the_program !==
					participantViewingWithGuardianRadioFormatted &&
				!signed.current
			)
				updatedFields.is_participant_viewing_the_program =
					participantViewingWithGuardianRadioFormatted;

			try {
				setIsLoading(true);
				// Send request to update user
				response = await update(updatedFields);
				setTimeout(() => {
					switch (response) {
						case 200: // Request successful
							setSubmitSuccessCount(++submitSuccessCount);
							formikValidateOnBlur.current = false;
							genericModalProps.current = {
								titleText: i18n.t(
									"profile-success-modal-title"
								),
								bodyText: i18n.t("profile-success-modal-body"),
								footerText: i18n.t(
									"profile-success-modal-footer-close"
								),
							};
							handleGenericModalShow();
							break;
						case 401:
							navigate("/login");
							break;
						case 409: // Duplicate username -- this error is handled by formik
							break;
						default: // Generic error message
							throw "Generic error";
					}

					setIsLoading(false);
				}, 200);
			} catch (e) {
				genericModalProps.current = {
					titleText: i18n.t("error"),
					bodyText: i18n.t("error-failed-submission"),
					footerText: i18n.t("close"),
				};
				handleGenericModalShow();
				setIsLoading(false);
			}
		},
	});

	// Handles showing/hiding of form submission success modal
	const handleGenericModalClose = () => setShowGenericModal(false);
	const handleGenericModalShow = () => setShowGenericModal(true);

	async function setFormikFieldsTouched() {
		formik.setTouched(
			{
				username: true,
				participantFirstName: true,
				participantLastName: true,
				participantEmail: true,
				participantDateOfBirth: true,
				participantProvince: true,
				hasImpedimentsRadio: true,
				guardianFirstName: true,
				guardianLastName: true,
				guardianEmail: true,
				guardianYearOfBirth: true,
				participantViewingWithGuardianRadio: true,
			},
			true
		);
	}

	useEffect(() => {
		initialValues = {
			username: profile.username,
			participantFirstName: profile.first_name,
			participantLastName: profile.last_name,
			participantEmail: profile.participant_email,
			participantDateOfBirth: profile.date_of_birth
				? moment(profile.date_of_birth, "YYYY-MM-DD").toDate()
				: null,
			participantProvince: profile.province,
			hasImpedimentsRadio: profile.has_impediments,
			guardianFirstName: profile.guardian_first_name ?? null,
			guardianLastName: profile.guardian_last_name ?? null,
			guardianEmail: profile.guardian_email ?? null,
			guardianYearOfBirth: profile.guardian_year_of_birth
				? moment(profile.guardian_year_of_birth, "YYYY").toDate()
				: null,
			participantViewingWithGuardianRadio:
				profile.is_participant_viewing_the_program ?? null,
		};

		formik.resetForm({
			values: initialValues,
		});

		setFormConditionals({
			showForm: true,
			showGuardianSection: profile.guardian_email ? true : false,
		});
		signed.current = userHasSigned(profile.consents);
	}, [profile]);

	// Show/hide guardian section based on criteria
	useEffect(() => {
		if (signed.current == true) return;

		userIsOfAge.current = isUserAgeOfConsent(
			formik.values.participantDateOfBirth
		);
		hasImpediments.current = userHasImpediments(
			formik.values.hasImpedimentsRadio
		);

		if (formConditionals.showGuardianSection == false) {
			// Reveal guardian section if user is under 19 years of age
			if (!userIsOfAge.current) {
				toast.dismiss();
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: true,
				});
				toast.info(i18n.t("under-19-show-guardian-section"));
			}
			// Reveal guardian section if user has impediments
			else if (hasImpediments.current) {
				toast.dismiss();
				setFormConditionals({
					showForm: formConditionals.showForm,
					showGuardianSection: true,
				});
				toast.info(i18n.t("has-impediments-show-guardian-section"));
			}
		}
		// Hide guardian section if user is over 19 years of age and has no impediments
		else if (
			formConditionals.showGuardianSection == true &&
			userIsOfAge.current &&
			!hasImpediments.current
		) {
			toast.dismiss();
			setFormConditionals({
				showForm: formConditionals.showForm,
				showGuardianSection: false,
			});
			toast.info(i18n.t("over-19-no-impediments-hide-guardian-section"));
		}
	}, [
		formik.values.participantDateOfBirth,
		formik.values.hasImpedimentsRadio,
	]);

	// Hide loading spinner after set by submit function and announce num of validation errors to screen reader and include userSports errors to count
	useEffect(() => {
		if (!formik.isValid && formik.isSubmitting) {
			//console.log(Object.keys(formik.errors));

			// Get number of formik validation errors
			let numberOfErrors = Object.keys(formik.errors).length;

			// Announce number of validation errors to screenreader
			srAnnounce(
				i18n
					.t("sr-announce-formik-errors")
					.replace("{errors}", numberOfErrors),
				true
			);
			// renmove loading spinner after submission
			setTimeout(() => {
				setIsLoading(false);

				// focus username field if first error
				if (Object.keys(formik.errors)[0] == "username") {
					setTimeout(() => {
						if (usernameInputRef.current) {
							usernameInputRef.current.focus();
						}
					}, 100);
				}
			}, 500); // 100ms less than focusDelay timeout for FocusError component, works best with screen reader and srAnnounce
		}
	}, [
		formik.errors,
		formik.isValid,
		formik.isSubmitting,
		formik.values,
		i18n,
	]);

	return (
		<>
			{isLoading && <LoadingSpinner isLoading={isLoading} />}

			<GenericModal
				show={showGenericModal}
				onHide={handleGenericModalClose}
				backdrop="static"
				keyboard={false}
				titleText={genericModalProps.current.titleText}
				bodyText={genericModalProps.current.bodyText}
				footerText={genericModalProps.current.footerText}
			/>

			<Container fluid>
				<Form id="accountInformationForm" noValidate>
					{
						// Exclude FocusError component if username field is next validation error (issues with formik due to async duplicate username check)
						// Custom focus method exists specifically for this type of field in the useEffect above
						Object.keys(formik.errors)[0] !== "username" && (
							<FocusError formik={formik} focusDelay={600} />
						)
					}
					<Row>
						<Col>
							<h4
								role="heading"
								aria-level={2}
								className="fw-normal d-inline-block mb-2"
							>
								{i18n.t("account-information-header")}
							</h4>
							<div className="fw-normal text-muted">
								{i18n.t("profile-account-information-info")}
							</div>
						</Col>
						{signed.current && (
							<Col lg={6}>
								<div
									id="fields-disabled-label"
									className={`${styles.disabledLabel} text-danger`}
								>
									<em>
										{i18n.t("fields-disabled-label")}
										<span className="sr-only">;</span>
									</em>
								</div>
							</Col>
						)}
					</Row>
					<Row className="g-3 mt-2">
						<Col sm={12} className="mb-3">
							<Username
								value={formik.values.username}
								placeholder={i18n.t("username-placeholder")}
								label={i18n.t("account-username-label")}
								aria-label={i18n.t("username-placeholder")}
								onChange={formik.handleChange}
								onBlur={async () => {
									formik.handleBlur("username");
									formik.validateField("username");
								}}
								isInvalid={
									formik.touched.username &&
									formik.errors.username
								}
								disabled={isLoading}
								errors={formik.errors.username}
								inputRef={usernameInputRef}
							></Username>
						</Col>
						<Col sm={12} className="mb-3">
							<ParticipantFirstName
								value={formik.values.participantFirstName}
								placeholder={i18n.t(
									"participant-first-name-placeholder"
								)}
								label={i18n.t("participant-first-name-label")}
								aria-label={i18n.t(
									"participant-first-name-placeholder"
								)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik.touched.participantFirstName &&
									formik.errors.participantFirstName
								}
								disabled={isLoading || signed.current}
								errors={formik.errors.participantFirstName}
							></ParticipantFirstName>
						</Col>
						<Col sm={12} className="mb-3">
							<ParticipantLastName
								value={formik.values.participantLastName}
								placeholder={i18n.t(
									"participant-last-name-placeholder"
								)}
								label={i18n.t("participant-last-name-label")}
								aria-label={i18n.t(
									"participant-last-name-placeholder"
								)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik.touched.participantLastName &&
									formik.errors.participantLastName
								}
								disabled={isLoading || signed.current}
								errors={formik.errors.participantLastName}
							></ParticipantLastName>
						</Col>
						<Col sm={12} className="mb-3">
							<ParticipantEmail
								value={formik.values.participantEmail}
								placeholder={i18n.t(
									"participant-email-placeholder"
								)}
								label={i18n.t("participant-email-label")}
								aria-label={i18n.t(
									"participant-email-placeholder"
								)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik.touched.participantEmail &&
									formik.errors.participantEmail
								}
								disabled={isLoading}
								errors={formik.errors.participantEmail}
								showGuardianSection={
									formConditionals.showGuardianSection
								}
								twoFactorLabel={i18n.t(
									"two-factor-email-label"
								)}
							></ParticipantEmail>
						</Col>
						<Col sm={12} className="mb-3">
							<ParticipantDateOfBirth
								placeholder={i18n.t(
									"participant-date-of-birth-placeholder"
								)}
								label={i18n.t("participant-dob-label")}
								selected={formik.values.participantDateOfBirth}
								onChange={(date) => {
									formik.setFieldValue(
										"participantDateOfBirth",
										date
									);
								}}
								onBlur={() => {
									formik.handleBlur("participantDateOfBirth");
									formik.validateField(
										"participantDateOfBirth"
									);
								}}
								isInvalid={
									formik.touched.participantDateOfBirth &&
									formik.errors.participantDateOfBirth
								}
								disabled={isLoading || signed.current}
								locale={language}
								errors={formik.errors.participantDateOfBirth}
								formik={formik}
							></ParticipantDateOfBirth>
						</Col>
						<Col sm={12} className="mb-3">
							<Province
								provinceSelectedString={
									formik.values.participantProvince
								}
								id="participantProvince"
								name="participantProvince"
								label={i18n.t("province-label")}
								placeholder={i18n.t("province-placeholder")}
								onChange={(e) => {
									formik.setFieldValue(
										"participantProvince",
										e.value
									);
								}}
								onBlur={() => {
									formik.setFieldTouched(
										"participantProvince"
									);
								}}
								isInvalid={
									formik.touched.participantProvince &&
									formik.errors.participantProvince
								}
								disabled={isLoading}
								errors={formik.errors.participantProvince}
								required
								formik={formik}
							/>
						</Col>
						<Col sm={12} className="text-center">
							<HasImpediments
								label={i18n.t("has-impediments-label")}
								labelNo={i18n.t("has-impediments-no")}
								labelYes={i18n.t("has-impediments-yes")}
								autoComplete="on"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								yesChecked={
									formik.values.hasImpedimentsRadio == "1"
										? true
										: false
								}
								noChecked={
									formik.values.hasImpedimentsRadio == "0"
										? true
										: false
								}
								disabled={isLoading || signed.current}
								formik={formik}
							/>
						</Col>
					</Row>

					{formConditionals.showGuardianSection && (
						<Row className="g-3 mt-2">
							<hr className="my-4" />
							<Col sm={12} className="mb-3">
								<GuardianFirstName
									value={formik.values.guardianFirstName}
									placeholder={i18n.t(
										"guardian-first-name-placeholder"
									)}
									label={i18n.t("guardian-first-name-label")}
									aria-label={i18n.t(
										"guardian-first-name-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.guardianFirstName &&
										formik.errors.guardianFirstName
									}
									disabled={isLoading || signed.current}
									errors={formik.errors.guardianFirstName}
								></GuardianFirstName>
							</Col>
							<Col sm={12} className="mb-3">
								<GuardianLastName
									value={formik.values.guardianLastName}
									placeholder={i18n.t(
										"guardian-last-name-placeholder"
									)}
									label={i18n.t("guardian-last-name-label")}
									aria-label={i18n.t(
										"guardian-last-name-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.guardianLastName &&
										formik.errors.guardianLastName
									}
									disabled={isLoading || signed.current}
									errors={formik.errors.guardianLastName}
								></GuardianLastName>
							</Col>
							<Col sm={12} className="mb-3">
								<GuardianEmail
									value={formik.values.guardianEmail}
									placeholder={i18n.t(
										"guardian-email-placeholder"
									)}
									label={i18n.t("guardian-email-label")}
									aria-label={i18n.t(
										"guardian-email-placeholder"
									)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.touched.guardianEmail &&
										formik.errors.guardianEmail
									}
									disabled={isLoading}
									errors={formik.errors.guardianEmail}
									twoFactorLabel={i18n.t(
										"two-factor-email-label"
									)}
								></GuardianEmail>
							</Col>
							<Col sm={12} className="mb-3">
								<GuardianYearOfBirth
									label={i18n.t("guardian-yob-label")}
									placeholder={i18n.t(
										"guardian-yob-placeholder"
									)}
									selected={formik.values.guardianYearOfBirth}
									onChange={(date) => {
										formik.setFieldValue(
											"guardianYearOfBirth",
											date
										);
									}}
									onBlur={() => {
										formik.handleBlur(
											"guardianYearOfBirth"
										);
										formik.validateField(
											"guardianYearOfBirth"
										);
									}}
									isInvalid={
										formik.touched.guardianYearOfBirth &&
										formik.errors.guardianYearOfBirth
									}
									disabled={isLoading || signed.current}
									locale={language}
									errors={formik.errors.guardianYearOfBirth}
									yearOfBirthLabel={i18n.t(
										"year-of-birth-label"
									)}
									formik={formik}
								></GuardianYearOfBirth>
							</Col>
							<Col sm={12} className="text-center">
								<ParticipantViewingWithGuardian
									label={i18n.t(
										"participant-viewing-with-guardian-label"
									)}
									labelNo={i18n.t(
										"participant-viewing-with-guardian-no"
									)}
									labelYes={i18n.t(
										"participant-viewing-with-guardian-yes"
									)}
									autoComplete="on"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									yesChecked={
										formik.values
											.participantViewingWithGuardianRadio ==
										"1"
											? true
											: false
									}
									noChecked={
										formik.values
											.participantViewingWithGuardianRadio ==
										"0"
											? true
											: false
									}
									disabled={isLoading || signed.current}
									formik={formik}
								/>
							</Col>
						</Row>
					)}
					<Button
						type="button"
						variant="primary"
						size="lg"
						className="w-75 mx-auto d-block mt-4 mb-2"
						onClick={async () => {
							formikValidateOnBlur.current = true;
							setIsLoading(true);
							await setFormikFieldsTouched();
							formik.handleSubmit();
						}}
						disabled={isLoading}
					>
						{!isLoading && i18n.t("save-profile")}
						{isLoading && i18n.t("loading")}
					</Button>
				</Form>
			</Container>
		</>
	);
}

export default AccountInformation;
