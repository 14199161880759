import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editUser: null
}

const adminSlice = createSlice({
    name: "editUser",
    initialState,
    reducers: {
        setEditUser: (state, action) => {
            if (action.payload === null) {
                state.editUser = null;
            } else {
                state.editUser = action.payload;
            }
        }
    }
});

export const { setEditUser } = adminSlice.actions;

export const selectEditUser = (state) => state.admin.editUser;

export default adminSlice.reducer;