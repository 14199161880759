import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getAdminSportOrganizations } from "../features/sports/sportsAPI";

export const useSportsOrganizations = () => {
	const [sportOrganizations, setSportOrganizations] = useState([]);
	const { i18n } = useTranslation();

	useEffect(() => {
		async function fetchSportOrganizations() {
			const organizations = await getAdminSportOrganizations(i18n.language);
			if (organizations && !organizations.error) {
				setSportOrganizations(organizations);
			}
		}
		fetchSportOrganizations();
	}, [i18n.language]);

	return sportOrganizations;
};
