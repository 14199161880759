import React from "react";
import { Row, Col, Button, Spinner, Fade } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import styles from "./ReportTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

function ReportTable({
	columns,
	handleSearch,
	handleExport,
	reportData,
	handleSort,
	handlePageChange,
	handleRowsPerPageChange,
	isLoading,
	hasFetched,
}) {
	const { i18n } = useTranslation();

	const CustomProgressComponent = () => (
		<div className={styles.centered}>
			<Fade in={isLoading} timeout={5000} appear={true}>
				<div className="loading-spinner-container">
					<Spinner className="loading-spinner" role="status" aria-hidden="true"></Spinner>
				</div>
			</Fade>
		</div>
	);

	const CustomPaginationComponent = () => {
		const totalPages = Math.ceil(reportData.total / reportData.perPage);

		const selectChange = (event) => {
			let selectedValue = event.target.value;
			if (isNaN(selectedValue)) {
				selectedValue = Number.MAX_SAFE_INTEGER;
			} else {
				selectedValue = parseInt(selectedValue);
			}
			handleRowsPerPageChange(selectedValue);
		};

		const changePage = (newPage) => {
			if (newPage >= 1 && newPage <= totalPages) {
				handlePageChange(newPage);
			}
		};

		return (
			<Row className="d-flex justify-content-between align-items-center mx-0 my-3">
				<Col className="d-flex justify-content-start">
					<span>{i18n.t("rows-per-page-text")}&nbsp;</span>
					<select value={reportData.perPage === Number.MAX_SAFE_INTEGER ? i18n.t('all') : reportData.perPage} onChange={selectChange}>
						{[50, 100, 1000, i18n.t('all')].map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				</Col>
				<Col className="d-flex justify-content-end align-items-center">
					<button onClick={() => changePage(reportData.currentPage - 1)} disabled={reportData.currentPage <= 1}>
						<FontAwesomeIcon icon={faArrowLeft} />
					</button>
					<span>&nbsp;{reportData.currentPage}&nbsp;</span>
					<span>{i18n.t("range-separator-text")}</span>
					<span>&nbsp;{totalPages}&nbsp;</span>
					<button onClick={() => changePage(reportData.currentPage + 1)} disabled={reportData.currentPage === totalPages}>
						<FontAwesomeIcon icon={faArrowRight} />
					</button>
				</Col>
			</Row>
		);
	};

	return (
		<>
			<Row className="mt-3 d-flex flex-row justify-content-start align-items-start">
				<Col xs={4}>
					<Button
						type="button"
						variant="primary"
						onClick={() => {
							handleSearch();
						}}
						className="me-2 w-100 mb-2"
					>
						{i18n.t("run-report")}
					</Button>
				</Col>
				{hasFetched && <>
					<Col xs={4}>
						<Button
							onClick={() => handleExport("csv", columns, i18n.t("user-report"))}
							variant="secondary"
							className="me-2 w-100 mb-2"
						>
							{i18n.t("download-as-csv")}
						</Button>
					</Col>
					<Col xs={4}>
						<Button
							onClick={() => handleExport("excel", columns, i18n.t("user-report"))}
							variant="secondary"
							className="me-2 w-100 mb-2"
						>
							{i18n.t("download-as-excel")}
						</Button>
					</Col>
				</>}
			</Row>
			{hasFetched && (
				<Row>
					<Col>
						{isLoading && <CustomProgressComponent />}
						<CustomPaginationComponent />
						<DataTable
							columns={columns}
							data={reportData.data}
							pagination
							responsive
							highlightOnHover
							onSort={handleSort}
							sortServer
							paginationServer
							paginationComponent={CustomPaginationComponent}
						/>
					</Col>
				</Row>
			)}
		</>
	);
}

export default ReportTable;
